import * as React from 'react';
import clsx from 'clsx';
import { DashboardContainer } from '../../../core/modules/dashboard/container/Dashboard';
import UsersData from '../data/users.json';
import { Typography } from '../../../core/components/Typography';
import { useUsersQueryGetUser } from '../react_query/hooks/useQueryGetUser.users';
import { useFormContext } from 'react-hook-form';
import { UsersForm, forms } from '../react_hook_form/constants';
import { NotFoundContainer } from 'core/modules/not_found/container';
import { ListUsers } from '../fragments/List';
import { PlusIcon } from '@heroicons/react/20/solid';
import { UserInvitationPopUpUsers } from '../components/UserInvitationPopUp';
import { useUsersMutationInviteUsers } from '../react_query/hooks/useMutationInviteUsers.users';
import { REGEX } from 'core/utils/regex';

export const UsersContainer = () => {
  const {
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useFormContext<UsersForm>();

  useUsersQueryGetUser();
  const { mutate: inviteUser } = useUsersMutationInviteUsers();

  const role = watch(forms.role.name) as null | string;

  if (!role) {
    return <div />;
  }

  if (role !== 'site_admin' && role !== 'admin') {
    return <NotFoundContainer />;
  }

  const handleClickCreateUser = () => {
    setValue(forms.user_invitation.is_open, true);
  };

  const handleCloseUserInvitation = () => {
    setValue(forms.user_invitation.is_open, false);
    setValue(forms.user_invitation.email, '');
  };

  const handleClickInvite = () => {
    inviteUser();
  };

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(forms.user_invitation.email, e.currentTarget.value);
    if (!REGEX.EMAIL.test(e.currentTarget.value)) {
      setError(forms.user_invitation.email, {
        message: UsersData.data_table.user_invitation.form.email.errors.invalid_email.message
      });
    } else {
      clearErrors(forms.user_invitation.email);
    }
  };

  const isAddUserDisabled =
    !(watch(forms.user_invitation.email) as string).length ||
    (errors['email-user_invitation-users']?.message ?? '').length > 0;

  return (
    <DashboardContainer>
      <div
        className={clsx(
          'grid grid-cols-1 place-content-start place-items-start gap-[1rem]',
          'w-full'
        )}>
        <div
          className={clsx(
            'grid grid-flow-col items-center content-center justify-between justify-items-start',
            'w-full'
          )}>
          <div
            className={clsx(
              'grid grid-cols-1 place-content-start place-items-start gap-[0.25rem]',
              'w-full'
            )}>
            <Typography variant={'Heading'} size={'H1'} color={'text-grayscale-90'}>
              {UsersData.title}
            </Typography>
            <Typography variant={'Regular'} size={'M'} color={'text-grayscale-70'}>
              {UsersData.description}
            </Typography>
          </div>

          <button
            className={clsx(
              'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.75rem]',
              'w-full',
              'rounded-[0.375rem]',
              'px-[1.5rem] py-[0.5rem]',
              'bg-cta-purple-primary'
            )}
            onClick={handleClickCreateUser}>
            <PlusIcon className={clsx('w-[1rem] h-[1rem]', 'text-white')} />
            <Typography variant={'Button'} size={'M'} color={'text-grayscale-10'}>
              {'Add new user'}
            </Typography>
          </button>
        </div>

        <div
          className={clsx(
            'grid grid-cols-1 place-content-start place-items-start gap-[1rem]',
            'w-full'
          )}>
          <ListUsers />
        </div>

        <UserInvitationPopUpUsers
          isOpen={watch(forms.user_invitation.is_open) as boolean}
          onClose={handleCloseUserInvitation}
          message={UsersData.data_table.user_invitation.message}
          description={UsersData.data_table.user_invitation.description}
          input={{
            placeholder: UsersData.data_table.user_invitation.placeholder,
            error: {
              status:
                !REGEX.EMAIL.test(watch(forms.user_invitation.email) as string) &&
                (watch(forms.user_invitation.email) as string).length > 0,
              message: errors['email-user_invitation-users']?.message ?? ''
            },
            value: watch(forms.user_invitation.email) as string,
            onChange: handleChangeEmail
          }}
          cta={{
            primary: {
              children: UsersData.data_table.user_invitation.cta.primary.children,
              disabled: isAddUserDisabled,
              onClick: handleClickInvite
            }
          }}
        />
      </div>
    </DashboardContainer>
  );
};
