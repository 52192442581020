import axios from 'axios';
import { PostResetPasswordRequestInterface } from 'core/models/api/registration';

export const fetchPostResetPassword = async (payload?: PostResetPasswordRequestInterface) => {
  const url = `https://${process.env.REACT_APP_BILLING_AUTH0_DOMAIN}/dbconnections/change_password` || '';

  return await axios
    .post(url, payload?.data, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      }
    })
    .then((res) => {
      return res.data;
    });
};
