import * as React from 'react';
import clsx from 'clsx';

export interface TabsProps {
  children?: React.ReactNode;
}

export const Tabs = ({ children }: TabsProps) => {
  return (
    <div
      className={clsx(
        'grid grid-flow-col items-center content-center justify-start justify-items-start gap-x-[40px]',
        'w-full',
        "border-b border-b-grayscale-30"
      )}>
      {children}
    </div>
  );
};
