import { forms } from './data';
import { AccountForm } from './type';

export const defaultValues: AccountForm = {
  // tabs
  [forms.tabs.active]: {
    id: 'personal_info',
    name: 'Personal Info'
  },
  [forms.tabs.data]: [],
  // personal_information
  [forms.personal_information.profile_image]: '',
  [forms.personal_information.first_name]: '',
  [forms.personal_information.position_in_company]: '',
  [forms.personal_information.last_name]: '',
  [forms.personal_information.contact_number]: '',
  [forms.personal_information.email]: '',
  [forms.personal_information.linked_in]: '',
  [forms.personal_information.success]: false,

  // company_information
  [forms.company_information.logo]: '',
  [forms.company_information.legal_entity_name]: '',
  [forms.company_information.country]: null,
  [forms.company_information.region]: '',
  [forms.company_information.city]: '',
  [forms.company_information.contact_number]: '',
  [forms.company_information.company_name]: '',
  [forms.company_information.address]: '',
  [forms.company_information.about]: '',
  [forms.company_information.billing_email]: '',
  [forms.company_information.website_link]: '',
  [forms.company_information.success]: false,

  //   password information
  [forms.password_information.email]: '',
  [forms.password_information.reset_password]: false
};
