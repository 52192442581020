import { DashboardContainer } from 'core/modules/dashboard/container';
import * as React from 'react';
import clsx from 'clsx';
import { Typography } from 'core/components/Typography';
import { Link } from 'react-router-dom';
import { DocCrashIcon } from 'core/icons/DocCrash';

export interface INotFoundContainerProps {}

export const NotFoundContainer = (props: INotFoundContainerProps) => {
  return (
    <DashboardContainer sidebar={false} navbar={{ showProfile: false }}>
      <div
        className={clsx(
          'grid grid-cols-1 place-content-center place-items-center gap-[2rem]',
          'w-full h-[calc(100vh_-_4.5rem_-_1rem_-_4rem)]'
        )}>
        <div
          className={clsx('grid grid-cols-1 place-content-center place-items-center gap-[0.5rem]')}>
          <div
            className={clsx(
              'grid grid-cols-1 place-content-center place-items-center gap-[0.75rem]'
            )}>
            <DocCrashIcon className={clsx('stroke-grayscale-90', 'w-[2rem] h-[2rem]')} />
            <Typography variant={'Heading'} size={'H2'} color={'text-grayscale-90'}>
              Page Not Found
            </Typography>
          </div>
          <Typography variant={'Regular'} size={'M'} color={'text-grayscale-70'}>
            We’re sorry, the page you requested could not be found.
          </Typography>
        </div>

        <Link
          to={'/'}
          className={clsx(
            'flex items-center justify-center',
            'bg-cta-purple-primary',
            'rounded-[0.375rem]',
            'px-[2rem] py-[0.75rem]',
            'text-[1rem] text-grayscale-10 font-medium leading-[150%] tracking-[-0.16px]'
          )}>
          Return to Services page
        </Link>
      </div>
    </DashboardContainer>
  );
};
