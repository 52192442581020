import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { RegistrationReactQueryKey } from '../keys';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchPostCreateUser } from 'core/services/registration';
import {
  PostCreateUserRequestInterface,
  PostCreateUserErrorResponseInterface,
  PostCreateUserSuccessResponseInterface
} from 'core/models/api/registration';
import { useFormContext } from 'react-hook-form';
import { RegistrationForm } from 'features/registration/customer/react_hook_form/constants/type';
import { forms } from 'features/registration/customer/react_hook_form/constants/data';
import RegistrationData from '../../data/registration.json';

export const useRegistrationMutationCreateUser = () => {
  const { user } = useAuth0();
  const { setValue } = useFormContext<RegistrationForm>();
  const token = localStorage.getItem('token') ?? '';
  const mutation = useMutation<
    PostCreateUserSuccessResponseInterface,
    AxiosError<PostCreateUserErrorResponseInterface>,
    { organizationId: string }
  >(
    RegistrationReactQueryKey.MutationCreateUser(),
    (data: { organizationId: string }) => {
      const payload: PostCreateUserRequestInterface = {
        headers: {
          token: token
        },
        data: {
          query: gql`
            mutation {
              createUser(input:{
                firstName:"${user?.given_name || ''}",
                lastName:"${user?.family_name || ''}",
                email:"${user?.email || ''}",
                status:null,
                organizationId:"${data.organizationId}",
                positionInCompany:null,
                contactNumber:null,
                linkedInUrl:null,
                profileImageUrl:null
              }){
                id,
                firstName,
                lastName,
                email
              }
            }
          `
        }
      };

      return fetchPostCreateUser(payload);
    },
    {
      retry: 0,
      onError(error, variables, context) {
        setTimeout(() => {
          setValue(forms.add_team_members.alert, true);
          setValue(
            forms.add_team_members.alert_message,
            RegistrationData.workspace.alert.message.replace(
              '{{error}}',
              error.response?.data.error[0].message ?? ''
            )
          );
        }, 5000);
      },
      onSuccess(data) {
        setValue(forms.state.create_workspace_step, 'add_team_members');
        if (data.data.createUser !== null) {
          localStorage.setItem('userId', data.data.createUser.id);
        }
        // window.location.href = `${process.env.REACT_APP_REDIRECT_CUSTOMER_REGISTRATION_LINK}`;
      }
    }
  );

  return mutation;
};
