import clsx from 'clsx';
import { CurrentCycleDataTableBilling } from '../CurrentCycleDataTable';
import { CurrentCycleInformationBilling } from '../CurrentCycleInformation';
import { useCurrentCycleBillingQueryGetBillableItems } from 'features/billing/react_query/hooks/useQueryGetBillableItems.billing';

export const CurrentCycleBilling = () => {
  useCurrentCycleBillingQueryGetBillableItems();
  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-content-start place-items-start gap-[3rem]',
        'w-full'
      )}>
      <CurrentCycleInformationBilling />
      <CurrentCycleDataTableBilling />
    </div>
  );
};
