import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { useOnClickOutside } from 'usehooks-ts';

export interface RoleSelectFieldRegistrationProps {
  mainLabel?: string;
  secondaryLabel?: string;
  helperText?: string;
  placeholder?: string;
  variant?: 'default' | 'error';
  fieldSize?: 'S' | 'M';
  options: { id: string; name: string }[];
  onSelect: (data: { id: string; name: string }) => void;
  selected?: { id: string; name: string } | null;
}

export const RoleSelectFieldRegistration = ({
  mainLabel = '',
  secondaryLabel = '',
  helperText = '',
  placeholder = '',
  variant = 'default',
  fieldSize = 'M',
  options,
  onSelect,
  selected = null as { id: string; name: string } | null
}: RoleSelectFieldRegistrationProps) => {
  // const defaultSelected = !options.length ? null : options[0];
  // const [selected, setSelected] = useState<null | (typeof options)[0]>(defaultSelected);
  const ref = React.useRef<HTMLDivElement | null>(null);

  useOnClickOutside(ref, () => {
    setIsOpen((prev) => false);
  });
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div
      ref={ref}
      className={clsx(
        'grid grid-cols-1 place-content-start place-items-start gap-[0.5rem]',
        'w-[128px]'
      )}>
      <Listbox>
        <div className={clsx('relative', 'w-full')}>
          <Listbox.Button
            data-testid={`${selected?.name ?? 'default'}-listbox-button`}
            className={clsx(
              'flex items-center justify-between',
              'relative w-full cursor-default bg-white focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm',
              'text-left',
              'rounded-[0.25rem]',
              'border',

              'px-[0.25rem] py-[0.25rem]',

              'cursor-pointer disabled:cursor-default'
            )}
            onClick={handleClick}>
            <span
              className={clsx(
                'flex items-center justify-center',
                'px-[0.75rem] py-[0.25rem]',
                'bg-grayscale-30',
                'rounded-[0.25rem]',
                'text-[0.75rem] font-semibold leading-[136%] tracking-[0.3px] uppercase'
              )}>
              {selected?.name ?? placeholder}
            </span>

            <span
              className={clsx(
                'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'
              )}>
              {isOpen ? (
                <ChevronUpIcon
                  className={clsx('h-[1rem] w-[1rem]', 'text-grayscale-60')}
                  aria-hidden="true"
                />
              ) : (
                <ChevronDownIcon
                  className={clsx('h-[1rem] w-[1rem]', 'text-grayscale-60')}
                  aria-hidden="true"
                />
              )}
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave={clsx('transition ease-in duration-100')}
            leaveFrom={clsx('opacity-100')}
            leaveTo={clsx('opacity-0')}>
            <Listbox.Options
              className={clsx(
                'absolute z-[1] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
              )}>
              {options.map((option, optionIdx) => (
                <Listbox.Option
                  key={optionIdx}
                  data-testid={option.name}
                  className={({ active }) =>
                    clsx(
                      'relative',
                      active
                        ? 'bg-grayscale-80 text-grayscale-90'
                        : 'bg-white hover:bg-grayscale-80',
                      'text-grayscale-90',
                      'cursor-pointer',
                      'select-none',
                      fieldSize === 'M' ? 'h-[3rem]' : 'h-[2.5rem]',
                      fieldSize === 'M' ? 'px-[1rem] py-[0.875rem]' : 'px-[0.75rem] py-[0.75rem]',
                      'whitespace-nowrap',
                      'text-[0.875rem] text-grayscale-90 font-normal leading-[146%] tracking-[-0.14px]'
                    )
                  }
                  value={option}
                  onClick={() => {
                    onSelect(option);
                    setIsOpen(() => false);
                  }}>
                  {option.name}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};
