import * as React from 'react';
import clsx from 'clsx';
import { DashboardContainer } from '../../../core/modules/dashboard/container/Dashboard';
import AccountData from '../data/invoices.json';
import { Typography } from '../../../core/components/Typography';
import { useBillingQueryGetUser } from '../react_query/hooks/useQueryGetUser.billing';
import { useFormContext } from 'react-hook-form';
import { BillingForm, forms } from '../react_hook_form/constants';
import { NotFoundContainer } from 'core/modules/not_found/container';
import { TabsBilling } from '../fragments/Tabs';
import { GeneralBilling } from '../fragments/General';
import { InvoicesBilling } from '../fragments/Invoices';
import { CurrentCycleBilling } from '../fragments/CurrentCycle';

export const BillingContainer = () => {
  const { watch } = useFormContext<BillingForm>();

  useBillingQueryGetUser();

  const role = watch(forms.role.name) as null | string;

  const activeTab = watch(forms.tabs.active) as null | { id: string; name: string };

  if (!role) {
    return <div />;
  }

  if (role !== 'site_admin' && role !== 'admin') {
    return <NotFoundContainer />;
  }

  return (
    <DashboardContainer>
      <div
        className={clsx(
          'grid grid-cols-1 place-content-start place-items-start gap-[1rem]',
          'w-full'
        )}>
        <div
          className={clsx(
            'grid grid-cols-1 place-content-start place-items-start gap-[0.25rem]',
            'w-full'
          )}>
          <Typography variant={'Heading'} size={'H1'} color={'text-grayscale-90'}>
            {AccountData.title}
          </Typography>
          <Typography variant={'Regular'} size={'M'} color={'text-grayscale-70'}>
            {AccountData.description}
          </Typography>

          <TabsBilling />
        </div>
        <div
          className={clsx(
            'grid grid-cols-1 place-content-start place-items-start gap-[1rem]',
            'w-full'
          )}>
          {activeTab?.id === AccountData.tabs[0].id && <GeneralBilling />}
          {activeTab?.id === AccountData.tabs[1].id && <InvoicesBilling />}
          {activeTab?.id === AccountData.tabs[2].id && <CurrentCycleBilling />}
        </div>
      </div>
    </DashboardContainer>
  );
};
