import { DataTableUsers } from '../DataTable';
import { DataTableActionsUsers } from '../DataTableActions';
import { useUsersQueryGetUsers } from 'features/users/react_query/hooks/useQueryGetUsers.users';

export const ListUsers = () => {
  useUsersQueryGetUsers();

  return (
    <div className="w-full">
      <DataTableActionsUsers />
      <DataTableUsers />
    </div>
  );
};
