import { Table, flexRender } from '@tanstack/react-table';
import * as React from 'react';
import TableBodyTestData from './TableBody.test.data.json';
import clsx from 'clsx';

export interface TableBodyProps {
  table?: Table<any> | null;
  marginTop?: string;
}

export const TableBody: React.FC<TableBodyProps> = ({ table = null, marginTop }: TableBodyProps) => {
  return (
    <tbody data-testid={TableBodyTestData['data-testid']}>
      {table !== null && table.getRowModel().rows.length > 0 ? (
        table.getRowModel().rows.map((row, idx) => (
          <tr
            key={row.id}
            role={'table-row'}
            className={clsx(
              String(row.getValue('id')).length > 0 && 'border-b border-b-grayscale-30 hover:bg-grayscale-20'
            )}
          >
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className={clsx('py-[0.8rem]', 'text-left', (marginTop && idx === 0) ? marginTop:'')}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={table?.getHeaderGroups()[0]?.headers?.length} className='text-center border-b border-b-grayscale-30 hover:bg-grayscale-20'>
            <p className="text-grayscale-70 text-center py-4">No data available</p>
          </td>
        </tr>
      )}
    </tbody>
  );
};