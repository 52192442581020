import * as React from 'react';
import clsx from 'clsx';
import {
  TypographyColorProps,
  TypographySizeProps,
  TypographyVariantProps
} from './Typography.types';

export interface TypographyProps {
  variant?: TypographyVariantProps;
  children?: React.ReactNode;
  size?: TypographySizeProps;
  color?: TypographyColorProps;
  className?: string;
}

export const Typography = ({
  children,
  variant = 'Heading',
  size = 'H1',
  color = 'text-grayscale-90',
  className
}: TypographyProps) => {
  if (variant === 'Heading') {
    if (size === 'H1') {
      return (
        <h1
          className={clsx(
            'text-[1.5rem] font-semibold leading-[132%] tracking-[-0.24px]',
            color,
            className
          )}>
          {children}
        </h1>
      );
    }
    if (size === 'H2') {
      return (
        <h2
          className={clsx(
            'text-[1.5rem] font-semibold leading-[132%] tracking-[-0.24px]',
            color,
            className
          )}>
          {children}
        </h2>
      );
    }

    return (
      <h3
        className={clsx(
          'text-[1.25rem] font-semibold leading-[140%] tracking-[-0.2px]',
          color,
          className
        )}>
        {children}
      </h3>
    );
  }
  if (variant === 'Medium') {
    if (size === 'XXL') {
      return (
        <p
          className={clsx(
            'text-[1.5rem] font-medium leading-[150%] tracking-[-0.48px]',
            color,
            className
          )}>
          {children}
        </p>
      );
    }
    if (size === 'XL') {
      return (
        <p
          className={clsx(
            'text-[1.25rem] font-medium leading-[150%] tracking-[-0.2px]',
            color,
            className
          )}>
          {children}
        </p>
      );
    }
    if (size === 'L') {
      return (
        <p
          className={clsx(
            'text-[1.125rem] font-medium leading-[142%] tracking-[-0.18px]',
            color,
            className
          )}>
          {children}
        </p>
      );
    }
    if (size === 'M') {
      return (
        <p
          className={clsx(
            'text-[1rem] font-medium leading-[150%] tracking-[-0.16px]',
            color,
            className
          )}>
          {children}
        </p>
      );
    }
    if (size === 'S') {
      return (
        <p
          className={clsx(
            'text-[0.875rem] font-medium leading-[146%] tracking-[-0.14px]',
            color,
            className
          )}>
          {children}
        </p>
      );
    }
    if (size === 'XS') {
      return (
        <p
          className={clsx(
            'text-[13px] font-medium leading-[136%] tracking-[-0.13px]',
            color,
            className
          )}>
          {children}
        </p>
      );
    }
    return (
      <p
        className={clsx(
          'text-[0.75rem] font-medium leading-[140%] tracking-[-0.12px]',
          color,
          className
        )}>
        {children}
      </p>
    );
  }

  if (variant === 'Regular') {
    if (size === 'XL') {
      return (
        <p
          className={clsx(
            'text-[1.25rem] font-normal leading-[140%] tracking-[-0.2px]',
            color,
            className
          )}>
          {children}
        </p>
      );
    }
    if (size === 'L') {
      return (
        <p
          className={clsx(
            'text-[1.125rem] font-normal leading-[142%] tracking-[-0.18px]',
            color,
            className
          )}>
          {children}
        </p>
      );
    }
    if (size === 'M') {
      return (
        <p
          className={clsx(
            'text-[1rem] font-normal leading-[150%] tracking-[-0.16px]',
            color,
            className
          )}>
          {children}
        </p>
      );
    }
    if (size === 'S') {
      return (
        <p
          className={clsx(
            'text-[0.875rem] font-normal leading-[146%] tracking-[-0.14px]',
            color,
            className
          )}>
          {children}
        </p>
      );
    }
    if (size === 'XS') {
      return (
        <p
          className={clsx(
            'text-[13px] font-normal leading-[136%] tracking-[-0.13px]',
            color,
            className
          )}>
          {children}
        </p>
      );
    }
    return (
      <p
        className={clsx(
          'text-[0.75rem] font-normal leading-[140%] tracking-[-0.12px]',
          color,
          className
        )}>
        {children}
      </p>
    );
  }

  if (variant === 'Link') {
    if (size === 'XL') {
      return (
        <p
          className={clsx(
            'text-[1.25rem] font-normal leading-[140%] tracking-[-0.2px] underline',
            color,
            className
          )}>
          {children}
        </p>
      );
    }
    if (size === 'L') {
      return (
        <p
          className={clsx(
            'text-[1.125rem] font-normal leading-[142%] tracking-[-0.18px] underline',
            color,
            className
          )}>
          {children}
        </p>
      );
    }
    if (size === 'M') {
      return (
        <p
          className={clsx(
            'text-[1rem] font-normal leading-[150%] tracking-[-0.16px] underline',
            color,
            className
          )}>
          {children}
        </p>
      );
    }
    if (size === 'S') {
      return (
        <p
          className={clsx(
            'text-[0.875rem] font-normal leading-[146%] tracking-[-0.14px] underline',
            color,
            className
          )}>
          {children}
        </p>
      );
    }
    if (size === 'XS') {
      return (
        <p
          className={clsx(
            'text-[13px] font-normal leading-[136%] tracking-[-0.13px] underline',
            color,
            className
          )}>
          {children}
        </p>
      );
    }
    return (
      <p
        className={clsx(
          'text-[0.75rem] font-normal leading-[140%] tracking-[-0.12px] underline',
          color,
          className
        )}>
        {children}
      </p>
    );
  }

  // Button
  if (size === 'L') {
    return (
      <p
        className={clsx(
          'text-[1rem] font-medium leading-[150%] tracking-[-0.16px]',
          color,
          className
        )}>
        {children}
      </p>
    );
  }
  if (size === 'M') {
    return (
      <p
        className={clsx(
          'text-[15px] font-medium leading-[158%] tracking-[-0.15px]',
          color,
          className
        )}>
        {children}
      </p>
    );
  }
  return (
    <p
      className={clsx(
        'text-[0.875rem] font-medium leading-[146%] tracking-[-0.14px]',
        color,
        className
      )}>
      {children}
    </p>
  );
};
