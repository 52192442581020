import { useQuery } from '@tanstack/react-query';
import { RegistrationReactQueryKey } from '../keys';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

export const useSystemAuth0 = () => {
  const { isLoading: authLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [isLoading, setLoading] = useState(true);

  const query = useQuery({
    queryKey: RegistrationReactQueryKey.GetAccessTokenSilently(),
    queryFn: async () => {
      try {
        const res = await getAccessTokenSilently();
        return res;
      } catch (err) {
        throw err;
      }
    },
    retry: 0,
    enabled: !authLoading
  })

  useEffect(() => {
    if (query.data) {
      localStorage.setItem('token', query.data);
      setLoading(false);
    }
  }, [query.data]);

  return {
    isAuthenticated,
    isLoading
  };
};
