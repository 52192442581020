import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Typography } from '../Typography';

export interface SelectProps {
  options: { id: string; name: string }[];
  onSelect: (data: { id: string; name: string }) => void;
}

export const Select = ({ options, onSelect }: SelectProps) => {
  const defaultSelected = !options.length ? null : options[0];
  const [selected, setSelected] = useState<null | (typeof options)[0]>(defaultSelected);

  return (
    <div className={clsx('w-[160px]')}>
      <Listbox value={selected} onChange={setSelected}>
        <div className={clsx('relative mt-1')}>
          <Listbox.Button
            data-testid={`${selected?.name ?? 'default'}-listbox-button`}
            className={clsx(
              'relative w-full cursor-default bg-white focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm',
              'text-left',
              'rounded-[0.25rem]',
              'border border-grayscale-40',
              'px-[0.75rem] py-[0.375rem]'
            )}>
            <Typography variant={'Button'} size={'S'} color={'text-grayscale-80'}>
              {selected?.name ?? ''}
            </Typography>
            <span
              className={clsx(
                'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'
              )}>
              <ChevronUpDownIcon
                className={clsx('h-[1rem] w-[1rem]', 'text-grayscale-80')}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave={clsx('transition ease-in duration-100')}
            leaveFrom={clsx('opacity-100')}
            leaveTo={clsx('opacity-0')}>
            <Listbox.Options
              className={clsx(
                'absolute z-[1] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
              )}>
              {options.map((option, optionIdx) => (
                <Listbox.Option
                  key={optionIdx}
                  data-testid={option.name}
                  className={({ active }) =>
                    clsx(
                      'relative',
                      active
                        ? 'bg-cta-yellow-primary text-grayscale-90'
                        : 'bg-white hover:bg-cta-yellow-primary',
                      'text-grayscale-90',
                      'cursor-default',
                      'select-none',
                      'py-[0.375rem] px-[0.5rem]',
                      'whitespace-nowrap'
                    )
                  }
                  value={option}
                  onClick={() => {
                    onSelect(option);
                  }}>
                  {({ selected }) => (
                    <Typography variant={'Button'} size={'S'} color={'text-grayscale-80'}>
                      {option.name}
                    </Typography>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};
