import * as React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import ReviewerHomeData from 'features/home/reviewer/data/reviewer.home.json';
import { ArrowCornerIcon } from 'core/icons/ArrowCorner';

export const NavbarReviewerHome = () => {
  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-content-start place-items-start',
        'w-full',
        'bg-grayscale-20',
        'rounded-tr-[1rem] rounded-tl-[1rem]',
        'px-[4rem] py-[1rem]'
      )}>
      <div
        className={clsx(
          'grid grid-flow-col items-center content-center justify-between justify-items-start',
          'w-full'
        )}>
        <img src={'/logo/applied_ai_icon.svg'} />

        <Link to={'https://www.aaico.com/contact-us'} target='_blank'>
          <button
            className={clsx(
              'grid grid-flow-col items-center content-center justify-end justify-items-end gap-[0.625rem]',
              'text-[0.875rem] text-grayscale-black font- leading-[160%] tracking-[-0.28px]'
            )}>
            {ReviewerHomeData.navbar.actions.contact_us}
            <ArrowCornerIcon iconSize={'XS'} className={clsx('stroke-grayscale-black')} />
          </button>
        </Link>
      </div>
    </div>
  );
};
