import * as React from 'react';
import clsx from 'clsx';
import ReviewerHomeData from 'features/home/reviewer/data/reviewer.home.json';
import { Typography } from 'core/components/Typography';

export const LessonReviewerHome = () => {
  return (
    <div
      className={clsx(
        'grid grid-cols-1 items-start content-start justify-center justify-items-center',
        'w-full',
        'px-[4rem] py-[9rem]'
      )}>
      <div
        className={clsx(
          'grid grid-cols-1 place-content-start place-items-start gap-[6rem]',
          'w-full max-w-[1200px]'
        )}>
        <div
          className={clsx(
            'grid grid-cols-1 place-content-start place-items-start gap-[4rem]',
            'w-full'
          )}>
          <div
            className={clsx(
              'grid grid-flow-col items-start content-start justify-center justify-items-center',
              'w-full'
            )}>
            <h4
              className={clsx(
                'font-eliza font-medium text-[38px] text-grayscale-black leading-[110%] tracking-[-0.38px]'
              )}>
              {ReviewerHomeData.teach_by_demonstration.title}
            </h4>
          </div>
          <div
            className={clsx(
              'grid grid-cols-1 lg:grid-cols-2 place-content-start place-items-start gap-[6rem]',
              'w-full'
            )}>
            {ReviewerHomeData.teach_by_demonstration.items.map((item) => (
              <div
                key={item.id}
                className={clsx(
                  'grid grid-flow-col place-content-start place-items-start gap-[2.5rem]',
                  'w-full'
                )}>
                <img src={item.image} />
                <div
                  className={clsx(
                    'grid grid-cols-1 place-content-start place-items-start gap-[1.5rem]',
                    'w-full'
                  )}>
                  <Typography variant={'Medium'} size={'XL'} color={'text-grayscale-90'}>
                    {item.title}
                  </Typography>
                  <Typography variant={'Regular'} size={'M'} color={'text-grayscale-75'}>
                    {item.description}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          className={clsx(
            'grid grid-cols-1 place-content-start place-items-start gap-[4rem]',
            'w-full max-w-[1200px]'
          )}>
          <div
            className={clsx(
              'grid grid-flow-col items-start content-start justify-center justify-items-center',
              'w-full'
            )}>
            <h4
              className={clsx(
                'font-eliza font-medium text-[38px] text-grayscale-black leading-[110%] tracking-[-0.38px]'
              )}>
              {ReviewerHomeData.teach_by_comparison.title}
            </h4>
          </div>
          <div
            className={clsx(
              'grid grid-cols-1 lg:grid-cols-2 place-content-start place-items-start gap-[6rem]',
              'w-full'
            )}>
            {ReviewerHomeData.teach_by_comparison.items.map((item) => (
              <div
                key={item.id}
                className={clsx(
                  'grid grid-flow-col place-content-start place-items-start gap-[2.5rem]',
                  'w-full'
                )}>
                <img src={item.image} />
                <div
                  className={clsx(
                    'grid grid-cols-1 place-content-start place-items-start gap-[1.5rem]',
                    'w-full'
                  )}>
                  <Typography variant={'Medium'} size={'XL'} color={'text-grayscale-90'}>
                    {item.title}
                  </Typography>
                  <Typography variant={'Regular'} size={'M'} color={'text-grayscale-75'}>
                    {item.description}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/*  */}
    </div>
  );
};
