import * as React from 'react';
import clsx from 'clsx';
import { useDashboardQueryGetUser } from '../react_query/hooks';
import { NavbarDashboard, NavbarDashboardProps } from '../fragments/Navbar';
import { SidebarDashboard } from '../fragments/Sidebar';

export interface DashboardContainerProps {
  children?: React.ReactNode;
  sidebar?: boolean;
  navbar?: NavbarDashboardProps;
}

export const DashboardContainer = ({
  children,
  sidebar = true,
  navbar
}: DashboardContainerProps) => {
  useDashboardQueryGetUser();

  return (
    <div className={clsx('grid grid-cols-1 place-content-start place-items-start', 'w-full')}>
      <NavbarDashboard {...navbar} />
      <div
        className={clsx(
          'grid',
          sidebar ? 'grid-cols-[auto_1fr]' : 'grid-cols-1',
          'w-full',
          'min-h-[100vh_-_4.5rem]'
        )}>
        {sidebar && <SidebarDashboard />}

        <div
          className={clsx(
            'grid grid-cols-1 place-content-start place-items-start',
            'w-full',
            'pt-[1rem] px-[2.5rem]'
          )}>
          {children}
        </div>
      </div>
    </div>
  );
};
