import * as React from 'react';
import clsx from 'clsx';
import { HeroReviewerHome } from '../fragments/hero';
import { NavbarReviewerHome } from '../fragments/navbar';
import { LessonReviewerHome } from '../fragments/lesson';
import { EnterpriseSecurityReviewerHome } from '../fragments/enterprise_security';
import { FooterReviewerHome } from '../fragments/footer';

export const ReviewerHomeContainer = () => {
  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-content-start place-items-start',
        'w-full',
        'px-[1rem] py-[1rem]'
      )}>
      <NavbarReviewerHome />
      <HeroReviewerHome />
      <LessonReviewerHome />
      <EnterpriseSecurityReviewerHome />
      <FooterReviewerHome />
    </div>
  );
};
