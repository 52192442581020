import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { DashboardReactQueryKey } from '../keys';
import { fetchGetUser } from 'core/services/registration';
import {
  GetUserRequestInterface,
  GetUserSuccessResponseInterface
} from 'core/models/api/registration';
import { useFormContext } from 'react-hook-form';
import { DashboardForm } from '../../react_hook_form/constants/type';
import { forms } from '../../react_hook_form/constants/data';
import { User, useAuth0 } from '@auth0/auth0-react';
import { dashboardData } from 'core/modules/dashboard/data/dashboardData';
import { useLocation } from 'react-router-dom';

export const useDashboardQueryGetUser = () => {
  const { user } = useAuth0();
  const { setValue } = useFormContext<DashboardForm>();
  const token = localStorage.getItem('token') ?? '';
  const location = useLocation();
  const query = useQuery<GetUserSuccessResponseInterface, AxiosError>(
    DashboardReactQueryKey.QueryGetUser(),
    () => {
      const payload: GetUserRequestInterface = {
        headers: {
          token: token
        },
        data: {
          query: gql`
            query {
              user(${
                localStorage.getItem('userId')
                  ? `id:"${localStorage.getItem('userId')}"`
                  : 'id:null'
              }) {
                id
                firstName
                lastName
                email
                linkedInUrl
                positionInCompany
                contactNumber
                profileImageUrl
                roles
                status
                organization {
                  name
                  legalEntityName
                  country
                  city
                  region
                  about
                  websiteUrl
                  contactNumber
                }
              }
            }
          `
        }
      };

      return fetchGetUser(payload);
    },
    {
      retry: 0
    }
  );

  useEffect(() => {
    if (query.data) {
      const auth0UserStorage = localStorage.getItem('auth0_user');
      const auth0User =
        auth0UserStorage !== null ? (JSON.parse(auth0UserStorage) as User) : undefined;

      if (!!auth0User) {
        setValue(forms.navbar.image, auth0User.picture as string);

        const name =
          query.data.data !== null && query.data.data?.user !== null
            ? query.data.data.user.status === 'invited'
              ? query.data.data.user.email
              : `${query.data.data.user?.firstName || ''} ${query.data.data.user?.lastName || ''}`
            : auth0User?.nickname;
        setValue(forms.navbar.name, name);
        const email =
          query.data.data !== null && query.data.data.user !== null
            ? query.data.data.user.email
            : auth0User?.email || '';
        setValue(forms.navbar.email, email);
        const initial =
          query.data.data !== null && query.data?.data.user !== null
            ? query.data.data.user.status === 'invited'
              ? query.data.data.user.email.charAt(0)
              : `${query.data.data.user.firstName
                  ?.charAt(0)
                  .toUpperCase()}${query.data.data.user.lastName?.charAt(0).toUpperCase()}`
            : '';
        setValue(forms.navbar.initial, initial);

        const menuItems = query.data.data.user?.roles.includes('admin')
          ? dashboardData.menu.admin.items.map((item) => {
              return {
                ...item,
                active: location.pathname.includes(item.href)
              };
            })
          : query.data.data.user?.roles.includes('site_admin')
          ? dashboardData.menu.site_admin.items.map((item) => {
              return {
                ...item,
                active: location.pathname.includes(item.href)
              };
            })
          : dashboardData.menu.user.items.map((item) => {
              return {
                ...item,
                active: location.pathname.includes(item.href)
              };
            });

        setValue(forms.sidebar.menu, menuItems);

        const navbarMenuItems = query.data.data.user?.roles.includes('admin')
          ? dashboardData.navbar_menu.admin.items
          : query.data.data.user?.roles.includes('site_admin')
          ? dashboardData.navbar_menu.site_admin.items
          : query.data.data.user?.roles.includes('user')
          ? dashboardData.navbar_menu.user.items
          : dashboardData.navbar_menu.unregistered_user.items;

        console.log({ navbarMenuItems, roles: query.data.data.user?.roles });
        setValue(forms.menu_dropdown.items, navbarMenuItems);
      }
    }
  }, [query.data]);

  useEffect(() => {
    if (!!query.error) {
      const auth0UserStorage = localStorage.getItem('auth0_user');
      const auth0User = user;

      if (!!auth0User) {
        setValue(forms.navbar.image, auth0User.picture as string);

        const name = auth0User?.nickname || '';
        setValue(forms.navbar.name, name);
        const email = auth0User?.email || '';
        setValue(forms.navbar.email, email);
        const initial = '';
        setValue(forms.navbar.initial, initial);

        const menuItems = dashboardData.menu.user.items.map((item) => {
          return {
            ...item,
            active: location.pathname.includes(item.href)
          };
        });

        setValue(forms.sidebar.menu, menuItems);

        const navbarMenuItems = dashboardData.navbar_menu.unregistered_user.items;

        setValue(forms.menu_dropdown.items, navbarMenuItems);
      }
    }
  }, [query.error]);

  return query;
};
