import { GetEstimatedUsageSuccessResponseInterface } from 'core/models/api/invoices';
import { forms } from './data';

export const role = [forms.role.name] as const;

export const tabs = [forms.tabs.active, forms.tabs.data] as const;

export const general = [
  forms.general.information,
  forms.general.invoices.data_table.data,
  forms.general.invoices.data_table.offset
] as const;

export const current_cyle = [
  forms.current_cycle.information,
  forms.current_cycle.detail.data_table.data,
  forms.current_cycle.detail.data_table.offset,
  forms.current_cycle.detail.data_table.services
] as const;

export const invoices = [
  forms.invoices.data_table.all_selected,
  forms.invoices.data_table.services,
  forms.invoices.data_table.data,
  forms.invoices.data_table.list_download,
  forms.invoices.data_table.offset
] as const;

export type BillingFormDataKeys =
  | (typeof invoices)[number]
  | (typeof tabs)[number]
  | (typeof role)[number]
  | (typeof general)[number]
  | (typeof current_cyle)[number];

export type DataTableInvoicesForm = {
  id: string;
  date: string;
  number: string;
  used_services: string;
  jobs: string;
  pages: string;
  due_date: string;
  status: string;
  total_amount: string;
  pay: string;
  pdf: string;
  checked: boolean;
};

export type CurrentCycleDataTableInvoicesForm = {
  date: string;
  used_services: string;
  job: string;
  job_name: string;
  job_number: string;
  pages: string;
};

export type BillingForm = Record<
  BillingFormDataKeys,
  | string
  | boolean
  | null
  | { id: string; name: string }
  | { id: string; name: string }[]
  | number
  | string[]
  | DataTableInvoicesForm[]
  | CurrentCycleDataTableInvoicesForm[]
  | GetEstimatedUsageSuccessResponseInterface['data']['estimatedOrganizationUsage']
>;
