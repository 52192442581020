import { Avatar } from 'core/components/Avatar';
import { stringToHslColor } from 'core/utils/color';
import * as React from 'react';
import clsx from 'clsx';
import { Typography } from 'core/components/Typography';

export interface IAdminRoleCellDataTableUsersProps {
  value?: string;
}

export const AdminRoleCellDataTableUsers = ({ value = '' }: IAdminRoleCellDataTableUsersProps) => {
  return (
    <div
      className={clsx(
        'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[1.5rem]',
        'w-full'
      )}>
      <div
        className={clsx(
          'w-[140px] h-[34px]',
          'grid grid-flow-col items-center content-center justify-start justify-items-start'
        )}>
        <div
          className={clsx(
            'flex items-center justify-center',
            'bg-[#EBE9F6]',
            'rounded-[0.25rem]',
            'px-[0.5rem] py-[0.25rem]'
          )}>
          <p
            className={clsx(
              'text-cta-purple-primary text-[0.75rem] font-semibold leading-[136%] tracking-[0.3px] uppercase'
            )}>
            {value}
          </p>
        </div>
      </div>
    </div>
  );
};
