import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { RegistrationReactQueryKey } from '../keys';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchPostInviteUsers } from 'core/services/registration';
import {
  PostInviteUsersSuccessResponseInterface,
  PostInviteUsersErrorResponseInterface,
  PostInviteUsersRequestInterface
} from 'core/models/api/registration';
import { useFormContext } from 'react-hook-form';
import { RegistrationForm } from 'features/registration/customer/react_hook_form/constants/type';
import { forms } from 'features/registration/customer/react_hook_form/constants/data';
import RegistrationData from '../../data/registration.json';

export const useRegistrationMutationInviteUsers = () => {
  const { user } = useAuth0();
  const { watch, setValue } = useFormContext<RegistrationForm>();
  const token = localStorage.getItem('token') ?? '';
  const invitedUsers = watch(forms.add_team_members.team_members) as {
    email: string;
    roles: { id: string; name: string } | null;
  }[];
  const mutation = useMutation<
    PostInviteUsersSuccessResponseInterface,
    PostInviteUsersErrorResponseInterface
  >(
    RegistrationReactQueryKey.MutationInviteUsers(),
    () => {
      const usersVariable = invitedUsers.map((invitedUser) => {
        return {
          email: invitedUser.email,
          roles: [invitedUser.roles?.id ?? '']
        };
      });

      const query = gql`
        mutation inviteUsers($organizationId: String = null, $users: [InviteUserInput!]!) {
          inviteUsers(input: { organizationId: $organizationId, users: $users }) {
            users {
              id
            }
          }
        }
      `;

      const variables = {
        organizationId: localStorage.getItem('organizationId') ?? '',
        users: usersVariable
      };
      const headers = {
        Authorization: `Bearer ${token}`
      };

      const payload: PostInviteUsersRequestInterface = {
        url: process.env.REACT_APP_GRAPHQL_API_URL ?? '',
        query: query,
        variables: variables,
        headers: headers
      };

      return fetchPostInviteUsers(payload);
    },
    {
      retry: 0,
      onError(error, variables, context) {
        setTimeout(() => {
          setValue(forms.add_team_members.alert, true);
          setValue(
            forms.add_team_members.alert_message,
            RegistrationData.workspace.alert.message.replace(
              '{{error}}',
              error.response.errors[0].message ?? ''
            )
          );
        }, 5000);
      },
      onSuccess(data) {
        if (user?.email_verified) {
          window.location.href = `${process.env.REACT_APP_REDIRECT_CUSTOMER_REGISTRATION_LINK}`;
        } else {
          setValue(forms.user.is_email_verified, false);
        }
      }
    }
  );

  return mutation;
};
