import * as React from 'react';
import clsx from 'clsx';

export interface CancelIconProps extends React.SVGAttributes<HTMLOrSVGElement> {
  iconSize?: 'XL' | 'L' | 'M' | 'S' | 'XS';
}

export const CancelIcon = ({ iconSize, className, ...otherProps }: CancelIconProps) => {
  const svgSizeClassName =
    iconSize === 'XL'
      ? 'w-[2.5rem] h-[2.5rem]'
      : iconSize === 'L'
      ? 'w-[2rem] h-[2rem]'
      : iconSize === 'M'
      ? 'w-[1.5rem] h-[1.5rem]'
      : iconSize === 'S'
      ? 'w-[1.25rem] h-[1.25rem]'
      : 'w-[1rem] h-[1rem]';
  return (
    <svg
      className={clsx(svgSizeClassName, className)}
      {...otherProps}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M13 3L8 8M8 8L3 13M8 8L13 13M8 8L3 3" {...otherProps} strokeWidth="1.5" />
    </svg>
  );
};
