import { useGeneralBillingQueryGetinvoices } from 'features/billing/react_query/hooks/useQueryGetInvoices.billing';
import { GeneralDataTableBilling } from '../GeneralDataTable';
import { GeneralInformationBilling } from '../GeneralInformation';

export const GeneralBilling = () => {
  useGeneralBillingQueryGetinvoices();
  return (
    <div className="w-full">
      <GeneralInformationBilling />
      <GeneralDataTableBilling />
    </div>
  );
};
