import * as React from 'react';
import clsx from 'clsx';

export interface BackdropProps {
  onClick?: () => void;
}

export const Backdrop = ({ onClick }: BackdropProps) => {
  return (
    <div
      data-testid={'backdrop'}
      className={clsx('fixed top-0 left-0 bottom-0 right-0', 'z-40', 'bg-[rgba(14,16,16,0.25)]')}
      onClick={onClick}
    />
  );
};
