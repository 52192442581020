export const getStatusLabelBackgroundColorInvoiceTable = (label: string) => {
  switch (label.toLowerCase()) {
    case 'paid': {
      return 'bg-success-tint';
    }
    case 'overdue': {
      return 'bg-error-tint';
    }
    case 'open': {
      return 'bg-warning-tint';
    }
    case 'submitted': {
      return 'bg-cta-purple-tint';
    }
    case 'unpaid': {
      return 'bg-warning-tint';
    }
    case 'error': {
      return 'bg-error-tint';
    }
    case 'failed': {
      return 'bg-error-tint';
    }
    default:
      break;
  }
};
export const getStatusLabelColorInvoiceTable = (label: string) => {
  switch (label.toLowerCase()) {
    case 'paid': {
      return 'text-success-primary';
    }
    case 'overdue': {
      return 'text-error-primary';
    }
    case 'open': {
      return 'text-warning-primary';
    }
    case 'submitted': {
      return 'text-cta-purple-primary';
    }
    case 'unpaid': {
      return 'text-warning-primary';
    }
    case 'error': {
      return 'text-error-primary';
    }
    case 'failed': {
      return 'text-error-primary';
    }
    default:
      break;
  }
};
