import { Typography } from 'core/components/Typography';
import * as React from 'react';

export interface DueDateCellDataTableBillingProps {
  label?: string;
}

export const DueDateCellDataTableBilling = ({ label = '' }: DueDateCellDataTableBillingProps) => {
  return (
    <Typography variant={'Regular'} size={'XS'} color={'text-grayscale-70'}>
      {label}
    </Typography>
  );
};
