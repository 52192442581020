import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  GetUserRequestInterface,
  GetUserSuccessResponseInterface
} from 'core/models/api/registration';
import { BillingReactQueryKey } from '../keys';
import { gql } from 'graphql-request';
import { fetchGetUser } from 'core/services/registration';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { BillingForm, forms } from 'features/billing/react_hook_form/constants';
import AccountData from 'features/billing/data/invoices.json';

export const useBillingQueryGetUser = () => {
  const token = localStorage.getItem('token') ?? '';
  const { setValue } = useFormContext<BillingForm>();
  const query = useQuery<GetUserSuccessResponseInterface, AxiosError>(
    BillingReactQueryKey.QueryGetUser(),
    () => {
      const payload: GetUserRequestInterface = {
        headers: {
          token: token
        },
        data: {
          query: gql`
              query {
                user(${
                  localStorage.getItem('userId')
                    ? `id:"${localStorage.getItem('userId')}"`
                    : 'id:null'
                }) {
                  id
                  firstName
                  lastName
                  email
                  linkedInUrl
                  positionInCompany
                  contactNumber
                  profileImageUrl
                  roles
                  organization {
                    name
                    legalEntityName
                    country
                    city
                    region
                    about
                    id
                    websiteUrl
                    contactNumber
                  }
                }
              }
            `
        }
      };

      return fetchGetUser(payload);
    },
    {
      retry: 0,
      enabled: localStorage.getItem('userId') !== null
    }
  );

  useEffect(() => {
    if (query.data) {
      setValue(forms.role.name, String(query.data.data.user?.roles));
      setValue(forms.tabs.data, AccountData.tabs);
    }
  }, [query.data]);

  return query;
};
