import { useMutation, useQuery } from '@tanstack/react-query';
import { RegistrationReactQueryKey } from '../keys';
import { IdToken, useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useReviewerRegistrationMutationGetUser } from './useQueryGetUser.registration.reviewer';

export const useReviewerRegistrationGetIdTokenClaims = () => {
  const { isLoading: isLoadingAuth0, getIdTokenClaims } = useAuth0();

  const { mutate: mutationGetUser } = useReviewerRegistrationMutationGetUser();

  const mutation = useMutation<IdToken | undefined>(
    RegistrationReactQueryKey.GetIdTokenClaims(),
    () => {
      return getIdTokenClaims()
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    {
      retry: 0
    }
  );

  useEffect(() => {
    if (mutation.data) {
      if (typeof mutation.data?.__raw !== 'undefined') {
        localStorage.setItem('idToken', mutation.data.__raw);

        mutationGetUser(mutation.data);
      }
    }
  }, [mutation.data]);

  return mutation;
};
