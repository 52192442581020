import clsx from 'clsx';
import { Typography } from 'core/components/Typography';
import * as React from 'react';
import RegistrationData from '../../data/registration.json';
import { Field } from 'core/components/Field';
import { useFormContext } from 'react-hook-form';
import { Button } from 'core/components/Button';
import { SelectField } from 'core/components/SelectField';
import { RegistrationForm } from 'features/registration/customer/react_hook_form/constants/type';
import { forms } from 'features/registration/customer/react_hook_form/constants/data';
import { useRegistrationGetOrganizationExist } from '../../react_query/hooks/useGetOrganizationExist.registration';

export const CreateWorkspaceRegistration = () => {
  const {
    watch,
    formState: { errors },
    setError,
    setValue,
    clearErrors
  } = useFormContext<RegistrationForm>();

  const { mutate: getOrganizationExist } = useRegistrationGetOrganizationExist();

  const handleChangeCompanyName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(forms.create_workspace.company_name, e.currentTarget.value);
    if (!e.currentTarget.value.length) {
      setError(forms.create_workspace.company_name, {
        message: RegistrationData.workspace.create_workspace.forms.errors.required
      });
    } else {
      getOrganizationExist({ organizationName: e.currentTarget.value });
      clearErrors(forms.create_workspace.company_name);
    }
  };
  const handleChangeCity = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(forms.create_workspace.city, e.currentTarget.value);
    if (!e.currentTarget.value.length) {
      setError(forms.create_workspace.city, {
        message: RegistrationData.workspace.create_workspace.forms.errors.required
      });
    } else {
      clearErrors(forms.create_workspace.city);
    }
  };
  const handleChangeRegion = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(forms.create_workspace.state_region, e.currentTarget.value);
    if (!e.currentTarget.value.length) {
      setError(forms.create_workspace.state_region, {
        message: RegistrationData.workspace.create_workspace.forms.errors.required
      });
    } else {
      clearErrors(forms.create_workspace.state_region);
    }
  };

  const handleSelectCountry = (data: { id: string; name: string }) => {
    setValue(forms.create_workspace.country, data);
  };

  const handleChangeZipCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(forms.create_workspace.zip_code, e.currentTarget.value);
    if (!e.currentTarget.value.length) {
      setError(forms.create_workspace.zip_code, {
        message: RegistrationData.workspace.create_workspace.forms.errors.required
      });
    } else {
      clearErrors(forms.create_workspace.zip_code);
    }
  };

  const handleChangeAddressLine1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(forms.create_workspace.address_line_1, e.currentTarget.value);
    if (!e.currentTarget.value.length) {
      setError(forms.create_workspace.address_line_1, {
        message: RegistrationData.workspace.create_workspace.forms.errors.required
      });
    } else {
      clearErrors(forms.create_workspace.address_line_1);
    }
  };
  const handleChangeAddressLine2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(forms.create_workspace.address_line_2, e.currentTarget.value);
  };
  const handleChangeBillingEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(forms.create_workspace.billing_email, e.currentTarget.value);
    if (!e.currentTarget.value.length) {
      setError(forms.create_workspace.billing_email, {
        message: RegistrationData.workspace.create_workspace.forms.errors.required
      });
    } else {
      clearErrors(forms.create_workspace.billing_email);
    }
  };

  const handleChangeContactNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(forms.create_workspace.contact_number, e.currentTarget.value);
    if (!e.currentTarget.value.length) {
      setError(forms.create_workspace.contact_number, {
        message: RegistrationData.workspace.create_workspace.forms.errors.required
      });
    } else {
      clearErrors(forms.create_workspace.contact_number);
    }
  };

  const handleClickNext = () => {
    setValue(forms.state.create_workspace_step, 'add_more_information');
  };

  const isButtonDisabled =
    Object.keys(errors).length > 0 ||
    !(watch(forms.create_workspace.company_name) as string).length ||
    !(watch(forms.create_workspace.city) as string).length ||
    !(watch(forms.create_workspace.state_region) as string).length ||
    !(watch(forms.create_workspace.country) as null | { id: string; name: string }) ||
    !(watch(forms.create_workspace.zip_code) as string).length ||
    !(watch(forms.create_workspace.contact_number) as string).length ||
    !(watch(forms.create_workspace.address_line_1) as string).length ||
    !(watch(forms.create_workspace.billing_email) as string).length;

  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-items-center place-content-center gap-[2.5rem]',
        'w-[350px]'
      )}>
      <div
        className={clsx(
          'grid grid-cols-1 place-items-center place-content-center gap-[0.25rem]',
          'w-full'
        )}>
        <Typography variant={'Heading'} size={'H1'} color={'text-grayscale-90'}>
          {RegistrationData.workspace.create_workspace.title}
        </Typography>
        <Typography variant={'Regular'} size={'M'} color={'text-grayscale-70'}>
          {RegistrationData.workspace.create_workspace.message}
        </Typography>
      </div>

      {/* form */}
      <div
        className={clsx(
          'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[1.25rem]',
          'w-full'
        )}>
        <Field
          key={RegistrationData.workspace.create_workspace.forms.company_name.name}
          mainLabel={RegistrationData.workspace.create_workspace.forms.company_name.mainLabel}
          name={forms.create_workspace.company_name}
          secondaryLabel={
            RegistrationData.workspace.create_workspace.forms.company_name.secondaryLabel
          }
          placeholder={RegistrationData.workspace.create_workspace.forms.company_name.placeholder}
          variant={!!errors[forms.create_workspace.company_name] ? 'error' : 'default'}
          value={watch(forms.create_workspace.company_name) as string}
          onChange={handleChangeCompanyName}
          helperText={errors[forms.create_workspace.company_name]?.message as string}
        />

        <div
          className={clsx(
            'grid grid-cols-2 items-start content-start justify-center justify-items-center gap-[1rem]',
            'w-full'
          )}>
          {/* city */}
          <Field
            key={RegistrationData.workspace.create_workspace.forms.city.name}
            mainLabel={RegistrationData.workspace.create_workspace.forms.city.mainLabel}
            name={forms.create_workspace.city}
            secondaryLabel={RegistrationData.workspace.create_workspace.forms.city.secondaryLabel}
            placeholder={RegistrationData.workspace.create_workspace.forms.city.placeholder}
            variant={!!errors[forms.create_workspace.city] ? 'error' : 'default'}
            value={watch(forms.create_workspace.city) as string}
            onChange={handleChangeCity}
            helperText={errors[forms.create_workspace.city]?.message as string}
          />

          {/* state/region */}
          <Field
            key={RegistrationData.workspace.create_workspace.forms.state_region.name}
            mainLabel={RegistrationData.workspace.create_workspace.forms.state_region.mainLabel}
            name={forms.create_workspace.state_region}
            secondaryLabel={
              RegistrationData.workspace.create_workspace.forms.state_region.secondaryLabel
            }
            placeholder={RegistrationData.workspace.create_workspace.forms.state_region.placeholder}
            variant={!!errors[forms.create_workspace.state_region] ? 'error' : 'default'}
            value={watch(forms.create_workspace.state_region) as string}
            onChange={handleChangeRegion}
            helperText={errors[forms.create_workspace.state_region]?.message as string}
          />
        </div>

        <div
          className={clsx(
            'grid grid-cols-2 items-start content-start justify-center justify-items-center gap-[1rem]',
            'w-full'
          )}>
          {/* country */}

          <SelectField
            key={RegistrationData.workspace.create_workspace.forms.country.name}
            mainLabel={RegistrationData.workspace.create_workspace.forms.country.mainLabel}
            placeholder={RegistrationData.workspace.create_workspace.forms.country.placeholder}
            options={RegistrationData.workspace.create_workspace.forms.country.options}
            selected={watch(forms.create_workspace.country) as null | { id: string; name: string }}
            onSelect={handleSelectCountry}
          />

          {/* zip_code */}
          <Field
            key={RegistrationData.workspace.create_workspace.forms.zip_code.name}
            mainLabel={RegistrationData.workspace.create_workspace.forms.zip_code.mainLabel}
            name={forms.create_workspace.zip_code}
            secondaryLabel={
              RegistrationData.workspace.create_workspace.forms.zip_code.secondaryLabel
            }
            placeholder={RegistrationData.workspace.create_workspace.forms.zip_code.placeholder}
            variant={!!errors[forms.create_workspace.zip_code] ? 'error' : 'default'}
            value={watch(forms.create_workspace.zip_code) as string}
            onChange={handleChangeZipCode}
            helperText={errors[forms.create_workspace.zip_code]?.message as string}
          />
        </div>

        <div
          className={clsx(
            'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[1rem]',
            'w-full'
          )}>
          {/* address line 1 */}
          <Field
            key={RegistrationData.workspace.create_workspace.forms.address_line_1.name}
            mainLabel={RegistrationData.workspace.create_workspace.forms.address_line_1.mainLabel}
            name={forms.create_workspace.address_line_1}
            secondaryLabel={
              RegistrationData.workspace.create_workspace.forms.address_line_1.secondaryLabel
            }
            placeholder={
              RegistrationData.workspace.create_workspace.forms.address_line_1.placeholder
            }
            variant={!!errors[forms.create_workspace.address_line_1] ? 'error' : 'default'}
            value={watch(forms.create_workspace.address_line_1) as string}
            onChange={handleChangeAddressLine1}
            helperText={errors[forms.create_workspace.address_line_1]?.message as string}
          />
        </div>

        <div
          className={clsx(
            'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[1rem]',
            'w-full'
          )}>
          {/* address line 2 */}
          <Field
            key={RegistrationData.workspace.create_workspace.forms.address_line_2.name}
            mainLabel={RegistrationData.workspace.create_workspace.forms.address_line_2.mainLabel}
            name={forms.create_workspace.address_line_2}
            secondaryLabel={
              RegistrationData.workspace.create_workspace.forms.address_line_2.secondaryLabel
            }
            placeholder={
              RegistrationData.workspace.create_workspace.forms.address_line_2.placeholder
            }
            variant={!!errors[forms.create_workspace.address_line_2] ? 'error' : 'default'}
            value={watch(forms.create_workspace.address_line_2) as string}
            onChange={handleChangeAddressLine2}
            helperText={errors[forms.create_workspace.address_line_2]?.message as string}
          />
        </div>

        <div
          className={clsx(
            'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[1rem]',
            'w-full'
          )}>
          {/* billing email */}
          <Field
            key={RegistrationData.workspace.create_workspace.forms.billing_email.name}
            mainLabel={RegistrationData.workspace.create_workspace.forms.billing_email.mainLabel}
            name={forms.create_workspace.billing_email}
            secondaryLabel={
              RegistrationData.workspace.create_workspace.forms.billing_email.secondaryLabel
            }
            placeholder={
              RegistrationData.workspace.create_workspace.forms.billing_email.placeholder
            }
            variant={!!errors[forms.create_workspace.billing_email] ? 'error' : 'default'}
            value={watch(forms.create_workspace.billing_email) as string}
            onChange={handleChangeBillingEmail}
            helperText={errors[forms.create_workspace.billing_email]?.message as string}
          />
        </div>

        <div
          className={clsx(
            'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[1rem]',
            'w-full'
          )}>
          {/* contact_number */}
          <Field
            key={RegistrationData.workspace.create_workspace.forms.contact_number.name}
            mainLabel={RegistrationData.workspace.create_workspace.forms.contact_number.mainLabel}
            name={forms.create_workspace.contact_number}
            secondaryLabel={
              RegistrationData.workspace.create_workspace.forms.contact_number.secondaryLabel
            }
            placeholder={
              RegistrationData.workspace.create_workspace.forms.contact_number.placeholder
            }
            variant={!!errors[forms.create_workspace.contact_number] ? 'error' : 'default'}
            value={watch(forms.create_workspace.contact_number) as string}
            onChange={handleChangeContactNumber}
            helperText={errors[forms.create_workspace.contact_number]?.message as string}
          />
        </div>

        <div
          className={clsx(
            'grid grid-cols-1 place-content-start place-items-start gap-[1rem]',
            'w-full'
          )}>
          <Button
            disabled={isButtonDisabled}
            placeholder={RegistrationData.workspace.create_workspace.actions.primary}
            variant={'primary'}
            fullWidth
            onClick={handleClickNext}>
            {RegistrationData.workspace.create_workspace.actions.primary}
          </Button>
        </div>
      </div>
      {/* end form */}
    </div>
  );
};
