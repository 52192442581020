import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { UsersReactQueryKey } from '../keys';
import { useFormContext } from 'react-hook-form';
import { fetchPostUpdateUser } from 'core/services/registration';
import {
  GetUsersRequestInterface,
  PostUpdateUserRequestInterface,
  PostUpdateUserSuccessResponseInterface
} from 'core/models/api/registration';
import { AccountForm } from 'features/account/react_hook_form/constants/type';
import { useEffect, useMemo } from 'react';
import { forms } from 'features/users/react_hook_form/constants/data';
import { UsersForm } from 'features/users/react_hook_form/constants';
import { queryClient } from 'index';
import { useUsersQueryGetUsers } from './useQueryGetUsers.users';

export const useUsersStatusMutationUpdateUser = () => {
  const { refetch: refetchUsers } = useUsersQueryGetUsers();
  const { watch, setValue } = useFormContext<UsersForm>();
  const token = localStorage.getItem('token') ?? '';

  const LIMIT = 10;

  const usersPayload: GetUsersRequestInterface = useMemo(() => {
    const OFFSET = watch(forms.data_table.offset) as number;

    return {
      headers: {
        token: token
      },
      data: {
        query: gql`
          query {
            users(${
              !(watch(forms.data_table.search) as string).length
                ? 'search:null'
                : `search:"${watch(forms.data_table.search)}"`
            },
            limit:${LIMIT},
            offset:${OFFSET},
            sort:"${(watch(forms.data_table.sort_by) as { id: string; name: string }).id}"
          ) {
              email
              firstName
              lastName
              roles
              status
              createdAt
            }
          }
        `
      }
    };
  }, [
    watch(forms.data_table.sort_by) as { id: string; name: string },
    watch(forms.data_table.search) as string,
    watch(forms.data_table.offset) as number,
    token,
    LIMIT,
    watch(forms.data_table.sort_by) as { id: string; name: string }
  ]);

  const mutation = useMutation<
    PostUpdateUserSuccessResponseInterface,
    AxiosError,
    { id: string; name: string }
  >(
    UsersReactQueryKey.MutationUpdateUserStatus(),
    (data: { id: string; name: string }) => {
      const payload: PostUpdateUserRequestInterface = {
        headers: {
          token: token
        },

        data: {
          query: gql`
            mutation {
              updateUser(
                ${
                  localStorage.getItem('userId')
                    ? `id:"${localStorage.getItem('userId')}",`
                    : `id:null,`
                }
                input:{
                  ${`status:${data.id},`}
              }){
                id,
                firstName,
                lastName,
                email,
                linkedInUrl,
                positionInCompany,
                contactNumber,
                profileImageUrl
              }
            }
          `
        }
      };

      return fetchPostUpdateUser(payload);
    },
    {
      retry: 0,
      onSuccess() {
        refetchUsers();
      }
    }
  );

  // useEffect(() => {
  //   setValue(forms.personal_information.success, mutation.isSuccess ? true : false);
  //   if (mutation.isSuccess) {
  //     const timer = setTimeout(() => {
  //       setValue(forms.personal_information.success, false);
  //     }, 2000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [mutation.isSuccess]);

  return mutation;
};

export const useUsersRolesMutationUpdateUser = () => {
  const { refetch: refetchUsers } = useUsersQueryGetUsers();
  const { watch, setValue } = useFormContext<UsersForm>();
  const token = localStorage.getItem('token') ?? '';

  const LIMIT = 10;

  const usersPayload: GetUsersRequestInterface = useMemo(() => {
    const OFFSET = watch(forms.data_table.offset) as number;

    return {
      headers: {
        token: token
      },
      data: {
        query: gql`
          query {
            users(${
              !(watch(forms.data_table.search) as string).length
                ? 'search:null'
                : `search:"${watch(forms.data_table.search)}"`
            },
            limit:${LIMIT},
            offset:${OFFSET},
            sort:"${(watch(forms.data_table.sort_by) as { id: string; name: string }).id}"
          ) {
              email
              firstName
              lastName
              roles
              status
              createdAt
            }
          }
        `
      }
    };
  }, [
    watch(forms.data_table.sort_by) as { id: string; name: string },
    watch(forms.data_table.search) as string,
    watch(forms.data_table.offset) as number,
    token,
    LIMIT,
    watch(forms.data_table.sort_by) as { id: string; name: string }
  ]);

  const mutation = useMutation<
    PostUpdateUserSuccessResponseInterface,
    AxiosError,
    { id: string; name: string }
  >(
    UsersReactQueryKey.MutationUpdateUserRoles(),
    (data: { id: string; name: string }) => {
      const payload: PostUpdateUserRequestInterface = {
        headers: {
          token: token
        },

        data: {
          query: gql`
            mutation {
              updateUser(
                ${
                  localStorage.getItem('userId')
                    ? `id:"${localStorage.getItem('userId')}",`
                    : `id:null,`
                }
                input:{
                  ${`roles:["${data.id}"]`}
              }){
                id,
                firstName,
                lastName,
                email,
                linkedInUrl,
                positionInCompany,
                contactNumber,
                profileImageUrl
              }
            }
          `
        }
      };

      return fetchPostUpdateUser(payload);
    },
    {
      retry: 0,
      onSuccess() {
        refetchUsers();
      }
    }
  );

  // useEffect(() => {
  //   setValue(forms.personal_information.success, mutation.isSuccess ? true : false);
  //   if (mutation.isSuccess) {
  //     const timer = setTimeout(() => {
  //       setValue(forms.personal_information.success, false);
  //     }, 2000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [mutation.isSuccess]);

  return mutation;
};
