import * as React from 'react';
import clsx from 'clsx';

export interface WaitingIconProps extends React.SVGAttributes<HTMLOrSVGElement> {
  iconSize?: 'XL' | 'L' | 'M' | 'S' | 'XS';
}

export const WaitingIcon = ({ iconSize, className, ...otherProps }: WaitingIconProps) => {
  const svgSizeClassName =
    iconSize === 'XL'
      ? 'w-[2.5rem] h-[2.5rem]'
      : iconSize === 'L'
      ? 'w-[2rem] h-[2rem]'
      : iconSize === 'M'
      ? 'w-[1.5rem] h-[1.5rem]'
      : iconSize === 'S'
      ? 'w-[1.25rem] h-[1.25rem]'
      : 'w-[1rem] h-[1rem]';
  return (
    <svg
      className={clsx(svgSizeClassName, className)}
      {...otherProps}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.002 6.27051V11.4851C12.002 11.808 12.1579 12.1111 12.4207 12.2988L15.6478 14.6038"
        stroke="#7E8180"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5C15.6775 21.5 18.8669 19.4104 20.4458 16.3539"
        stroke="#7E8180"
        strokeWidth="1.5"
      />
      <path
        d="M21.2927 10.0264C22.3827 15.1586 19.1058 20.2027 13.9736 21.2927C8.84138 22.3827 3.79727 19.1058 2.70727 13.9736C1.61726 8.84138 4.89414 3.79727 10.0264 2.70727C15.1586 1.61726 20.2027 4.89414 21.2927 10.0264Z"
        stroke="#7E8180"
        strokeWidth="1.5"
        strokeDasharray="3 3"
      />
    </svg>
  );
};
