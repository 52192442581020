import * as React from 'react';
import clsx from 'clsx';
import CustomerHomeData from 'features/home/customer/data/customer.home.json';
import { Typography } from 'core/components/Typography';
import { Link } from 'react-router-dom';
import { EmailIcon } from 'core/icons/Email';
import { useFormContext } from 'react-hook-form';
import { CustomerHomeForm, forms } from '../../react_hook_form/constants';

export const FooterCustomerHome = () => {
  const { watch, setValue } = useFormContext<CustomerHomeForm>();

  const handleChangeNewsletterEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(forms.newsletter.email, e.currentTarget.value);
  };
  return (
    <div
      className={clsx(
        'grid grid-cols-1 items-start content-start justify-center justify-items-center',
        'w-full',
        'px-[4rem] py-[9rem]'
      )}>
      <div
        className={clsx(
          'grid grid-cols-1 items-start content-start justify-between justify-items-start gap-[2rem]',
          'w-full max-w-[1200px]'
        )}>
        <div
          className={clsx(
            'grid grid-cols-1 lg:grid-flow-col items-start content-start justify-between justify-items-start gap-y-[2rem] gap-x-[2rem]',
            'w-full'
          )}>
          {/* logo and adress */}
          <div
            className={clsx(
              'grid grid-cols-1 place-content-start place-items-start gap-[2rem]',
              'w-full max-w-[170px]'
            )}>
            <img src={'/logo/applied_ai_icon.svg'} />
            <Typography variant={'Regular'} size={'S'} color={'text-grayscale-black'}>
              {CustomerHomeData.footer.address}
            </Typography>
          </div>

          {/* link */}
          <div
            className={clsx(
              'grid grid-cols-1 sm:grid-cols-3 lg:grid-flow-col place-content-start place-items-start gap-[1rem] sm:gap-[5rem]',
              'lg:w-fit w-full'
            )}>
            <div
              className={clsx(
                'grid grid-cols-1 place-content-start place-items-start gap-[0.5rem]',
                'w-full'
              )}>
              <Link to={CustomerHomeData.footer.service.link}>
                <Typography variant={'Medium'} size={'M'} color={'text-grayscale-black'}>
                  {CustomerHomeData.footer.service.label}
                </Typography>
              </Link>
              {CustomerHomeData.footer.service.links.items.map((item) => (
                <Link key={item.name} to={item.link}>
                  <Typography variant={'Medium'} size={'M'} color={'text-grayscale-black'}>
                    {item.name}
                  </Typography>
                </Link>
              ))}
            </div>

            <div
              className={clsx(
                'grid grid-cols-1 place-content-start place-items-start gap-[0.5rem]',
                'w-full'
              )}>
              <Link to={CustomerHomeData.footer.graphene.link}>
                <Typography variant={'Medium'} size={'M'} color={'text-grayscale-black'}>
                  {CustomerHomeData.footer.graphene.label}
                </Typography>
              </Link>
              {CustomerHomeData.footer.graphene.links.items.map((item) => (
                <Link key={item.name} to={item.link}>
                  <Typography variant={'Medium'} size={'M'} color={'text-grayscale-black'}>
                    {item.name}
                  </Typography>
                </Link>
              ))}
            </div>

            <div
              className={clsx(
                'grid grid-cols-1 place-content-start place-items-start gap-[0.5rem]',
                'w-full'
              )}>
              <Typography variant={'Medium'} size={'M'} color={'text-grayscale-black'}>
                {CustomerHomeData.footer.contact_us.label}
              </Typography>

              <Link to={CustomerHomeData.footer.contact_us.link}>
                <Typography variant={'Regular'} size={'S'} color={'text-grayscale-black'}>
                  {CustomerHomeData.footer.contact_us.email}
                </Typography>
              </Link>

              <div
                className={clsx(
                  'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.5rem]'
                )}>
                <Link
                  to={CustomerHomeData.footer.contact_us.linkedin.link}
                  className={clsx(
                    'flex items-center justify-center',
                    'p-[0.5rem]',
                    'rounded-[0.375rem]',
                    'border border-[#EFEFEF]'
                  )}>
                  <img
                    src={CustomerHomeData.footer.contact_us.linkedin.icon}
                    className={clsx('w-[2rem] h-[2rem]')}
                    alt={CustomerHomeData.footer.contact_us.linkedin.link}
                  />
                </Link>
                <Link
                  to={CustomerHomeData.footer.contact_us.twitter.link}
                  className={clsx(
                    'flex items-center justify-center',
                    'p-[0.5rem]',
                    'rounded-[0.375rem]',
                    'border border-[#EFEFEF]'
                  )}>
                  <img
                    src={CustomerHomeData.footer.contact_us.twitter.icon}
                    className={clsx('w-[2rem] h-[2rem]')}
                    alt={CustomerHomeData.footer.contact_us.twitter.link}
                  />
                </Link>
              </div>
            </div>
          </div>

          {/* newsletter */}
          <div
            className={clsx(
              'grid grid-cols-1 place-content-start place-items-start gap-[1rem]',
              'bg-grayscale-20',
              'px-[2rem] py-[1.5rem]',
              'rounded-[0.75rem]',
              'lg:w-fit w-full'
            )}>
            <div
              className={clsx('grid grid-cols-1 place-content-start place-items-start gap-[1rem]')}>
              <h5
                className={clsx(
                  'text-[1.5rem] text-grayscale-black font-eliza font-medium leading-[150%] tracking-[-0.32px]'
                )}>
                {CustomerHomeData.footer.newsletter.input.label}
              </h5>
              {/* input */}
              <div
                className={clsx(
                  'grid grid-cols-1 place-content-start place-items-start',
                  'rounded-[0.25rem]',
                  'px-[1rem] py-[0.75rem]',
                  'bg-grayscale-10',
                  'min-w-[356px]'
                )}>
                <div
                  className={clsx(
                    'grid grid-cols-[1fr_auto] items-center content-center justify-between justify-items-start gap-[0.5rem]',
                    'w-full'
                  )}>
                  <input
                    className={clsx(
                      'bg-transparent',
                      'outline-none',
                      'w-full',
                      'placeholder:text-[0.875rem] placeholder:text-grayscale-60 placeholder:leading-[146%] placeholder:tracking-[-0.14px] font-normal',
                      'text-[0.875rem] text-grayscale-90 font-normal leading-[146%] tracking-[-0.14px]'
                    )}
                    placeholder={CustomerHomeData.footer.newsletter.input.placeholder}
                    value={watch(forms.newsletter.email) as string}
                    onChange={handleChangeNewsletterEmail}
                  />
                  <EmailIcon iconSize={'XS'} className={clsx('stroke-grayscale-black')} />
                </div>
              </div>
            </div>
            <button
              className={clsx(
                'flex items-center justify-center',
                'w-full',
                'bg-grayscale-black',
                'rounded-[0.375rem]',
                'py-[0.75rem]',
                'text-[1rem] text-grayscale-10 font-medium leading-[150%] tracking-[-0.32px]'
              )}>
              {CustomerHomeData.footer.newsletter.actions.subscribe}
            </button>
          </div>
        </div>
        {/* divider */}
        <div className={clsx('w-full h-[1px]', 'bg-[#EFEFEF]')} />

        <div
          className={clsx(
            'grid grid-cols-1 lg:grid-flow-col items-center content-center justify-between justify-ites-start gap-[1rem]',
            'w-full'
          )}>
          <Typography variant={'Medium'} size={'XS'} color={'text-grayscale-75'}>
            {CustomerHomeData.footer.credits.label}
          </Typography>

          <div
            className={clsx(
              'grid grid-cols-1 sm:grid-flow-col items-center content-center justify-start justify-items-start sm:justify-end sm:justify-items-end gap-[1rem]'
            )}>
            {CustomerHomeData.footer.credits.links.items.map((item) => (
              <Link key={item.name} to={item.link} target='_blank'>
                <Typography
                  variant={'Link'}
                  size={'S'}
                  color={'text-grayscale-75'}
                  className={'underline'}>
                  {item.name}
                </Typography>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
