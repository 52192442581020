import * as React from 'react';
import clsx from 'clsx';
import { Typography } from '../Typography';
import { WarningIcon } from 'core/icons/Warning/Warning.icon';

export interface BannerProps {
  message?: string;
}

export const Banner = ({ message = '' }: BannerProps) => {
  return (
    <div
      className={clsx(
        'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.75rem]',
        'w-full',
        'px-[1rem] py-[0.5rem]',
        'bg-warning-tint',
        'rounded-[0.25rem]'
      )}>
      <WarningIcon className={clsx('w-[1rem] h-[1rem]', 'stroke-grayscale-90')} />
      <Typography variant={'Regular'} size={'S'} color={'text-grayscale-90'}>
        {message}
      </Typography>
    </div>
  );
};
