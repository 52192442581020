import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import clsx from 'clsx';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

interface PaymentDropdownBillingProps {
  placeholder?: string;
  options?: { id: string; name: string }[];
  onSelect: (data: { id: string; name: string }) => void;
}

export const PaymentDropdownBilling = ({
  placeholder = '',
  options = [],
  onSelect
}: PaymentDropdownBillingProps) => {
  return (
    <Menu as="div" className={clsx('relative inline-block text-left')}>
      <div>
        <Menu.Button
          data-testid={'payment-dropdown-invoices-button'}
          className={clsx(
            'inline-flex justify-center items-center',
            'rounded-[0.25rem]',
            'bg-grayscale-90',
            'w-[5rem] h-[2rem]',
            'text-[0.875rem] font-medium text-grayscale-10 tracking-[-0.14px] leading-[146%]'
          )}>
          {placeholder}
          <ChevronDownIcon
            className={clsx('ml-2 -mr-1', 'h-5 w-5', 'text-violet-200 hover:text-violet-100')}
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter={clsx('transition ease-out duration-100')}
        enterFrom={clsx('transform opacity-0 scale-95')}
        enterTo={clsx('transform opacity-100 scale-100')}
        leave={clsx('transition ease-in duration-75')}
        leaveFrom={clsx('transform opacity-100 scale-100')}
        leaveTo={clsx('transform opacity-0 scale-95')}>
        <Menu.Items
          className={clsx(
            'absolute',
            'z-10',
            'shadow-lg',
            'bg-grayscale-10',
            'rounded-[0.25rem]',
            'origin-top-right',
            'right-0',
            'mt-2'
          )}>
          {options.map((option) => (
            <div key={option.id} className={clsx('px-1 py-1 ')}>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={clsx(
                      active ? 'bg-grayscale-20' : 'bg-grayscale-10',
                      active ? 'text-grayscale-90' : 'text-grayscale-80',
                      'group',
                      'flex items-center justify-start',
                      'w-full',
                      'px-[0.5rem] py-[0.375rem]',
                      'text-[13px] font-normal leading-[136%] tracking-[-0.13px]',
                      'rounded-[0.25rem]',
                      'whitespace-nowrap'
                    )}
                    onClick={() => {
                      onSelect(option);
                    }}>
                    {option.name}
                  </button>
                )}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
