import { PopUp, type PopUpProps } from 'core/components/PopUp/PopUp';
import * as React from 'react';
import clsx from 'clsx';
import { Typography } from 'core/components/Typography';
import { Button } from 'core/components/Button';
import { FileList } from 'core/components/FileList';

export interface RetryPaymentPopUpBillingProps extends PopUpProps {
  message?: string;
  description?: string;
  cta?: {
    primary: string;
    disabled: boolean;
  };
  onSubmit?: () => void;
}

export const RetryPaymentPopUpBilling = ({
  message = '',
  description = '',
  cta = {
    primary: '',
    disabled: true
  },
  onSubmit,
  ...otherProps
}: RetryPaymentPopUpBillingProps) => {
  const [names, setNames] = React.useState<string[]>(['chucknorris.png', 'test02.pdf']);
  const [newNames, setNewNames] = React.useState<string[]>([]);
  const [files, setFiles] = React.useState<File[] | null>(null);

  const handleChangeOneFile = (data: { id: number; files: FileList | null }) => {
    const newNames = names.map((name, nameIndex) => {
      if (data.id === nameIndex && data.files !== null) {
        return data.files[0].name;
      } else {
        return name;
      }
    });

    // change files
    let newFiles = files;
    if (newFiles !== null) {
      for (let i = 0; i < newFiles.length; i++) {
        if (data.id === i && data.files !== null) {
          newFiles[i] = data.files[0];
        }
      }
    }

    setFiles((_) => newFiles);
    setNames((_) => newNames);
    const dataFiles = data.files;
    if (dataFiles !== null && dataFiles.length > 0) {
      setNewNames((prev) => [...prev, dataFiles[0].name]);
    }
  };

  const isEnabled = newNames?.length === names.length;

  return (
    <PopUp {...otherProps}>
      <div
        className={clsx(
          'grid grid-cols-1 place-content-start place-items-start gap-[1.5rem]',
          'rounded-[0.5rem]',
          'bg-grayscale-10',
          'px-[2rem] py-[1.5rem]'
        )}>
        <div
          className={clsx(
            'grid grid-cols-1 items-start content-start justify-start justify-items-start gap-[0.5rem]',
            'text-left',
            'pb-[1rem]',
            'border-b border-b-grayscale-30'
          )}>
          <Typography variant={'Heading'} size={'H3'} color={'text-grayscale-90'}>
            {message}
          </Typography>
          <Typography variant={'Regular'} size={'S'} color={'text-grayscale-70'}>
            {description}
          </Typography>
        </div>

        <div className={clsx('w-full')}>
          {names.length > 0 && (
            <div
              className={clsx(
                'grid grid-cols-1 place-content-start place-items-start gap-[0.5rem]',
                'w-full'
              )}>
              {names.map((name, nameIndex) => (
                <FileList
                  key={nameIndex}
                  name={name}
                  isDelete={false}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangeOneFile({ id: nameIndex, files: e.currentTarget.files });
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
              ))}
            </div>
          )}
        </div>

        <div
          className={clsx(
            'grid grid-flow-col items-center content-center justify-end justify-items-end gap-[0.5rem]',
            'w-full'
          )}>
          <Button
            data-testid={cta.primary}
            variant={'primary'}
            fullWidth={true}
            disabled={!isEnabled}
            onClick={onSubmit}>
            {cta.primary}
          </Button>
        </div>
      </div>
    </PopUp>
  );
};
