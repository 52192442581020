export const forms = {
  tabs: {
    active: 'active-tab-account',
    data: 'data-tab-account'
  },
  personal_information: {
    profile_image: 'profile_image-personal_information-account',
    first_name: 'first_name-personal_information-account',
    position_in_company: 'position_in_company-personal_information-account',
    last_name: 'last_name-personal_information-account',
    contact_number: 'contact_number-personal_information-account',
    email: 'email-personal_information-account',
    linked_in: 'linked_in-personal_information-account',

    success: 'success-personal_information-account'
  },
  company_information: {
    logo: 'logo-company_information-account',
    legal_entity_name: 'legal_entity_name-company_information-account',
    country: 'country-company_information-account',
    region: 'region-company_information-account',
    city: 'city-company_information-account',
    contact_number: 'contact_number-company_information-account',
    company_name: 'company_name-company_information-account',
    address: 'address-company_information-account',
    about: 'about-company_information-account',
    billing_email: 'billing_email-company_information-account',
    website_link: 'website_link-company_information-account',
    success: 'success-company_information-account'
  },
  password_information: {
    email: 'email-password_information-account',
    reset_password: 'reset_password-password_information-account'
  }
} as const;

export const formErrors = {
  personal_information: {
    [`${forms.personal_information.first_name}`]: {
      type: 'custom',
      message: 'required'
    },
    [`${forms.personal_information.position_in_company}`]: {
      type: 'custom',
      message: 'required'
    },
    [`${forms.personal_information.last_name}`]: {
      type: 'custom',
      message: 'required'
    },
    [`${forms.personal_information.contact_number}`]: {
      type: 'custom',
      message: 'required'
    },
    [`${forms.personal_information.email}`]: {
      type: 'custom',
      message: 'required'
    },
    [`${forms.personal_information.linked_in}`]: {
      type: 'custom',
      message: 'required'
    }
  },
  company_information: {
    [`${forms.company_information.legal_entity_name}`]: {
      type: 'custom',
      message: 'required'
    },
    [`${forms.company_information.region}`]: {
      type: 'custom',
      message: 'required'
    },
    [`${forms.company_information.city}`]: {
      type: 'custom',
      message: 'required'
    },
    [`${forms.company_information.contact_number}`]: {
      type: 'custom',
      message: 'required'
    },
    [`${forms.company_information.company_name}`]: {
      type: 'custom',
      message: 'required'
    },
    [`${forms.company_information.about}`]: {
      type: 'custom',
      message: 'required'
    },
    [`${forms.company_information.website_link}`]: {
      type: 'custom',
      message: 'required'
    }
  }
};
