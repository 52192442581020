import { InvoicesDataTableBilling } from '../InvoicesDataTable';
import { InvoicesDataTableActionsBilling } from '../InvoicesDataTableActions';
import { useInvoicesBillingQueryGetinvoices } from 'features/billing/react_query/hooks/useQueryGetInvoices.billing';

export const InvoicesBilling = () => {
  useInvoicesBillingQueryGetinvoices();

  return (
    <div className="w-full">
      <InvoicesDataTableActionsBilling />
      <InvoicesDataTableBilling />
    </div>
  );
};
