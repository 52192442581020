import * as React from 'react';

export interface IconButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  disabled?: boolean;
}

export const IconButton = ({ children, disabled, ...otherProps }: IconButtonProps) => {
  return (
    <button {...otherProps} disabled={disabled}>
      {children}
    </button>
  );
};
