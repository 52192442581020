import * as React from 'react';
import clsx from 'clsx';
import { CompanyIcon } from 'core/icons/Company';
import { TypographyColorProps } from '../Typography/Typography.types';

export interface AvatarProps {
  variant?: 'user-photo' | 'user-placeholder' | 'company-logo' | 'company-placeholder';
  size?: 'XL' | 'L' | 'M' | 'S' | 'XS';
  children?: React.ReactNode;
  src?: string;
  backgroundColor?: string;
  textColor?: TypographyColorProps;
}

export const Avatar = ({
  variant = 'user-placeholder',
  size = 'XS',
  children,
  src = '',
  backgroundColor,
  textColor = 'text-grayscale-90'
}: AvatarProps) => {
  const avatarSize =
    size === 'XL'
      ? 'w-[6rem] h-[6rem]'
      : size === 'L'
      ? 'w-[3rem] h-[3rem]'
      : size === 'M'
      ? 'w-[2.5rem] h-[2.5rem]'
      : size === 'S'
      ? 'w-[2rem] h-[2rem]'
      : 'w-[1.5rem] h-[1.5rem]';

  if (variant === 'company-placeholder') {
    return (
      <div
        className={clsx(
          'flex items-center justify-center',
          'rounded-[0.625rem]',
          'bg-cta-mint-primary',
          avatarSize
        )}>
        <CompanyIcon iconSize={'XL'} className={clsx('stroke-[#0E1010]')} />
      </div>
    );
  }

  if (variant === 'company-logo') {
    return <img className={clsx('rounded-[0.625rem]', avatarSize)} src={src} />;
  }

  if (variant === 'user-photo') {
    return <img className={clsx('rounded-[50%]', avatarSize)} src={src} />;
  }

  return (
    <div
      className={clsx(
        'flex items-center justify-center',
        'rounded-[50%]',
        backgroundColor ? '' : 'bg-cta-purple-ghost',
        textColor,
        size === 'XL'
          ? 'text-[1.875rem] font-semibold text-center leading-[1.875rem] uppercase'
          : size === 'L'
          ? 'text-[1.125rem] font-semibold text-center leading-[1.25rem] uppercase'
          : size === 'M'
          ? 'text-[0.875rem] font-semibold text-center leading-[0.875rem] uppercase'
          : size === 'S'
          ? 'text-[0.75rem] font-semibold text-center leading-[0.75rem] uppercase'
          : 'text-[0.625rem] font-semibold text-center leading-[0.625rem] uppercase',
        avatarSize
      )}
      style={{ background: backgroundColor }}>
      {children}
    </div>
  );
};
