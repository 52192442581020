import React from 'react';
import './assets/styles/App.css';
import { Route, Routes } from 'react-router-dom';
import { privateRoutes, publicRoutes } from './core/routes/app';
import CookieConsent, { OPTIONS } from "react-cookie-consent";

function App() {
  return (
    <React.Suspense>
      <CookieConsent
        location={OPTIONS.BOTTOM}
        buttonStyle={{"background" : "yellow", "padding" : "10px", "borderRadius" : "10px"}}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
      <Routes>
        {publicRoutes.map((route, index) => (
          <Route key={`public-route-${index}`} path={route.path()} element={route.element} />
        ))}
        {privateRoutes.map((route, index) => (
          <Route key={`private-route-${index}`} path={route.path()} element={route.element} />
        ))}
      </Routes>
    </React.Suspense>
  );
}

export default App;
