import * as React from 'react';
import clsx from 'clsx';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { useOnClickOutside } from 'usehooks-ts';

export interface DeletedRoleCellDataTableUsersProps {
  actions?: {
    options: { id: string; name: string }[];
    onClick: (data: { id: string; name: string }) => void;
  };
}

export default function DeletedRoleCellDataTableUsers({
  actions = {
    options: [],
    onClick: () => {}
  }
}: DeletedRoleCellDataTableUsersProps) {
  const threeDotsRef = React.useRef<HTMLDivElement | null>(null);
  const [isActionsOpen, setIsActionsOpen] = React.useState<boolean>(false);

  useOnClickOutside(threeDotsRef, () => {
    setIsActionsOpen((prev) => false);
  });

  const handleClickActionsButton = () => {
    setIsActionsOpen((prev) => !prev);
  };
  return (
    <div
      className={clsx(
        'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[1.5rem]',
        'w-full'
      )}>
      <div
        className={clsx(
          'grid grid-cols-1 items-center content-center justify-start justify-items-start gap-[1.5rem]',
          'w-full',
          'relative'
        )}>
        <div
          className={clsx(
            'w-[140px] h-[34px]',
            'grid grid-flow-col items-center content-center justify-between justify-items-start',
            'rounded-[0.25rem]',
            'pl-[0.25rem] pr-[0.75rem]'
          )}
        />
      </div>

      {/* three dots */}

      <div
        ref={threeDotsRef}
        className={clsx(
          'grid grid-cols-1 items-center content-center justify-start justify-items-start gap-[1.5rem]',
          'w-full',
          'relative'
        )}>
        <button
          className={clsx(
            'w-fit',
            'grid grid-cols-1 items-center content-center justify-between justify-items-start',
            'rounded-[0.25rem]',
            'pl-[0.25rem] pr-[0.75rem]'
          )}
          onClick={handleClickActionsButton}>
          <EllipsisVerticalIcon className={clsx('h-[1rem] w-[1rem]', 'text-grayscale-80')} />
        </button>

        {isActionsOpen && (
          <div
            className={clsx(
              'absolute top-[30px] right-[0.75rem]',
              'w-[140px]',
              'grid grid-cols-1 items-center content-center justify-start justify-items-start gap-[0.5rem]',
              'border border-grayscale-30',
              'rounded-[0.25rem]',
              'px-[0.25rem]',
              'py-[0.25rem]',
              'bg-white',
              'z-10'
            )}>
            {actions.options.map((option, index) => (
              <button
                key={index}
                className={clsx(
                  'flex items-center justify-start',
                  'w-full',
                  'hover:bg-grayscale-20 bg-grayscale-10',
                  'rounded-[0.25rem]',
                  'px-[0.5rem] py-[0.25rem]'
                )}
                onClick={() => {
                  actions.onClick(option);
                  setIsActionsOpen(false);
                }}>
                <p
                  className={clsx(
                    option.id.toLowerCase() === 'delete'
                      ? 'text-error-primary'
                      : 'text-grayscale-80',
                    'text-[13px] font-normal leading-[136%] tracking-[-0.13px]'
                  )}>
                  {option.name}
                </p>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
