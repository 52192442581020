import * as React from 'react';
import clsx from 'clsx';

export interface InfoTipIconProps extends React.SVGAttributes<HTMLOrSVGElement> {
  iconSize?: 'XL' | 'L' | 'M' | 'S' | 'XS';
}

export const InfoTipIcon = ({ iconSize, className, ...otherProps }: InfoTipIconProps) => {
  const svgSizeClassName =
    iconSize === 'XL'
      ? 'w-[2.5rem] h-[2.5rem]'
      : iconSize === 'L'
      ? 'w-[2rem] h-[2rem]'
      : iconSize === 'M'
      ? 'w-[1.5rem] h-[1.5rem]'
      : iconSize === 'S'
      ? 'w-[1.25rem] h-[1.25rem]'
      : 'w-[1rem] h-[1rem]';
  return (
    <svg
      className={clsx(svgSizeClassName, className)}
      {...otherProps}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_426_12067)">
        <path
          d="M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5Z"
          {...otherProps}
          {...otherProps}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.00684 10.6667L8.00684 8"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M8.00667 5.33337L8 5.33337"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_426_12067">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
