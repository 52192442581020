import * as React from 'react';
import clsx from 'clsx';
import { Typography } from 'core/components/Typography';

export interface StepsRegistrationProps {
  items?: { id: string; name: string }[];
  step?: number;
}

export const StepsRegistration = ({ items = [], step = 0 }: StepsRegistrationProps) => {
  return (
    <div
      className={clsx(
        'grid grid-flow-col items-center content-center justify-center justify-items-center gap-[2.5rem]',
        'w-full'
      )}>
      {/* list */}
      {items.map((item, itemIndex) => (
        <div
          key={item.id}
          className={clsx(
            'grid grid-flow-col items-center content-center justify-center justify-items-center gap-[2.5rem]',
            'w-full'
          )}>
          <div
            className={clsx(
              'grid grid-flow-col items-center content-center justify-center justify-items-center gap-[1rem]'
            )}>
            <div
              className={clsx(
                'w-[2rem] h-[2rem]',
                'flex items-center justify-center',
                itemIndex > step
                  ? 'bg-grayscale-10'
                  : itemIndex < step
                  ? 'bg-grayscale-70'
                  : 'bg-grayscale-90',
                itemIndex > step
                  ? 'border border-grayscale-70'
                  : itemIndex < step
                  ? 'border border-grayscale-70'
                  : 'border border-grayscale-90',
                'rounded-[50%]'
              )}>
              <Typography
                variant={'Medium'}
                size={'S'}
                color={
                  itemIndex > step
                    ? 'text-grayscale-70'
                    : itemIndex < step
                    ? 'text-grayscale-10'
                    : 'text-grayscale-10'
                }>
                {itemIndex + 1}
              </Typography>
            </div>

            <Typography variant={'Medium'} size={'S'} color={'text-grayscale-90'}>
              {item.name}
            </Typography>
          </div>

          {items.length > 1 && itemIndex < items.length - 1 && (
            <div className={clsx('w-[2.5rem] h-[1px]', 'bg-grayscale-40')} />
          )}
        </div>
      ))}
    </div>
  );
};
