import * as React from 'react';
import clsx from 'clsx';

export interface TextButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

export const TextButton = ({ children, leftIcon, rightIcon, ...otherProps }: TextButtonProps) => {
  return (
    <button
      className={clsx(
        'py-[0.125rem]',
        'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.5rem]',
        'bg-transparent',
        'text-[0.875rem] font-medium leading-[146%] tracking-[-0.14px]',
        'text-grayscale-90 disabled:text-grayscale-50'
      )}
      {...otherProps}>
      {leftIcon && leftIcon}
      {children}
      {rightIcon && rightIcon}
    </button>
  );
};
