import { useMutation } from '@tanstack/react-query';
import { RegistrationReactQueryKey } from '../keys';
import { useEffect } from 'react';
import { fetchGetOrganizationExist } from 'core/services/registration';
import {
  GetOrganizationExistRequestInterface,
  GetOrganizationExistSuccessResponseInterface
} from 'core/models/api/registration';
import { gql } from 'graphql-request';
import { useFormContext } from 'react-hook-form';
import { RegistrationForm } from '../../react_hook_form/constants/type';
import { forms } from '../../react_hook_form/constants/data';
import RegistrationData from '../../data/registration.json';

export const useRegistrationGetOrganizationExist = () => {
  const { setError, clearErrors } = useFormContext<RegistrationForm>();
  const mutation = useMutation<
    GetOrganizationExistSuccessResponseInterface | undefined,
    any,
    { organizationName: string }
  >(
    RegistrationReactQueryKey.QueryGetOrganizationExist(),

    (data: { organizationName: string }) => {
      const token = localStorage.getItem('token') ?? '';
      const payload: GetOrganizationExistRequestInterface = {
        headers: {
          token: token
        },
        data: {
          query: gql`
                query {
                    organizationExists(name: "${data.organizationName}")
                }
              `
        }
      };
      return fetchGetOrganizationExist(payload)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    {
      retry: 0
    }
  );

  useEffect(() => {
    if (mutation.data?.data) {
      if (mutation.data.data.organizationExists) {
        setError(forms.create_workspace.company_name, {
          message: RegistrationData.workspace.create_workspace.forms.errors.organization_is_exist
        });
      } else {
        clearErrors(forms.create_workspace.company_name);
      }
    }
  }, [mutation.data]);

  return mutation;
};
