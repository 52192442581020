import { useMutation } from '@tanstack/react-query';
import { request, gql } from 'graphql-request';
import { UsersReactQueryKey } from '../keys';
import {
  PostInviteUsersSuccessResponseInterface,
  GetUsersRequestInterface,
  PostInviteUsersRequestInterface
} from 'core/models/api/registration';
import { useFormContext } from 'react-hook-form';
import { forms } from 'features/users/react_hook_form/constants/data';
import { UsersForm } from 'features/users/react_hook_form/constants';
import { useMemo } from 'react';
import { queryClient } from 'index';
import { fetchPostInviteUsers } from 'core/services/registration';
import { useUsersQueryGetUsers } from './useQueryGetUsers.users';

export const useUsersMutationInviteUsers = () => {
  const { refetch: refetchUsers } = useUsersQueryGetUsers();
  const { watch, setValue } = useFormContext<UsersForm>();
  const token = localStorage.getItem('token') ?? '';
  const invitedUser = watch(forms.user_invitation.email) as string;

  const LIMIT = 10;
  const usersPayload: GetUsersRequestInterface = useMemo(() => {
    const OFFSET = watch(forms.data_table.offset) as number;

    return {
      headers: {
        token: token
      },
      data: {
        query: gql`
          query {
            users(${
              !(watch(forms.data_table.search) as string).length
                ? 'search:null'
                : `search:"${watch(forms.data_table.search)}"`
            },
            limit:${LIMIT},
            offset:${OFFSET},
            sort:"${(watch(forms.data_table.sort_by) as { id: string; name: string }).id}"
          ) {
              email
              firstName
              lastName
              roles
              status
              createdAt
            }
          }
        `
      }
    };
  }, [
    watch(forms.data_table.sort_by) as { id: string; name: string },
    watch(forms.data_table.search) as string,
    watch(forms.data_table.offset) as number,
    token,
    LIMIT,
    watch(forms.data_table.sort_by) as { id: string; name: string }
  ]);

  const mutation = useMutation<PostInviteUsersSuccessResponseInterface>(
    UsersReactQueryKey.MutationInviteUsers(),
    () => {
      const usersVariable = [
        {
          email: invitedUser,
          roles: ['viewer']
        }
      ];

      const query = gql`
        mutation inviteUsers($organizationId: String = null, $users: [InviteUserInput!]!) {
          inviteUsers(input: { organizationId: $organizationId, users: $users }) {
            users {
              id
            }
          }
        }
      `;

      const variables = {
        organizationId: localStorage.getItem('organizationId') ?? '',
        users: usersVariable
      };
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const payload: PostInviteUsersRequestInterface = {
        url: process.env.REACT_APP_GRAPHQL_API_URL ?? '',
        query: query,
        variables: variables,
        headers: headers
      };
      return fetchPostInviteUsers(payload);
    },
    {
      retry: 0,
      onSuccess(data) {
        setValue(forms.user_invitation.is_open, false);
        setValue(forms.user_invitation.email, '');
        refetchUsers();
      }
      //onError(error, variables, context) {
      //   setTimeout(() => {
      //     setValue(forms.add_team_members.alert, true);
      //     setValue(
      //       forms.add_team_members.alert_message,
      //       RegistrationData.workspace.alert.message.replace(
      //         '{{error}}',
      //         error.response?.data.error[0].message ?? ''
      //       )
      //     );
      //   }, 5000);
      // },
    }
  );

  return mutation;
};
