import { forms } from './data';
import { UsersForm } from './type';
import UsersData from '../../data/users.json';

export const defaultValues: UsersForm = {
  // role
  [forms.role.name]: null,

  // data table
  [forms.data_table.sort_by]: {
    id: 'email',
    name: 'Alphabetical'
  },
  [forms.data_table.search]: '' as string,
  [forms.data_table.data]: [],
  // [forms.data_table.data]: UsersData.data_table.data,
  [forms.data_table.offset]: 0,
  [forms.data_table.total_data]: 0,

  // user invitation
  [forms.user_invitation.is_open]: false as boolean,
  [forms.user_invitation.email]: '' as string
};
