import { forms } from './data';
import { RegistrationForm } from './type';
export const defaultValues: RegistrationForm = {
  // general
  [forms.general.id]: '',
  [forms.general.organization_id]: '',

  // state
  [forms.state.is_loading]: true as boolean,

  [forms.state.step]: 'create_workspace', // 'create-workspace | join-workspace'
  [forms.state.create_workspace_step]: 'create_workspace' as string,

  // create_workspace
  [forms.create_workspace.company_name]: '',
  [forms.create_workspace.country]: null,
  [forms.create_workspace.state_region]: '',
  [forms.create_workspace.city]: '',
  [forms.create_workspace.zip_code]: '',
  [forms.create_workspace.address_line_1]: '',
  [forms.create_workspace.address_line_2]: '',
  [forms.create_workspace.billing_email]: '',
  [forms.create_workspace.contact_number]: '',

  // join_workspace
  [forms.join_workspace.id]: '',

  // create_account
  [forms.create_account.first_name]: '',
  [forms.create_account.last_name]: '',
  [forms.create_account.email]: '',

  // add_team_members
  [forms.add_team_members.team_members]: [
    { email: '', roles: { id: 'viewer', name: 'Viewer' } }
  ] as {
    email: string;
    roles: { id: string; name: string } | null;
  }[],
  [forms.add_team_members.alert]: false,
  [forms.add_team_members.alert_message]: '',

  // add_more_information
  [forms.add_more_information.industry]: '',
  [forms.add_more_information.team_size]: '',
  [forms.add_more_information.about]: '',

  // personal information invited
  [forms.personal_information_invited.first_name]: '',
  [forms.personal_information_invited.last_name]: '',
  [forms.personal_information_invited.position_in_company]: '',
  [forms.personal_information_invited.contact_number]: '',
  [forms.personal_information_invited.linkedin]: '',

  // user
  [forms.user.is_email_verified]: null
};
