import clsx from 'clsx';
import { Typography } from 'core/components/Typography';
import * as React from 'react';
import RegistrationData from '../../data/registration.json';
import { Field } from 'core/components/Field';
import { useFormContext } from 'react-hook-form';
import { Button } from 'core/components/Button';
import { RoleSelectFieldRegistration } from 'features/registration/customer/components/role_select';
import { TextButton } from 'core/components/TextButton';
import { PlusIcon } from '@heroicons/react/20/solid';
import { Alert } from 'core/components/Alert';
import { RegistrationForm } from 'features/registration/customer/react_hook_form/constants/type';
import { forms } from 'features/registration/customer/react_hook_form/constants/data';
import { useRegistrationMutationInviteUsers } from '../../react_query/hooks/useMutationInviteUsers.registration';
import { useAuth0 } from '@auth0/auth0-react';

export const AddTeamMembersRegistration = () => {
  const { user } = useAuth0();
  const { mutate: inviteUsers } = useRegistrationMutationInviteUsers();
  const {
    watch,
    formState: { errors },
    setValue
  } = useFormContext<RegistrationForm>();

  const invitedUsers = watch(forms.add_team_members.team_members) as {
    email: string;
    roles: { id: string; name: string } | null;
  }[];

  const handleSelectRole = (value: { index: number; data: { id: string; name: string } }) => {
    const payload = invitedUsers.map((invitedUser, index) => {
      return {
        ...invitedUser,
        roles: index === value.index ? value.data : invitedUser.roles
      };
    });
    setValue(forms.add_team_members.team_members, payload);
  };

  const handleChangeTeamMemberName = (value: { index: number; data: string }) => {
    const payload = invitedUsers.map((invitedUser, index) => {
      return {
        ...invitedUser,
        email: index === value.index ? value.data : invitedUser.email
      };
    });
    setValue(forms.add_team_members.team_members, payload);
  };

  const handleClickAddAnother = () => {
    setValue(forms.add_team_members.team_members, [
      ...invitedUsers,
      { email: '', roles: { id: 'admin', name: 'Admin' } }
    ]);
  };

  const handleClickAdd = () => {
    const invitedUsers = watch(forms.add_team_members.team_members) as {
      email: string;
      roles: { id: string; name: string } | null;
    }[];

    if (invitedUsers.length === 1 && !invitedUsers[0].email.length) {
      if (user?.email_verified) {
        window.location.href = `${process.env.REACT_APP_REDIRECT_CUSTOMER_REGISTRATION_LINK}`;
      } else {
        setValue(forms.user.is_email_verified, false);
      }
    } else {
      inviteUsers();
    }
  };

  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-items-center place-content-center gap-[2.5rem]',
        'w-[350px]'
      )}>
      <Alert
        isOpen={watch(forms.add_team_members.alert) as boolean}
        message={watch(forms.add_team_members.alert_message) as string}
      />
      <div
        className={clsx(
          'grid grid-cols-1 place-items-center place-content-center gap-[0.25rem]',
          'w-full'
        )}>
        <Typography variant={'Heading'} size={'H1'} color={'text-grayscale-90'}>
          {RegistrationData.workspace.add_team_members.title}
        </Typography>
        <Typography variant={'Regular'} size={'M'} color={'text-grayscale-70'}>
          {RegistrationData.workspace.add_team_members.message}
        </Typography>
      </div>

      {/* form */}
      <div
        className={clsx(
          'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[1.25rem]',
          'w-full'
        )}>
        {invitedUsers.map((invitedUser, index) => (
          <Field
            key={RegistrationData.workspace.add_team_members.forms.team_members.name}
            mainLabel={RegistrationData.workspace.add_team_members.forms.team_members.mainLabel}
            name={forms.add_team_members.team_members}
            secondaryLabel={
              RegistrationData.workspace.add_team_members.forms.team_members.secondaryLabel
            }
            placeholder={RegistrationData.workspace.add_team_members.forms.team_members.placeholder}
            variant={!!errors[forms.add_team_members.team_members] ? 'error' : 'default'}
            value={invitedUser.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeTeamMemberName({ index: index, data: e.currentTarget.value })
            }
            helperText={errors[forms.add_team_members.team_members]?.message as string}
            endIcon={
              <div className={clsx('w-[128px]')}>
                <RoleSelectFieldRegistration
                  selected={invitedUser.roles}
                  options={RegistrationData.workspace.add_team_members.forms.team_members.options}
                  onSelect={(data: { id: string; name: string }) =>
                    handleSelectRole({ index: index, data: data })
                  }
                />
              </div>
            }
          />
        ))}

        <div className={clsx('w-full')}>
          <TextButton onClick={handleClickAddAnother}>
            <div
              className={clsx(
                'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.5rem]',
                'w-full'
              )}>
              <PlusIcon className={clsx('w-[1rem] h-[1rem]', 'text-[grayscale-90]')} />
              <Typography variant={'Button'} size={'S'} color={'text-grayscale-90'}>
                {RegistrationData.workspace.add_team_members.forms.actions.primary}
              </Typography>
            </div>
          </TextButton>
        </div>

        <div
          className={clsx(
            'grid grid-cols-1 place-content-start place-items-start gap-[1rem]',
            'w-full'
          )}>
          <Button variant={'primary'} fullWidth onClick={handleClickAdd}>
            {RegistrationData.workspace.add_team_members.actions.primary}
          </Button>
        </div>
      </div>
      {/* end form */}
    </div>
  );
};
