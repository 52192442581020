import * as React from 'react';
import clsx from 'clsx';
import { Typography } from '../Typography';

export interface TooltipProps {
  children?: React.ReactNode;
  text?: string;
  position?: 'left' | 'right' | 'top' | 'bottom';
  variant?: 'white' | 'black';
}

export const Tooltip = ({
  children,
  text = '',
  position = 'right',
  variant = 'white'
}: TooltipProps) => {
  const childrenRef = React.useRef<HTMLDivElement | null>(null);
  const [childrenWidth, setChildrenWidth] = React.useState<number>(0);
  const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(false);
  const handleMouseOver = () => {
    setIsTooltipOpen((prev) => true);
  };
  const handleMouseLeave = () => {
    setIsTooltipOpen((prev) => false);
  };

  React.useEffect(() => {
    if (childrenRef.current !== null) {
      setChildrenWidth((prev) => childrenRef.current?.clientWidth || 0);
    }
  }, [childrenRef.current]);
  // NOTE: children haven't set into calc function
  return (
    <div
      data-testid={'tooltip-wrapper'}
      className={clsx('relative')}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}>
      <div ref={childrenRef}>{children}</div>

      <div
        className={clsx(
          'absolute',
          'z-10',
          isTooltipOpen ? 'block' : 'hidden',
          position === 'bottom'
            ? 'top-[1.5rem] translate-x-[calc(-50%+0.5rem)]'
            : position === 'left'
            ? 'top-[-0.25rem] left-[-8.5rem]'
            : position === 'right'
            ? 'top-[-0.25rem] right-[-1.5rem]'
            : 'top-[-1.5rem]',
          'whitespace-nowrap',
          variant === 'white' ? 'border border-grayscale-40' : 'border border-grayscale-90',
          variant === 'white' ? 'bg-grayscale-10' : 'bg-grayscale-90',
          'rounded-[0.25rem]',
          'px-[0.75rem] py-[0.375rem]'
        )}>
        <Typography
          variant={'Regular'}
          size={'XXS'}
          color={variant === 'white' ? 'text-grayscale-70' : 'text-grayscale-10'}>
          {text}
        </Typography>

        <div className={clsx('absolute top-[-6px] left-[50%] translate-x-[-50%]', 'z-[10]')}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="6"
            viewBox="0 0 12 6"
            fill="none">
            <path
              d="M6.67267 1.61152C6.29125 1.26477 5.70875 1.26477 5.32733 1.61152L0.5 6L11.5 6L6.67267 1.61152Z"
              fill="white"
            />
            <path d="M0.5 5.5L6 1L11.5 5.5" stroke="#E5E7E7" strokeLinecap="round" />
          </svg>
        </div>
      </div>
    </div>
  );
};
