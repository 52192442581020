import { DashboardContainer } from 'core/modules/dashboard/container';
import * as React from 'react';
import clsx from 'clsx';
import { PersonalInformationReviewerRegistration } from '../fragments/personal_information';
import { useReviewerRegistrationGetAccessTokenSilently } from '../react_query/hooks/useGetAccessTokenSilently.registration.reviewer';
import { ReviewerRegistrationForm, forms } from '../react_hook_form/constants';
import { useFormContext } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorReviewerRegistration } from '../fragments/error';
import { UnverifiedEmailReviewerRegistration } from '../fragments/unverified_email';

export const ReviewerRegistrationContainer = () => {
  const { user } = useAuth0();
  const { watch } = useFormContext<ReviewerRegistrationForm>();
  useReviewerRegistrationGetAccessTokenSilently();

  const isEmailNotVerified = (watch(forms.user.is_email_verified) as null | boolean) === false;

  if (isEmailNotVerified) {
    return (
      <DashboardContainer sidebar={false}>
        <div
          className={clsx(
            'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[5rem]',
            'w-full'
          )}>
          <div
            className={clsx(
              'grid grid-flow-col place-content-center place-items-center',
              'w-full',
              'pt-[152px]'
            )}>
            <UnverifiedEmailReviewerRegistration />
          </div>
        </div>
      </DashboardContainer>
    );
  }

  if (watch(forms.state.is_loading)) {
    return <div />;
  }

  if (!user?.email?.includes('@aaico.com')) {
    return (
      <DashboardContainer sidebar={false}>
        <div
          className={clsx(
            'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[5rem]',
            'w-full'
          )}>
          <div
            className={clsx(
              'grid grid-flow-col place-content-center place-items-center',
              'w-full',
              'pt-[152px]'
            )}>
            <ErrorReviewerRegistration />
          </div>
        </div>
      </DashboardContainer>
    );
  }

  return (
    <DashboardContainer sidebar={false}>
      <div
        className={clsx(
          'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[5rem]',
          'w-full'
        )}>
        <div
          className={clsx(
            'grid grid-flow-col place-content-center place-items-center',
            'w-full',
            'pt-[152px]'
          )}>
          <PersonalInformationReviewerRegistration />
        </div>
      </div>
    </DashboardContainer>
  );
};
