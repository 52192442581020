import * as React from 'react';
import clsx from 'clsx';
import { Typography } from '../Typography';
import { Link } from 'react-router-dom';

export interface MenuItemProps {
  name?: string;
  href?: string;
  icon?: string;
  children?: React.ReactNode;
  active?: boolean;
  indicator?: React.ReactNode;
}

export const MenuItem = ({
  name = '',
  href = '',
  icon = '',
  active = false,
  children,
  indicator
}: MenuItemProps) => {
  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-content-start place-items-start gap-[1rem]',
        'w-full'
      )}>
      <Link
        className={clsx(
          'grid grid-cols-[auto_1fr_auto] items-center content-center justify-start justify-items-start gap-[0.75rem]',
          'w-full',
          'p-[0.5rem]',
          active ? 'bg-cta-yellow-primary' : 'bg-transparent hover:bg-grayscale-20',
          'rounded-[0.25rem]'
        )}
        to={href}>
        <img src={icon} className={clsx('w-[1.5rem] h-[1.5rem]')} alt={name} />
        <Typography variant={'Medium'} size={'S'} color={'text-grayscale-90'}>
          {name}
        </Typography>
        {indicator}
      </Link>
      {children}
    </div>
  );
};
