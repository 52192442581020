export const dashboardData = {
  menu: {
    admin: {
      items: [
        {
          name: 'Billing',
          icon: '/icons/billing.icon.svg',
          href: '/invoices',
          active: false
        },
        {
          name: 'Account',
          icon: '/icons/account.icon.svg',
          href: '/account',
          active: false
        },
        {
          name: 'Users',
          icon: '/icons/team.icon.svg',
          href: '/users',
          active: false
        }
      ]
    },
    site_admin: {
      items: [
        {
          name: 'Billing',
          icon: '/icons/billing.icon.svg',
          href: '/invoices',
          active: false
        },
        {
          name: 'Account',
          icon: '/icons/account.icon.svg',
          href: '/account',
          active: false
        },
        {
          name: 'Users',
          icon: '/icons/team.icon.svg',
          href: '/users',
          active: false
        }
      ]
    },
    user: {
      items: [
        {
          name: 'Account',
          icon: '/icons/account.icon.svg',
          href: '/account',
          active: false
        }
      ]
    }
  },
  navbar_menu: {
    unregistered_user: {
      items: [
        {
          name: 'Logout',
          icon: '/icons/logout.icon.svg',
          href: '/',
          active: false
        }
      ]
    },
    admin: {
      items: [
        {
          name: 'Services',
          icon: '',
          href: process.env.REACT_APP_REDIRECT_LOGIN_LINK as string,
          active: false
        },
        {
          name: 'Logout',
          icon: '/icons/logout.icon.svg',
          href: '/',
          active: false
        }
      ]
    },
    site_admin: {
      items: [
        {
          name: 'Billing',
          icon: '/icons/billing.icon.svg',
          href: '/invoices',
          active: false
        },
        {
          name: 'Services',
          icon: '',
          href: process.env.REACT_APP_REDIRECT_LOGIN_LINK as string,
          active: false
        },
        {
          name: 'Users',
          icon: '/icons/team.icon.svg',
          href: '/users',
          active: false
        },
        {
          name: 'Logout',
          icon: '/icons/logout.icon.svg',
          href: '/',
          active: false
        }
      ]
    },
    user: {
      items: [
        {
          name: 'Services',
          icon: '',
          href: process.env.REACT_APP_REDIRECT_LOGIN_LINK as string,
          active: false
        },
        {
          name: 'Logout',
          icon: '/icons/logout.icon.svg',
          href: '/',
          active: false
        }
      ]
    }
  }
};
