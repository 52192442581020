import * as React from 'react';
import clsx from 'clsx';
import { Typography } from '../Typography';
import { IconButton } from '../IconButton';
import { CancelIcon } from 'core/icons/Cancel';
import { RotateIcon } from 'core/icons/Rotate';

export interface FileListProps extends React.HTMLAttributes<HTMLInputElement> {
  name?: string;
  isRetry?: boolean;
  isDelete?: boolean;
  onRetry?: () => void;
  onDelete?: () => void;
}

export const FileList = ({
  name,
  isDelete = true,
  isRetry = true,
  onRetry,
  onDelete,
  ...otherProps
}: FileListProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleClickUpload = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    inputRef?.current?.click();
  };
  return (
    <div
      className={clsx(
        'flex items-center justify-between',
        'w-full',
        'rounded-[0.25rem]',
        'bg-grayscale-20',
        'px-[1rem] py-[0.5rem]'
      )}>
      <Typography variant={'Medium'} size={'S'} color={'text-grayscale-90'}>
        {name}
      </Typography>

      <div
        className={clsx(
          'grid grid-flow-col items-center content-center justify-end justify-items-end gap-[0.5rem]'
        )}>
        {isRetry && (
          <IconButton
            data-testid={`${name ?? 'default'}-file-list-uploader`}
            onClick={handleClickUpload}>
            <RotateIcon iconSize={'XS'} className={'stroke-grayscale-60'} />
          </IconButton>
        )}
        {isDelete && (
          <IconButton data-testid={`${name ?? 'default'}-file-removal-uploader`} onClick={onDelete}>
            <CancelIcon iconSize={'XS'} className={clsx('stroke-grayscale-60')} />
          </IconButton>
        )}
      </div>
      <input
        {...otherProps}
        ref={inputRef}
        type="file"
        id={'inputFileList'}
        multiple={false}
        data-testid={`${name ?? 'default'}-file-list-upload-input`}
        className={clsx('sr-only')}
      />
    </div>
  );
};
