import { useForm, FormProvider } from 'react-hook-form';
import clsx from 'clsx';
import { defaultValues as defaultValuesReviewerRegistration } from 'features/registration/reviewer/react_hook_form/constants';
import { defaultValues as defaultValuesReviewerHome } from 'features/home/reviewer/react_hook_form/constants';
import { ReviewerRegistrationContainer } from 'features/registration/reviewer/container';
import { ReviewerHomeContainer } from 'features/home/reviewer/container/Reviewer.home';
import { useAuth0 } from '@auth0/auth0-react';

export default function ReviewerRegistrationPage() {
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();

  const methods = useForm({
    defaultValues: { ...defaultValuesReviewerRegistration, ...defaultValuesReviewerHome }
  });

  if (isLoading) {
    const loadingImg = 'https://cdn.auth0.com/blog/hello-auth0/loader.svg';
    return (
      <div className={clsx('flex items-center justify-center', 'w-full h-[100vh]')}>
        <img className={clsx('w-[120px] h-[120px]')} src={loadingImg} alt="Loading..." />
      </div>
    );
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isAuthenticated) {
    localStorage.setItem('auth0_user', JSON.stringify(user));

    return (
      <FormProvider {...methods}>
        <ReviewerRegistrationContainer />
      </FormProvider>
    );
  } else {
    localStorage.clear();

    return (
      <FormProvider {...methods}>
        <ReviewerHomeContainer />
      </FormProvider>
    );
  }
}
