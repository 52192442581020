import clsx from 'clsx';
import { Typography } from 'core/components/Typography';
import * as React from 'react';
import RegistrationData from '../../data/registration.json';
import { Field } from 'core/components/Field';
import { useFormContext } from 'react-hook-form';
import { Button } from 'core/components/Button';
import { RegistrationForm } from 'features/registration/customer/react_hook_form/constants/type';
import { forms } from 'features/registration/customer/react_hook_form/constants/data';

export const JoinWorkspaceRegistration = () => {
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const {
    watch,
    formState: { errors },
    setValue,
    setError,
    clearErrors
  } = useFormContext<RegistrationForm>();

  if (isSuccess) {
    return (
      <div
        className={clsx(
          'grid grid-cols-1 place-items-center place-content-center gap-[2.5rem]',
          'max-w-[422px] w-full'
        )}>
        <div
          className={clsx(
            'grid grid-cols-1 place-items-center place-content-center gap-[0.25rem]',
            'w-full'
          )}>
          <Typography
            variant={'Heading'}
            size={'H1'}
            color={'text-grayscale-90'}
            className={'text-center'}>
            {RegistrationData.workspace.send_request_join_workspace.message}
          </Typography>
          <Typography
            variant={'Regular'}
            size={'M'}
            color={'text-grayscale-70'}
            className={'text-center'}>
            {RegistrationData.workspace.send_request_join_workspace.description}
          </Typography>
        </div>
      </div>
    );
  }

  const handleChangeWorkspaceId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(forms.join_workspace.id, e.currentTarget.value);
    if (!e.currentTarget.value.length) {
      setError(forms.join_workspace.id, {
        message: RegistrationData.workspace.join_workspace.forms.errors.required
      });
    } else {
      clearErrors(forms.join_workspace.id);
    }
  };

  const handleClickCreateWorkspace = () => {
    setValue(forms.state.step, 'create_workspace');
  };

  const handleClickSendRequest = () => {
    setIsSuccess((prev) => true);
  };

  const isButtonDisabled = !(watch(forms.join_workspace.id) as string).length;
  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-items-center place-content-center gap-[2.5rem]',
        'w-[434px]'
      )}>
      <div
        className={clsx(
          'grid grid-cols-1 place-items-center place-content-center gap-[0.25rem]',
          'w-full'
        )}>
        <Typography
          variant={'Heading'}
          size={'H1'}
          color={'text-grayscale-90'}
          className={'text-center'}>
          {RegistrationData.workspace.join_workspace.title}
        </Typography>
      </div>

      {/* form */}
      <div
        className={clsx(
          'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[1.25rem]',
          'w-full max-w-[350px]'
        )}>
        <Button variant={'primary'} fullWidth onClick={handleClickCreateWorkspace}>
          {RegistrationData.workspace.join_workspace.actions.primary}
        </Button>

        <div
          className={clsx(
            'grid grid-cols-[1fr_auto_1fr] items-center content-center justify-start justify-items-start gap-[0.25rem]',
            'w-full'
          )}>
          <div className={clsx('w-full', 'h-[1px]', 'bg-grayscale-40')} />
          <Typography variant={'Regular'} size={'M'} color={'text-grayscale-60'}>
            {RegistrationData.workspace.join_workspace.divider.text}
          </Typography>
          <div className={clsx('w-full', 'h-[1px]', 'bg-grayscale-40')} />
        </div>

        <Field
          key={RegistrationData.workspace.join_workspace.forms.workspace_id.name}
          mainLabel={RegistrationData.workspace.join_workspace.forms.workspace_id.mainLabel}
          name={forms.join_workspace.id}
          secondaryLabel={
            RegistrationData.workspace.join_workspace.forms.workspace_id.secondaryLabel
          }
          placeholder={RegistrationData.workspace.join_workspace.forms.workspace_id.placeholder}
          variant={!!errors[forms.join_workspace.id] ? 'error' : 'default'}
          value={watch(forms.join_workspace.id) as string}
          onChange={handleChangeWorkspaceId}
          helperText={errors[forms.join_workspace.id]?.message as string}
        />

        <Button
          disabled={isButtonDisabled}
          variant={'ghost'}
          fullWidth
          onClick={handleClickSendRequest}>
          {RegistrationData.workspace.join_workspace.actions.secondary}
        </Button>
      </div>
      {/* end form */}
    </div>
  );
};
