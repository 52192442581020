import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { RegistrationReactQueryKey } from '../keys';
import { useFormContext } from 'react-hook-form';
import { fetchPostCreateOrganization } from 'core/services/registration';
import {
  PostCreateOrganizationRequestInterface,
  PostCreateOrganizationErrorResponseInterface,
  PostCreateOrganizationSuccessResponseInterface
} from 'core/models/api/registration';
import { useRegistrationMutationCreateUser } from './useMutationCreateUser.registration';
import { RegistrationForm } from 'features/registration/customer/react_hook_form/constants/type';
import { forms } from 'features/registration/customer/react_hook_form/constants/data';
import RegistrationData from '../../data/registration.json';

export const useRegistrationMutationCreateOrganization = () => {
  const { watch, setValue } = useFormContext<RegistrationForm>();
  const { mutate: createUser } = useRegistrationMutationCreateUser();
  const token = localStorage.getItem('token') ?? '';
  const mutation = useMutation<
    PostCreateOrganizationSuccessResponseInterface,
    AxiosError<PostCreateOrganizationErrorResponseInterface>
  >(
    RegistrationReactQueryKey.MutationCreateOrganization(),
    () => {
      const payload: PostCreateOrganizationRequestInterface = {
        headers: {
          token: token
        },
        data: {
          query: gql`
            mutation {
              createOrganization(input:{
                name:"${watch(forms.create_workspace.company_name) || ''}",
                legalEntityName:"${watch(forms.create_workspace.company_name) || ''}",
                city:"${watch(forms.create_workspace.city) || ''}",
                region:"${watch(forms.create_workspace.state_region) || ''}",
                postalCode:"${watch(forms.create_workspace.zip_code) || ''}",
                country:"${
                  (
                    watch(forms.create_workspace.country) as {
                      id: string;
                      name: string;
                    }
                  )?.name || ''
                }",
                contactNumber:"${watch(forms.create_workspace.contact_number) || ''}",
                websiteUrl:"",
                logoUrl:"",
                industry:"${
                  (
                    watch(forms.add_more_information.industry) as {
                      id: string;
                      name: string;
                    }
                  )?.name || ''
                }",
                teamSize:"${
                  (
                    watch(forms.add_more_information.team_size) as {
                      id: string;
                      name: string;
                    }
                  )?.name || ''
                }",
                about:"${watch(forms.add_more_information.about) ?? ''}",
                addressLine1:"${watch(forms.create_workspace.address_line_1) ?? ''}",
                ${
                  !(watch(forms.create_workspace.address_line_2) as string).length
                    ? ``
                    : `addressLine2:"${watch(forms.create_workspace.address_line_2)}",`
                }
                billingEmail:"${watch(forms.create_workspace.billing_email) ?? ''}"
              }) {
                id,
                name,
              }
            }
          `
        }
      };

      return fetchPostCreateOrganization(payload);
    },
    {
      retry: 0,
      onError(error, variables, context) {
        setTimeout(() => {
          setValue(forms.add_team_members.alert, true);
          setValue(
            forms.add_team_members.alert_message,
            RegistrationData.workspace.alert.message.replace(
              '{{error}}',
              error.response?.data.error[0].message ?? ''
            )
          );
        }, 5000);
      },
      onSuccess(data) {
        if (data !== null) {
          localStorage.setItem('organizationId', data.data.createOrganization.id);
          createUser({
            organizationId: data.data.createOrganization.id
          });
        }
      }
    }
  );

  return mutation;
};
