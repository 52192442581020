import { DashboardContainer } from 'core/modules/dashboard/container';
import * as React from 'react';
import clsx from 'clsx';
import { StepsRegistration } from '../components/steps';
import RegistrationData from 'features/registration/customer/data/registration.json';
import { CreateWorkspaceRegistration } from '../fragments/create_workspace';
import { AddMoreInformationRegistration } from '../fragments/add_more_information';
import { AddTeamMembersRegistration } from '../fragments/add_team_members';
import { JoinWorkspaceRegistration } from '../fragments/join_workspace';
import { useFormContext } from 'react-hook-form';
import { RegistrationForm } from '../react_hook_form/constants/type';
import { forms } from '../react_hook_form/constants/data';
import { useRegistrationGetAccessTokenSilently } from '../react_query/hooks/useGetAccessTokenSilently.registration';
import { UnverifiedEmailCustomerRegistration } from '../fragments/unverified_email';
import { AddPersonalInformationInvitedRegistration } from '../fragments/add_personal_information_invited';

export const RegistrationContainer = () => {
  const { watch } = useFormContext<RegistrationForm>();
  useRegistrationGetAccessTokenSilently();

  const isEmailNotVerified = (watch(forms.user.is_email_verified) as null | boolean) === false;

  if (isEmailNotVerified) {
    return (
      <DashboardContainer sidebar={false}>
        <div
          className={clsx(
            'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[5rem]',
            'w-full'
          )}>
          <div
            className={clsx(
              'grid grid-flow-col place-content-center place-items-center',
              'w-full',
              'pt-[152px]'
            )}>
            <UnverifiedEmailCustomerRegistration />
          </div>
        </div>
      </DashboardContainer>
    );
  }

  if (watch(forms.state.is_loading)) {
    return <div />;
  }

  // TODO: need conditional change when user is invited
  if (
    (watch(forms.state.step) as 'create_workspace' | 'join_workspace' | 'invited') === 'invited'
  ) {
    return (
      <DashboardContainer sidebar={false}>
        <div
          className={clsx(
            'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[5rem]',
            'w-full'
          )}>
          <AddPersonalInformationInvitedRegistration />
        </div>
      </DashboardContainer>
    );
  }

  if (
    (watch(forms.state.step) as 'create_workspace' | 'join_workspace' | 'invited') ===
    'create_workspace'
  ) {
    const stepNumber =
      watch(forms.state.create_workspace_step) === 'add_more_information'
        ? 1
        : watch(forms.state.create_workspace_step) === 'add_team_members'
        ? 2
        : 0;
    return (
      <DashboardContainer sidebar={false}>
        <div
          className={clsx(
            'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[5rem]',
            'w-full'
          )}>
          <div
            className={clsx(
              'grid grid-flow-col place-content-center place-items-center',
              'w-full',
              'pt-[2.5rem]'
            )}>
            <StepsRegistration items={RegistrationData.workspace.steps} step={stepNumber} />
          </div>

          {(watch(forms.state.create_workspace_step) as string) === 'create_workspace' && (
            <CreateWorkspaceRegistration />
          )}
          {(watch(forms.state.create_workspace_step) as string) === 'add_more_information' && (
            <AddMoreInformationRegistration />
          )}
          {(watch(forms.state.create_workspace_step) as string) === 'add_team_members' && (
            <AddTeamMembersRegistration />
          )}
        </div>
      </DashboardContainer>
    );
  }

  return (
    <DashboardContainer sidebar={false}>
      <div
        className={clsx(
          'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[5rem]',
          'w-full'
        )}>
        <div
          className={clsx(
            'grid grid-flow-col place-content-center place-items-center',
            'w-full',
            'pt-[152px]'
          )}>
          <JoinWorkspaceRegistration />
        </div>
      </div>
    </DashboardContainer>
  );
};
