import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { RegistrationReactQueryKey } from '../keys';
import { IdToken, useAuth0 } from '@auth0/auth0-react';
import {
  GetUserRequestInterface,
  GetReviewerSuccessResponseInterface
} from 'core/models/api/registration';
import { useFormContext } from 'react-hook-form';
import { ReviewerRegistrationForm } from 'features/registration/reviewer/react_hook_form/constants/type';
import { forms } from 'features/registration/reviewer/react_hook_form/constants/data';
import { fetchGetReviewer } from 'core/services/registration/get_reviewer.get';
import { useReviewerRegistrationMutationGetUserCustomer } from './useQueryGetCurrentUser.registration.reviewer';

export const useReviewerRegistrationMutationGetUser = () => {
  const { setValue } = useFormContext<ReviewerRegistrationForm>();
  const token = localStorage.getItem('token') ?? '';
  const { logout } = useAuth0();
  const { mutate: getUser } = useReviewerRegistrationMutationGetUserCustomer();

  const mutation = useMutation<GetReviewerSuccessResponseInterface, AxiosError, IdToken>(
    RegistrationReactQueryKey.QueryGetReviewer(),
    () => {
      const payload: GetUserRequestInterface = {
        headers: {
          token: token
        },
        data: {
          query: gql`
            query {
              currentUser {
                id
                email
                role
              }
            }
          `
        }
      };

      return fetchGetReviewer(payload);
    },
    {
      retry: 0,
      // enabled: !isLoadingAuth0 && !!auth0TokenData,
      onSuccess(data) {
        if (data.data.currentUser !== null) {
          localStorage.setItem('userId', data.data.currentUser.id);
          if (data.data.currentUser.role === 'REVIEWER') {
            window.location.href = `${process.env.REACT_APP_REDIRECT_REVIEWER_LOGIN_LINK}` || '';
          } else if (data.data.currentUser.role === 'PROJECT_MANAGER') {
            window.location.href = `${process.env.REACT_APP_REDIRECT_REVIEWER_LOGIN_LINK}` || '';
          } else if (data.data.currentUser.role === 'USER') {
            alert("Sorry, you're not a reviewer. Please sign in as a customer.");
            logout({
              logoutParams: {
                returnTo: window.location.origin
              }
            });
            window.location.href = window.location.origin;
          }
        } else {
          getUser();
        }
      },
      onError() {
        setValue(forms.state.is_loading, false);
      }
    }
  );

  return mutation;
};
