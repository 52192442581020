import * as React from 'react';
import clsx from 'clsx';
import { Typography } from 'core/components/Typography';
import { useAuth0 } from '@auth0/auth0-react';
import ReviewerRegistrationData from 'features/registration/reviewer/data/reviewer.registration.json';

export interface UnverifiedEmailReviewerRegistrationProps {}

export const UnverifiedEmailReviewerRegistration = (
  props: UnverifiedEmailReviewerRegistrationProps
) => {
  const { user, logout } = useAuth0();

  const handleClickTryAgain = () => {
    logout({
      logoutParams: {
        returnTo: `${process.env.REACT_APP_REDIRECT_LOGOUT_LINK}/reviewer`
      }
    });
  };
  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-content-center place-items-center',
        'w-full max-w-[464px]'
      )}>
      <Typography variant={'Heading'} size={'H1'} color={'text-grayscale-90'}>
        {ReviewerRegistrationData.unverified_email.message}
      </Typography>
      <div
        className={clsx(
          'grid grid-cols-1 place-content-center place-items-center gap-[0.5rem]',
          'w-full'
        )}>
        <div className={clsx('grid grid-cols-1 place-content-center place-items-center', 'w-full')}>
          <Typography
            className={clsx('text-center')}
            variant={'Regular'}
            size={'M'}
            color={'text-grayscale-70'}>
            {ReviewerRegistrationData.unverified_email.description_1}
            <span className={clsx('text-cta-purple-primary')}>{user?.email ?? ''}</span>
          </Typography>
          <Typography variant={'Regular'} size={'M'} color={'text-grayscale-70'}>
            {ReviewerRegistrationData.unverified_email.description_2}
            <button className={clsx('text-grayscale-90 font-medium')} onClick={handleClickTryAgain}>
              {ReviewerRegistrationData.unverified_email.cta.try_again.children}
            </button>
          </Typography>
        </div>

        <Typography
          variant={'Regular'}
          size={'M'}
          color={'text-grayscale-70'}
          className={clsx('text-center')}>
          {ReviewerRegistrationData.unverified_email.description_3}
          <a
            href={'https://www.aaico.com/contact-us'}
            target={'_blank'}
            rel="noreferrer"
            className={clsx('text-grayscale-90 font-medium')}>
            {ReviewerRegistrationData.unverified_email.cta.support.children}
          </a>
        </Typography>
      </div>
    </div>
  );
};
