import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  GetInvoicesRequestInterface,
  GetInvoicesResponseInterface
} from 'core/models/api/invoices';
import { fetchGetInvoices } from 'core/services/invoices';
import { BillingReactQueryKey } from '../keys';
import { gql } from 'graphql-request';
import { useFormContext } from 'react-hook-form';
import { DataTableInvoicesForm } from 'features/billing/react_hook_form/constants/type';
import { useEffect, useMemo } from 'react';
import { BillingForm, forms } from 'features/billing/react_hook_form/constants';
import BillingData from 'features/billing/data/invoices.json';

export const useGeneralBillingQueryGetinvoices = () => {
  const token = localStorage.getItem('token') ?? '';
  const { watch, setValue } = useFormContext<BillingForm>();
  const LIMIT = 10;
  const payload: GetInvoicesRequestInterface = useMemo(() => {
    const OFFSET = 0;

    return {
      headers: {
        token: token
      },
      data: {
        query: gql`
          query {
            invoices(offset: ${OFFSET}, limit: ${LIMIT}) {
              id
              number
              createdAt
              dueDate
              status
              services
              jobCount
              pageCount
              totalAmount
              currency
              pdfUrl
              paymentUrl
            }
          }
        `
      }
    };
  }, [watch(forms.general.invoices.data_table.offset) as number, token, LIMIT]);

  const query = useQuery<GetInvoicesResponseInterface | undefined, AxiosError>(
    BillingReactQueryKey.QueryGeneralGetInvoices(payload),
    () => {
      return fetchGetInvoices(payload);
    },
    {
      retry: 0,
      enabled:
        (watch(forms.tabs.active) as null | { id: string; name: string })?.id ===
        BillingData.tabs[0].id
    }
  );

  useEffect(() => {
    if (query.data?.data.invoices) {
      const tableData: DataTableInvoicesForm[] =
        query?.data?.data?.invoices?.map((invoice) => {
          return {
            id: invoice.id,
            date: invoice.createdAt,
            number: invoice.number,
            used_services: invoice.services.toString(),
            jobs: invoice.jobCount.toLocaleString('en-US', {
              maximumFractionDigits: 0
            }),
            pages: invoice.pageCount.toLocaleString('en-US', {
              maximumFractionDigits: 0
            }),
            due_date: invoice.dueDate,
            status: invoice.status,
            total_amount: invoice.totalAmount.toLocaleString('en-US', {
              maximumFractionDigits: 0,
              style: 'currency',
              currency: 'USD'
            }),
            pay: invoice.paymentUrl,
            pdf: invoice.pdfUrl,
            checked: false
          };
        }) || [];
      setValue(forms.general.invoices.data_table.data, tableData as any[]);
    }
  }, [query.data]);

  return query;
};

export const useInvoicesBillingQueryGetinvoices = () => {
  const token = localStorage.getItem('token') ?? '';
  const { watch, setValue } = useFormContext<BillingForm>();
  const LIMIT = 10;
  const services = watch(forms.invoices.data_table.services) as { id: string; name: string };

  const payload: GetInvoicesRequestInterface = useMemo(() => {
    const OFFSET = watch(forms.invoices.data_table.offset) as number;

    return {
      headers: {
        token: token
      },
      data: {
        query: gql`
          query {
            invoices(offset: ${OFFSET}, limit: ${LIMIT}${
              services.id !== 'ALL' ? `, service: "${services.id}"` : ''
            }) {
              id
              number
              createdAt
              dueDate
              status
              services
              jobCount
              pageCount
              totalAmount
              currency
              pdfUrl
              paymentUrl
            }
          }
        `
      }
    };
  }, [
    watch(forms.invoices.data_table.offset) as number,
    token,
    LIMIT,
    watch(forms.invoices.data_table.services) as { id: string; name: string }
  ]);

  const query = useQuery<GetInvoicesResponseInterface | undefined, AxiosError>(
    BillingReactQueryKey.QueryInvoicesGetInvoices(payload),
    () => {
      return fetchGetInvoices(payload);
    },
    {
      retry: 0,
      enabled:
        (watch(forms.tabs.active) as null | { id: string; name: string })?.id ===
        BillingData.tabs[1].id
    }
  );

  useEffect(() => {
    if (query.data?.data.invoices) {
      const tableData: DataTableInvoicesForm[] =
        query?.data?.data?.invoices?.map((invoice) => {
          return {
            id: invoice.id,
            date: invoice.createdAt,
            number: invoice.number,
            used_services: invoice.services.toString(),
            jobs: invoice.jobCount.toLocaleString('en-US', {
              maximumFractionDigits: 0
            }),
            pages: invoice.pageCount.toLocaleString('en-US', {
              maximumFractionDigits: 0
            }),
            due_date: invoice.dueDate,
            status: invoice.status,
            total_amount: invoice.totalAmount.toLocaleString('en-US', {
              maximumFractionDigits: 0,
              style: 'currency',
              currency: 'USD'
            }),
            pay: invoice.paymentUrl,
            pdf: invoice.pdfUrl,
            checked: false
          };
        }) || [];
      setValue(forms.invoices.data_table.data, tableData as any[]);
    }
  }, [query.data]);

  return query;
};
