import { MenuItem } from 'core/components/MenuItem';
import { MenuItems } from 'core/components/MenuItems';
import { Sidebar } from 'core/components/Sidebar';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { DashboardForm } from '../../react_hook_form/constants/type';
import { forms } from '../../react_hook_form/constants/data';

export const SidebarDashboard = () => {
  const { watch } = useFormContext<DashboardForm>();
  const menu =
    (watch(forms.sidebar.menu) as
      | { name: string; icon: string; href: string; active: boolean }[]
      | undefined) ?? [];
  return (
    <Sidebar type={'Customer'}>
      <MenuItems>
        {menu.map((item, index) => (
          <MenuItem key={index} {...item} />
        ))}
      </MenuItems>
    </Sidebar>
  );
};
