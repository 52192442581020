import * as React from 'react';
import clsx from 'clsx';
import { TableHead } from 'core/components/TableHead';
import { TableBody } from 'core/components/TableBody';
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import BillingData from 'features/billing/data/invoices.json';
import { Typography } from 'core/components/Typography';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import {
  getStatusLabelBackgroundColorInvoiceTable,
  getStatusLabelColorInvoiceTable
} from 'features/billing/utils';
import { useFormContext } from 'react-hook-form';
import { InfoTipIcon } from 'core/icons/InfoTip';
import { Tooltip } from 'core/components/Tooltip/Tooltip';
import { Link } from 'react-router-dom';
import { HeaderCellDataTableBilling } from 'features/billing/components/HeaderCellDataTable';
import { DueDateCellDataTableBilling } from 'features/billing/components/DueDateCellDataTable';
import {
  BillingForm,
  DataTableInvoicesForm
} from 'features/billing/react_hook_form/constants/type';
import { forms } from 'features/billing/react_hook_form/constants/data';
import { RetryPaymentPopUpBilling } from 'features/billing/components/RetryPaymentPopUp';
import { BankPaymentPopUpBilling } from 'features/billing/components/BankPaymentPopUp';
import { CheckPaymentPopUpBilling } from 'features/billing/components/CheckPaymentPopUp';
import { PaymentDropdownBilling } from 'features/billing/components/PaymentDropdown';
import { ArrowRightIcon } from 'core/icons/ArrowRight';

export const GeneralDataTableBilling = () => {
  const { watch, setValue } = useFormContext<BillingForm>();

  const invoices = watch(forms.general.invoices.data_table.data) as DataTableInvoicesForm[];

  const columns = React.useMemo<ColumnDef<DataTableInvoicesForm>[]>(() => {
    return BillingData.data_table.min_columns.map((item, index) => {
      return {
        accessorKey: item.id,
        header: () => {
          return <HeaderCellDataTableBilling label={item.label} />;
        },
        cell: (cellProps) => {
          if (item.id === 'id') {
            return <span />;
          }

          if (item.id === 'date' && cellProps.row.getValue('id')) {
            const date = new Date(cellProps.row.getValue('date')).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            });
            return (
              <Typography variant={'Regular'} size={'XS'} color={'text-grayscale-70'}>
                {date}
              </Typography>
            );
          }

          if (item.id === 'due_date' && cellProps.row.getValue('id')) {
            const dueDate = new Date(cellProps.row.getValue('due_date')).toLocaleDateString(
              'en-US',
              {
                month: 'short',
                day: 'numeric',
                year: 'numeric'
              }
            );
            return <DueDateCellDataTableBilling label={dueDate} />;
          }

          if (
            item.id === 'pay' &&
            (cellProps.row.getValue('status') as string).toLowerCase() === 'open'
          ) {
            const [paymentMethod, setPaymentMethod] = React.useState<null | {
              id: string;
              name: string;
            }>(null);
            const handleSelectPaymentMethod = (data: { id: string; name: string }) => {
              setPaymentMethod(data);
              if (data.id === 'credit_card') {
                window.open(cellProps.row.getValue('pay') as string, '_blank');
              }
            };
            const handleClose = () => {
              setPaymentMethod(null);
            };
            const handleSubmit = () => {
              setPaymentMethod(null);
            };
            return (
              <div className={clsx('flex items-center justify-left', 'w-full')}>
                <PaymentDropdownBilling
                  placeholder={item.label}
                  options={BillingData.data_table.payment_method}
                  onSelect={handleSelectPaymentMethod}
                />

                <CheckPaymentPopUpBilling
                  isOpen={paymentMethod !== null && paymentMethod.id === 'submit_check'}
                  message={BillingData.data_table.check_payment.title}
                  description={BillingData.data_table.check_payment.message.default}
                  cta={BillingData.data_table.check_payment.cta}
                  onClose={handleClose}
                  onSubmit={handleSubmit}
                />
                <BankPaymentPopUpBilling
                  isOpen={paymentMethod !== null && paymentMethod.id === 'bank_transfer'}
                  message={BillingData.data_table.bank_transfer.title}
                  bannerMessage={BillingData.data_table.bank_transfer.banner.message}
                  description={BillingData.data_table.bank_transfer.message.default}
                  informations={BillingData.data_table.bank_transfer.informations.data}
                  cta={BillingData.data_table.bank_transfer.cta}
                  onClose={handleClose}
                  onSubmit={handleSubmit}
                />
              </div>
            );
          }

          if (
            item.id === 'pay' &&
            (cellProps.row.getValue('status') as string).toLowerCase() === 'failed'
          ) {
            const [paymentMethod, setPaymentMethod] = React.useState<null | {
              id: string;
              name: string;
            }>(null);
            const handleSelectPaymentMethod = (data: { id: string; name: string }) => {
              setPaymentMethod(data);
              if (data.id === 'credit_card') {
                window.open(cellProps.row.getValue('pay') as string, '_blank');
              }
            };
            const handleClose = () => {
              setPaymentMethod(null);
            };
            const handleSubmit = () => {
              setPaymentMethod(null);
            };
            return (
              <div className={clsx('flex items-center justify-left', 'w-full')}>
                <PaymentDropdownBilling
                  placeholder={item.label}
                  options={BillingData.data_table.payment_method}
                  onSelect={handleSelectPaymentMethod}
                />

                <CheckPaymentPopUpBilling
                  isOpen={paymentMethod !== null && paymentMethod.id === 'submit_check'}
                  message={BillingData.data_table.check_payment.title}
                  description={BillingData.data_table.check_payment.message.default}
                  cta={BillingData.data_table.check_payment.cta}
                  onClose={handleClose}
                  onSubmit={handleSubmit}
                />
                <BankPaymentPopUpBilling
                  isOpen={paymentMethod !== null && paymentMethod.id === 'bank_transfer'}
                  message={BillingData.data_table.bank_transfer.title}
                  bannerMessage={BillingData.data_table.bank_transfer.banner.message}
                  description={BillingData.data_table.bank_transfer.message.default}
                  informations={BillingData.data_table.bank_transfer.informations.data}
                  cta={BillingData.data_table.bank_transfer.cta}
                  onClose={handleClose}
                  onSubmit={handleSubmit}
                />
              </div>
            );
          }

          if (
            item.id === 'pay' &&
            (cellProps.row.getValue('status') as string).toLowerCase() === 'overdue'
          ) {
            const [paymentMethod, setPaymentMethod] = React.useState<null | {
              id: string;
              name: string;
            }>(null);
            const handleSelectPaymentMethod = (data: { id: string; name: string }) => {
              setPaymentMethod(data);
              if (data.id === 'credit_card') {
                window.open(cellProps.row.getValue('pay') as string, '_blank');
              }
            };
            const handleClose = () => {
              setPaymentMethod(null);
            };
            const handleSubmit = () => {
              setPaymentMethod(null);
            };

            return (
              <div className={clsx('flex items-center justify-left', 'w-full')}>
                <PaymentDropdownBilling
                  placeholder={item.label}
                  options={BillingData.data_table.payment_method}
                  onSelect={handleSelectPaymentMethod}
                />

                <CheckPaymentPopUpBilling
                  isOpen={paymentMethod !== null && paymentMethod.id === 'submit_check'}
                  message={BillingData.data_table.check_payment.title}
                  description={BillingData.data_table.check_payment.message.default}
                  cta={BillingData.data_table.check_payment.cta}
                  onClose={handleClose}
                  onSubmit={handleSubmit}
                />
                <BankPaymentPopUpBilling
                  isOpen={paymentMethod !== null && paymentMethod.id === 'bank_transfer'}
                  message={BillingData.data_table.bank_transfer.title}
                  bannerMessage={BillingData.data_table.bank_transfer.banner.message}
                  description={BillingData.data_table.bank_transfer.message.default}
                  informations={BillingData.data_table.bank_transfer.informations.data}
                  cta={BillingData.data_table.bank_transfer.cta}
                  onClose={handleClose}
                  onSubmit={handleSubmit}
                />
              </div>
            );
          }
          if (
            item.id === 'pay' &&
            (cellProps.row.getValue('status') as string).toLowerCase() === 'error'
          ) {
            const [isOpen, setIsOpen] = React.useState<boolean>(false);
            const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
              setIsOpen(() => true);
            };

            const handleClose = () => {
              setIsOpen(() => false);
            };

            const handleSubmit = () => {
              setIsOpen(() => false);
            };
            return (
              <div className={clsx('flex items-center justify-left', 'w-full')}>
                <button
                  className={clsx(
                    'bg-cta-mint-primary',
                    'rounded-[0.25rem]',
                    'text-[0.875rem] font-medium leading-[146%] tracking-[-0.14px]',
                    'w-[5rem] h-[2rem]'
                  )}
                  onClick={handleClick}>
                  {'Retry'}
                </button>
                <RetryPaymentPopUpBilling
                  isOpen={isOpen}
                  message={BillingData.data_table.retry_payment.title}
                  description={BillingData.data_table.retry_payment.message.default}
                  cta={{
                    ...BillingData.data_table.retry_payment.cta,
                    disabled: true
                  }}
                  onClose={handleClose}
                  onSubmit={handleSubmit}
                />
              </div>
            );
          }

          if (
            item.id === 'pay' &&
            ((cellProps.row.getValue('status') as string).toLowerCase() !== 'open' ||
              (cellProps.row.getValue('status') as string).toLowerCase() !== 'overdue' ||
              (cellProps.row.getValue('status') as string).toLowerCase() !== 'error')
          ) {
            return <span />;
          }

          if (
            item.id === 'status' &&
            (cellProps.row.getValue(item.id) as string).toLowerCase() !== 'error'
          ) {
            return (
              <div
                className={clsx(
                  'px-[0.5rem] py-[0.25rem]',
                  'rounded-[0.25rem]',
                  'w-fit',
                  'text-[0.75rem] font-semibold leading-[136%] tracking-[0.3px] uppercase',
                  getStatusLabelBackgroundColorInvoiceTable(cellProps.row.getValue(item.id)),
                  getStatusLabelColorInvoiceTable(cellProps.row.getValue(item.id))
                )}>
                {cellProps.row.getValue(item.id)}
              </div>
            );
          }

          if (
            item.id === 'status' &&
            (cellProps.row.getValue(item.id) as string).toLowerCase() === 'error'
          ) {
            return (
              <div
                className={clsx(
                  'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.5rem]',
                  'w-full'
                )}>
                <div
                  className={clsx(
                    'px-[0.5rem] py-[0.25rem]',
                    'rounded-[0.25rem]',
                    'w-fit',
                    'text-[0.75rem] font-semibold leading-[136%] tracking-[0.3px] uppercase',
                    getStatusLabelBackgroundColorInvoiceTable(cellProps.row.getValue(item.id)),
                    getStatusLabelColorInvoiceTable(cellProps.row.getValue(item.id))
                  )}>
                  {cellProps.row.getValue(item.id)}
                </div>

                <Tooltip
                  text={`Payment couldn't be processed due to a faulty check scan.`}
                  variant={'white'}
                  position={'bottom'}>
                  <InfoTipIcon iconSize={'XS'} className={'fill-grayscale-55'} />
                </Tooltip>
              </div>
            );
          }
          if (item.id === 'pdf' && (cellProps.row.getValue(item.id) as string).length) {
            return (
              <div className={clsx('w-full', 'flex items-center')}>
                <Link
                  aria-label={'credit-card-payment'}
                  to={cellProps.row.getValue(item.id) as string}
                  target={'_blank'}
                  className={clsx(
                    'py-[0.125rem]',
                    'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.5rem]',
                    'bg-transparent',
                    'text-[0.875rem] font-medium leading-[146%] tracking-[-0.14px]',
                    'text-grayscale-90 disabled:text-grayscale-50'
                  )}>
                  {item.label}
                  <ArrowDownTrayIcon className={clsx('text-grayscale-90', 'w-[1rem] h-[1rem]')} />
                </Link>
              </div>
            );
          }

          if (item.id === 'total_amount') {
            return (
              <div className={clsx('flex items-center justify-end', 'w-full', 'max-w-[80px]')}>
                <Typography variant={'Regular'} size={'XS'} color={'text-grayscale-70'}>
                  {cellProps.row.getValue(item.id)}
                </Typography>
              </div>
            );
          }

          return (
            <Typography variant={'Regular'} size={'XS'} color={'text-grayscale-70'}>
              {cellProps.row.getValue(item.id)}
            </Typography>
          );
        }
      };
    });
  }, [BillingData.data_table.columns]);

  const table = useReactTable({
    data: invoices,
    columns: columns,
    state: {
      pagination: {
        pageIndex: 0,
        pageSize: 5
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    paginateExpandedRows: false,
    autoResetPageIndex: false,
    debugTable: true
  });

  const handleClickSeeAll = () => {
    setValue(forms.tabs.active, BillingData.tabs[1]);
  };

  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-content-start place-items-start gap-[1rem]',
        'w-full'
      )}>
      <div className={clsx('flex items-center justify-between mt-9 mb-4', 'w-full')}>
        <Typography variant={'Medium'} size={'M'} color={'text-grayscale-90'}>
          Invoices
        </Typography>
        <button onClick={handleClickSeeAll}>
          <Typography
            variant={'Medium'}
            size={'S'}
            color={'text-grayscale-70'}
            className="flex items-center">
            See all <ArrowRightIcon className="ml-2" />
          </Typography>
        </button>
      </div>
      <table className={clsx('wide-spacing', 'w-full')}>
        <TableHead table={table} />
        <TableBody table={table} marginTop="pt-5" />
      </table>
    </div>
  );
};
