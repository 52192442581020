import * as React from 'react';
import clsx from 'clsx';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import UsersData from 'features/users/data/users.json';
import { Pagination } from 'core/components/Pagination';

import { useFormContext } from 'react-hook-form';
import { HeaderCellDataTableUsers } from 'features/users/components/HeaderCellDataTable';
import { UsersForm, DataTableForm } from 'features/users/react_hook_form/constants/type';
import { forms } from 'features/users/react_hook_form/constants/data';
import { NameCellDataTableUsers } from 'features/users/components/NameCellDataTable';
import { EmailCellDataTableUsers } from 'features/users/components/EmailCellDataTable';
import { DateOfSignUpCellDataTableUsers } from 'features/users/components/DateOfSignUpCellDataTable';
import { AdminRoleCellDataTableUsers } from 'features/users/components/AdminRoleCellDataTable';
import { TypographyColorProps } from 'core/components/Typography/Typography.types';
import { ManagerRoleCellDataTableUsers } from 'features/users/components/ManagerRoleCellDataTable/ManagerRoleCellDataTable.users';
import { ViewerRoleCellDataTableUsers } from 'features/users/components/ViewerRoleCellDataTable';
import InvitedRoleCellDataTableUsers from 'features/users/components/InvitedRoleCellDataTable/InvitedRoleCellDataTable.users';
import DeletedRoleCellDataTableUsers from 'features/users/components/DeletedRoleCellDataTable/DeletedRoleCellDataTable.users';
import InactiveRoleCellDataTableUsers from 'features/users/components/InactiveRoleCellDataTable/InactiveRoleCellDataTable.users';
import {
  useUsersRolesMutationUpdateUser,
  useUsersStatusMutationUpdateUser
} from 'features/users/react_query/hooks/useMutationUpdateUser.users';

export const DataTableUsers = () => {
  const { watch, setValue } = useFormContext<UsersForm>();
  const { mutate: updateStatus } = useUsersStatusMutationUpdateUser();
  const { mutate: updateRoles } = useUsersRolesMutationUpdateUser();

  const dataTable = watch(forms.data_table.data) as DataTableForm[];

  const columns = React.useMemo<ColumnDef<DataTableForm>[]>(() => {
    return UsersData.data_table.columns.map((item, index) => {
      return {
        accessorKey: item.id,
        header: () => {
          return <HeaderCellDataTableUsers label={item.label} />;
        },
        cell: (cellProps) => {
          if (item.id === 'id') {
            return <span />;
          }

          if (item.id === 'name') {
            const value = cellProps.row.getValue('name') as string;

            const color: TypographyColorProps =
              value.toLowerCase() === 'waiting for acceptance'
                ? 'text-grayscale-70'
                : (cellProps.row.getValue('date_of_sign_up') as string).toLowerCase() === 'invited'
                ? 'text-grayscale-60'
                : (cellProps.row.getValue('date_of_sign_up') as string).toLowerCase() === 'inactive'
                ? 'text-grayscale-60'
                : (cellProps.row.getValue('date_of_sign_up') as string).toLowerCase() === 'deleted'
                ? 'text-grayscale-60'
                : 'text-grayscale-80';

            const avatarBackgroundColor =
              (cellProps.row.getValue('date_of_sign_up') as string).toLowerCase() === 'invited'
                ? '#C8CBCA'
                : (cellProps.row.getValue('date_of_sign_up') as string).toLowerCase() === 'inactive'
                ? '#C8CBCA'
                : (cellProps.row.getValue('date_of_sign_up') as string).toLowerCase() === 'deleted'
                ? '#C8CBCA'
                : '#C8CBCA';
            const avatarTextColor: TypographyColorProps =
              (cellProps.row.getValue('date_of_sign_up') as string).toLowerCase() === 'invited'
                ? 'text-grayscale-10'
                : (cellProps.row.getValue('date_of_sign_up') as string).toLowerCase() === 'inactive'
                ? 'text-grayscale-10'
                : (cellProps.row.getValue('date_of_sign_up') as string).toLowerCase() === 'deleted'
                ? 'text-grayscale-10'
                : 'text-grayscale-90';

            return (
              <NameCellDataTableUsers
                value={value}
                color={color}
                avatar={{
                  backgroundColor: avatarBackgroundColor,
                  textColor: avatarTextColor
                }}
              />
            );
          }

          if (item.id === 'email') {
            const parameter = cellProps.row.getValue('name') as string;
            const color: TypographyColorProps =
              parameter.toLowerCase() === 'waiting for acceptance'
                ? 'text-grayscale-70'
                : (cellProps.row.getValue('date_of_sign_up') as string).toLowerCase() === 'invited'
                ? 'text-grayscale-60'
                : (cellProps.row.getValue('date_of_sign_up') as string).toLowerCase() === 'inactive'
                ? 'text-grayscale-60'
                : (cellProps.row.getValue('date_of_sign_up') as string).toLowerCase() === 'deleted'
                ? 'text-grayscale-60'
                : 'text-grayscale-80';
            return (
              <EmailCellDataTableUsers value={cellProps.row.getValue('email')} color={color} />
            );
          }
          if (item.id === 'date_of_sign_up') {
            const value = cellProps.row.getValue('date_of_sign_up') as string;

            const color: TypographyColorProps =
              value.toLowerCase() === 'invited'
                ? 'text-success-primary'
                : value.toLowerCase() === 'deleted'
                ? 'text-error-primary'
                : value.toLowerCase() === 'inactive'
                ? 'text-grayscale-60'
                : 'text-grayscale-80';
            return (
              <DateOfSignUpCellDataTableUsers
                value={cellProps.row.getValue('date_of_sign_up')}
                color={color}
              />
            );
          }
          if (item.id === 'role') {
            const value = cellProps.row.getValue('role') as string;
            const status = cellProps.row.getValue('date_of_sign_up') as string;

            if (
              value.toLowerCase() === 'manager' &&
              status !== 'invited' &&
              status !== 'inactive' &&
              status !== 'deleted'
            ) {
              const handleClickRole = (data: { id: string; name: string }) => {
                updateRoles(data);
              };
              const handleClickActions = (data: { id: string; name: string }) => {
                updateStatus(data);
              };
              return (
                <ManagerRoleCellDataTableUsers
                  role={{
                    value:
                      UsersData.data_table.role.options.find((item) => item.id === value) ?? null,
                    options: UsersData.data_table.role.options,
                    onClick: handleClickRole
                  }}
                  actions={{
                    options: UsersData.data_table.user_actions.active.options,
                    onClick: handleClickActions
                  }}
                />
              );
            }
            if (
              value.toLowerCase() === 'viewer' &&
              status !== 'invited' &&
              status !== 'inactive' &&
              status !== 'deleted'
            ) {
              const handleClickRole = (data: { id: string; name: string }) => {
                updateRoles(data);
              };
              const handleClickActions = (data: { id: string; name: string }) => {
                updateStatus(data);
              };
              return (
                <ViewerRoleCellDataTableUsers
                  role={{
                    value:
                      UsersData.data_table.role.options.find((item) => item.id === value) ?? null,
                    options: UsersData.data_table.role.options,
                    onClick: handleClickRole
                  }}
                  actions={{
                    options: UsersData.data_table.user_actions.active.options,
                    onClick: handleClickActions
                  }}
                />
              );
            }
            if (status.toLowerCase() === 'invited') {
              const handleClickActions = (data: { id: string; name: string }) => {
                updateStatus(data);
              };
              return (
                <InvitedRoleCellDataTableUsers
                  actions={{
                    options: UsersData.data_table.user_actions.invited.options,
                    onClick: handleClickActions
                  }}
                />
              );
            }
            if (status.toLowerCase() === 'deleted') {
              const handleClickActions = (data: { id: string; name: string }) => {
                updateStatus(data);
              };
              return (
                <DeletedRoleCellDataTableUsers
                  actions={{
                    options: UsersData.data_table.user_actions.deleted.options,
                    onClick: handleClickActions
                  }}
                />
              );
            }
            if (status.toLowerCase() === 'inactive') {
              const handleClickActions = (data: { id: string; name: string }) => {
                updateStatus(data);
              };
              return (
                <InactiveRoleCellDataTableUsers
                  actions={{
                    options: UsersData.data_table.user_actions.inactive.options,
                    onClick: handleClickActions
                  }}
                />
              );
            }
            return <AdminRoleCellDataTableUsers value={value} />;
          }
        }
      };
    });
  }, [UsersData.data_table.columns]);

  const table = useReactTable({
    data: dataTable,
    columns: columns,
    state: {
      pagination: {
        pageIndex: 0,
        pageSize: 20
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    paginateExpandedRows: false,
    autoResetPageIndex: false,
    debugTable: true
  });

  const LIMIT = 10;

  const offset = watch(forms.data_table.offset) as number;

  const paginationLabel = `${offset + 1}-${offset + 10} of ${
    watch(forms.data_table.total_data) as number
  } Invoices`;

  const handlePreviousPage = () => {
    setValue(forms.data_table.offset, (watch(forms.data_table.offset) as number) - 10);
  };
  const handleNextPage = () => {
    setValue(forms.data_table.offset, (watch(forms.data_table.offset) as number) + 1 * 10);
  };

  const borderIndex = dataTable.findIndex(
    (item) =>
      item.date_of_sign_up.toLowerCase() === 'invited' ||
      item.date_of_sign_up.toLowerCase() === 'deleted' ||
      item.date_of_sign_up.toLowerCase() === 'inactive'
  );

  const isFirstPage = offset === 0;
  const isLastPage = (watch(forms.data_table.total_data) as number) - offset < LIMIT;

  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-content-start place-items-start gap-[1rem]',
        'w-full'
      )}>
      <table className={clsx('grid grid-cols-1 place-content-start place-items-start', 'w-full')}>
        <thead
          className={clsx(
            'grid grid-cols-1 place-content-start place-items-start',
            'bg-grayscale-20',
            'w-full'
          )}>
          {table !== null &&
            table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className={clsx(
                  'grid place-content-start place-items-start',
                  'w-full',
                  'px-[0.5rem]'
                )}
                style={{
                  gridTemplateColumns: `1fr 1fr 1fr 1fr`
                }}>
                {headerGroup.headers
                  .filter((header) => {
                    return header.id !== 'id';
                  })
                  .map((header) => {
                    return (
                      <th
                        className={clsx(
                          'grid grid-cols-1 place-content-start place-items-start',
                          'w-full'
                        )}
                        key={header.id}
                        colSpan={header.colSpan}>
                        <div
                          className={clsx(
                            'w-full',
                            'cursor-default select-none flex justify-left items-center'
                          )}>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </div>
                      </th>
                    );
                  })}
              </tr>
            ))}
        </thead>
        <tbody
          className={clsx(
            'grid grid-cols-1 place-content-start place-items-start',
            'w-full',
            'h-[calc(100vh_-_360px)]',
            'overflow-auto'
          )}>
          {table !== null && table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row, idx) => (
              <tr
                key={row.id}
                role={'table-row'}
                className={clsx(
                  'grid place-content-start place-items-start',
                  'w-full',
                  borderIndex - 1 === idx
                    ? 'border-b-[0.25rem] border-b-grayscale-30'
                    : 'border-b border-b-grayscale-30 hover:bg-grayscale-20',
                  'px-[0.5rem]'
                )}
                style={{
                  gridTemplateColumns: `0px 1fr 1fr 1fr 1fr`
                }}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      className={clsx('py-[0.625rem]', 'text-left', 'w-full h-full')}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <tr
              className={clsx('grid grid-cols-1 place-content-start place-items-start', 'w-full')}>
              <td
                colSpan={table?.getHeaderGroups()[0]?.headers?.length}
                className={clsx(
                  'text-center border-b border-b-grayscale-30 hover:bg-grayscale-20',
                  'w-full'
                )}>
                <p className="text-grayscale-70 text-center py-4">No data available</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div
        className={clsx(
          'grid grid-flow-col items-center content-center justify-items-center justify-center',
          'w-[calc(100vw-216px)]',
          'fixed bottom-[1rem] left-[216px] right-[0px]'
        )}>
        <Pagination
          is_first_page={isFirstPage}
          is_last_page={isLastPage}
          label={paginationLabel}
          onPreviousPage={handlePreviousPage}
          onNextPage={handleNextPage}
        />
      </div>
    </div>
  );
};
