import * as React from 'react';
import clsx from 'clsx';
import { Typography } from 'core/components/Typography';

export interface HeaderCellDataTableBillingProps {
  label?: string;
}

export const HeaderCellDataTableBilling = ({ label = '' }: HeaderCellDataTableBillingProps) => {
  return (
    <div className={clsx('w-full', 'py-[0.25rem]', 'text-left')}>
      <Typography variant={'Regular'} size={'XS'} color={'text-grayscale-70'}>
        {label}
      </Typography>
    </div>
  );
};
