import { Typography } from 'core/components/Typography';
import * as React from 'react';
import clsx from 'clsx';
import { ArrowRightIcon } from 'core/icons/ArrowRight';
import { useFormContext } from 'react-hook-form';
import { BillingForm, forms } from 'features/billing/react_hook_form/constants';
import { GetEstimatedUsageSuccessResponseInterface } from 'core/models/api/invoices';
import { useGeneralBillingQueryGetEstimatedOrganizationUsage } from 'features/billing/react_query/hooks/useQueryEstimatedOrganizationUsage.billing';
import { InfoTipIcon } from 'core/icons/InfoTip';
import BillingData from 'features/billing/data/invoices.json';

export const GeneralInformationBilling = () => {
  const { watch, setValue } = useFormContext<BillingForm>();

  useGeneralBillingQueryGetEstimatedOrganizationUsage();

  const estimate = watch(
    forms.general.information
  ) as GetEstimatedUsageSuccessResponseInterface['data']['estimatedOrganizationUsage'];

  const percentage =
    estimate?.creditsUsed === 0 && estimate.creditsTotal === 0
      ? 0
      : ((estimate?.creditsUsed ?? 0) / (estimate?.creditsTotal ?? 0)) * 100;

  const billableExist = Number(estimate?.totalAmount ?? 0) > 0;

  const period = `${new Date().toLocaleDateString('default', {
    month: 'long'
  })}, ${new Date().getFullYear()}`;

  const totalRemaining = (estimate?.creditsTotal ?? 0) - (estimate?.creditsUsed ?? 0);

  const handleClickSeeDetail = () => {
    setValue(forms.tabs.active, BillingData.tabs[2]);
  };
  return (
    <div className={clsx('w-full')}>
      <div>
        <Typography
          className="text-[1.0625rem]"
          variant={'Medium'}
          size={'L'}
          color={'text-grayscale-90'}>
          Current cycle
        </Typography>
        <div
          className={clsx(
            'flex flex-col justify-center rounded-xl bg-grayscale-20 px-8 py-6 mt-5 gap-y-6'
          )}>
          <div className={clsx('flex items-center justify-between')}>
            <Typography variant={'Medium'} size={'M'} color={'text-grayscale-90'}>
              {period}
            </Typography>
            <button className={clsx('cursor-pointer')} onClick={handleClickSeeDetail}>
              <Typography
                variant={'Medium'}
                size={'S'}
                color={'text-grayscale-70'}
                className="flex items-center">
                See details <ArrowRightIcon className="ml-2" />
              </Typography>
            </button>
          </div>
          <div className="flex justify-between flex-wrap gap-y-3">
            <div
              className={`inline-flex flex-row items-center bg-${
                billableExist ? 'warning-tint' : 'grayscale-40'
              } pt-2 pr-4 pb-2 pl-3 border border-white rounded-3xl`}>
              <InfoTipIcon
                iconSize={'M'}
                className={`fill-${billableExist ? 'warning-primary' : 'grayscale-60'} mr-2`}
              />
              <span className="text-sm text-grayscale-90 mr-1.5 font-normal">
                Estimated billable amount:
              </span>
              <span className="text-base text-grayscale-90 font-medium">
                ${estimate?.totalAmount ?? ''}
              </span>
            </div>
            <div className="flex flex-col gap-y-1.5 w-full max-w-2xl">
              <div className="flex justify-between flex-wrap">
                <div className="flex items-center">
                  <span className="text-sm text-grayscale-70 font-normal mr-1.5">
                    Used this month:
                  </span>
                  <span className="text-base text-grayscale-90 font-medium mr-1.5">
                    {estimate?.creditsUsed ?? 0}
                  </span>
                  <span className="text-base text-grayscale-70 font-medium">
                    {' '}
                    / {estimate?.creditsTotal}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-sm text-grayscale-70 font-normal mr-1.5">
                    Total remaining:
                  </span>
                  <span className="text-base text-grayscale-90 font-medium">{totalRemaining}</span>
                </div>
              </div>
              <div className="h-[0.625rem] bg-cta-purple-tint border border-grayscale-10 rounded-3xl">
                <div
                  className={`h-[0.525rem] bg-cta-purple-primary border border-cta-purple-tint rounded-3xl`}
                  style={{ width: `${percentage}%` }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
