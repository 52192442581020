import { useQuery } from '@tanstack/react-query';
import { RegistrationReactQueryKey } from '../keys';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useReviewerRegistrationGetIdTokenClaims } from './useGetIdTokenClaims.registration.reviewer';

export const useReviewerRegistrationGetAccessTokenSilently = () => {
  const { isLoading: isLoadingAuth0, getAccessTokenSilently } = useAuth0();

  const { mutate: getIdTokenClaims } = useReviewerRegistrationGetIdTokenClaims();
  const query = useQuery<string | undefined>(
    RegistrationReactQueryKey.GetIdTokenClaims(),
    () => {
      return getAccessTokenSilently()
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    {
      retry: 0,
      enabled: !isLoadingAuth0
    }
  );

  useEffect(() => {
    if (query.data) {
      if (typeof query.data !== 'undefined') {
        localStorage.setItem('token', query.data);

        getIdTokenClaims();
      }
    }
  }, [query.data]);

  return query;
};
