import clsx from 'clsx';
import { Typography } from 'core/components/Typography';
import * as React from 'react';
import RegistrationData from '../../data/registration.json';
import { useFormContext } from 'react-hook-form';
import { Button } from 'core/components/Button';
import { RegistrationForm } from 'features/registration/customer/react_hook_form/constants/type';
import { forms } from 'features/registration/customer/react_hook_form/constants/data';
import { Field } from 'core/components/Field';
import { useRegistrationMutationCompleteUserRegistration } from '../../react_query/hooks/useMutationCompleteUserRegistration.registration';

export const AddPersonalInformationInvitedRegistration = () => {
  const {
    watch,
    formState: { errors },
    setValue,
    setError,
    clearErrors
  } = useFormContext<RegistrationForm>();
  const { mutate: updateInvitedUser } = useRegistrationMutationCompleteUserRegistration();

  const handleChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(forms.personal_information_invited.first_name, e.currentTarget.value);
    if (!e.currentTarget.value.length) {
      setError(forms.personal_information_invited.first_name, {
        message: RegistrationData.workspace.create_workspace.forms.errors.required
      });
    } else {
      clearErrors(forms.personal_information_invited.first_name);
    }
  };
  const handleChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(forms.personal_information_invited.last_name, e.currentTarget.value);
    if (!e.currentTarget.value.length) {
      setError(forms.personal_information_invited.last_name, {
        message: RegistrationData.workspace.create_workspace.forms.errors.required
      });
    } else {
      clearErrors(forms.personal_information_invited.last_name);
    }
  };

  const handleChangePositionInCompany = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(forms.personal_information_invited.position_in_company, e.currentTarget.value);
    if (!e.currentTarget.value.length) {
      setError(forms.personal_information_invited.position_in_company, {
        message: RegistrationData.workspace.create_workspace.forms.errors.required
      });
    } else {
      clearErrors(forms.personal_information_invited.position_in_company);
    }
  };

  const handleChangeContactNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(forms.personal_information_invited.contact_number, e.currentTarget.value);
    if (!e.currentTarget.value.length) {
      setError(forms.personal_information_invited.contact_number, {
        message: RegistrationData.workspace.create_workspace.forms.errors.required
      });
    } else {
      clearErrors(forms.personal_information_invited.contact_number);
    }
  };

  const handleChangeLinkedIn = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(forms.personal_information_invited.linkedin, e.currentTarget.value);
  };

  const handleClickGo = () => {
    updateInvitedUser();
  };

  const isButtonDisabled =
    !(watch(forms.personal_information_invited.first_name) as string).length ||
    !(watch(forms.personal_information_invited.last_name) as string).length ||
    !(watch(forms.personal_information_invited.position_in_company) as string).length ||
    !(watch(forms.personal_information_invited.contact_number) as string).length;
  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-items-center place-content-center gap-[2.5rem]',
        'w-[350px]'
      )}>
      <div
        className={clsx(
          'grid grid-cols-1 place-items-center place-content-center gap-[0.25rem]',
          'w-full'
        )}>
        <Typography variant={'Heading'} size={'H1'} color={'text-grayscale-90'}>
          {RegistrationData.invited.personal_information.title}
        </Typography>
        <Typography variant={'Regular'} size={'M'} color={'text-grayscale-70'}>
          {RegistrationData.invited.personal_information.message}
        </Typography>
      </div>

      {/* form */}
      <div
        className={clsx(
          'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[1.25rem]',
          'w-full'
        )}>
        {/* first name */}
        <Field
          key={RegistrationData.invited.personal_information.forms.first_name.name}
          mainLabel={RegistrationData.invited.personal_information.forms.first_name.mainLabel}
          name={forms.personal_information_invited.first_name}
          secondaryLabel={
            RegistrationData.invited.personal_information.forms.first_name.secondaryLabel
          }
          placeholder={RegistrationData.invited.personal_information.forms.first_name.placeholder}
          variant={!!errors[forms.personal_information_invited.first_name] ? 'error' : 'default'}
          value={watch(forms.personal_information_invited.first_name) as string}
          onChange={handleChangeFirstName}
          helperText={errors[forms.personal_information_invited.first_name]?.message as string}
        />
        {/* last name */}
        <Field
          key={RegistrationData.invited.personal_information.forms.last_name.name}
          mainLabel={RegistrationData.invited.personal_information.forms.last_name.mainLabel}
          name={forms.personal_information_invited.last_name}
          secondaryLabel={
            RegistrationData.invited.personal_information.forms.last_name.secondaryLabel
          }
          placeholder={RegistrationData.invited.personal_information.forms.last_name.placeholder}
          variant={!!errors[forms.personal_information_invited.last_name] ? 'error' : 'default'}
          value={watch(forms.personal_information_invited.last_name) as string}
          onChange={handleChangeLastName}
          helperText={errors[forms.personal_information_invited.last_name]?.message as string}
        />
        {/* position in company */}
        <Field
          key={RegistrationData.invited.personal_information.forms.position_in_company.name}
          mainLabel={
            RegistrationData.invited.personal_information.forms.position_in_company.mainLabel
          }
          name={forms.personal_information_invited.position_in_company}
          secondaryLabel={
            RegistrationData.invited.personal_information.forms.position_in_company.secondaryLabel
          }
          placeholder={
            RegistrationData.invited.personal_information.forms.position_in_company.placeholder
          }
          variant={
            !!errors[forms.personal_information_invited.position_in_company] ? 'error' : 'default'
          }
          value={watch(forms.personal_information_invited.position_in_company) as string}
          onChange={handleChangePositionInCompany}
          helperText={
            errors[forms.personal_information_invited.position_in_company]?.message as string
          }
        />

        {/* contact_number */}
        <Field
          key={RegistrationData.invited.personal_information.forms.contact_number.name}
          mainLabel={RegistrationData.invited.personal_information.forms.contact_number.mainLabel}
          name={forms.personal_information_invited.contact_number}
          secondaryLabel={
            RegistrationData.invited.personal_information.forms.contact_number.secondaryLabel
          }
          placeholder={
            RegistrationData.invited.personal_information.forms.contact_number.placeholder
          }
          variant={
            !!errors[forms.personal_information_invited.contact_number] ? 'error' : 'default'
          }
          value={watch(forms.personal_information_invited.contact_number) as string}
          onChange={handleChangeContactNumber}
          helperText={errors[forms.personal_information_invited.contact_number]?.message as string}
        />

        {/* contact_number */}
        <Field
          key={RegistrationData.invited.personal_information.forms.linked_in.name}
          mainLabel={RegistrationData.invited.personal_information.forms.linked_in.mainLabel}
          name={forms.personal_information_invited.linkedin}
          secondaryLabel={
            RegistrationData.invited.personal_information.forms.linked_in.secondaryLabel
          }
          placeholder={RegistrationData.invited.personal_information.forms.linked_in.placeholder}
          variant={!!errors[forms.personal_information_invited.linkedin] ? 'error' : 'default'}
          value={watch(forms.personal_information_invited.linkedin) as string}
          onChange={handleChangeLinkedIn}
          helperText={errors[forms.personal_information_invited.linkedin]?.message as string}
        />

        <div
          className={clsx(
            'grid grid-cols-1 place-content-start place-items-start gap-[1rem]',
            'w-full'
          )}>
          <Button disabled={isButtonDisabled} variant={'primary'} fullWidth onClick={handleClickGo}>
            {RegistrationData.invited.personal_information.forms.cta.primary.children}
          </Button>
        </div>
      </div>
      {/* end form */}
    </div>
  );
};
