import InvoicesData from '../../data/invoices.json';
import { forms } from './data';
import { BillingForm } from './type';

export const defaultValues: BillingForm = {
  // role
  [forms.role.name]: null,

  [forms.tabs.active]: {
    id: 'general',
    name: 'General'
  },
  [forms.tabs.data]: [],

  // general
  [forms.general.information]: null,
  [forms.general.invoices.data_table.data]: [],
  [forms.general.invoices.data_table.offset]: 0,

  // invoices
  [forms.invoices.data_table.all_selected]: false,
  [forms.invoices.data_table.services]: { id: 'ALL', name: 'All' } as { id: string; name: string },
  [forms.invoices.data_table.data]: [],
  [forms.invoices.data_table.list_download]: [],
  [forms.invoices.data_table.offset]: 0,

  // current_cycle
  [forms.current_cycle.information]: null,
  [forms.current_cycle.detail.data_table.data]: [],
  [forms.current_cycle.detail.data_table.offset]: 0,
  [forms.current_cycle.detail.data_table.services]: { id: 'ALL', name: 'All' } as {
    id: string;
    name: string;
  }
};
