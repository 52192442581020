import { useForm, FormProvider } from 'react-hook-form';
import { RegistrationContainer } from 'features/registration/customer/container';
import { defaultValues } from 'features/registration/customer/react_hook_form/constants';

export default function RegistrationPage() {
  const methods = useForm({
    defaultValues: defaultValues
  });

  return (
    <FormProvider {...methods}>
      <RegistrationContainer />
    </FormProvider>
  );
}
