import * as React from 'react';
import clsx from 'clsx';

export interface LogOutIconProps extends React.SVGAttributes<HTMLOrSVGElement> {
  iconSize?: 'XL' | 'L' | 'M' | 'S' | 'XS';
}

export const LogOutIcon = ({ iconSize, className, ...otherProps }: LogOutIconProps) => {
  const svgSizeClassName =
    iconSize === 'XL'
      ? 'w-[2.5rem] h-[2.5rem]'
      : iconSize === 'L'
      ? 'w-[2rem] h-[2rem]'
      : iconSize === 'M'
      ? 'w-[1.5rem] h-[1.5rem]'
      : iconSize === 'S'
      ? 'w-[1.25rem] h-[1.25rem]'
      : 'w-[1rem] h-[1rem]';
  return (
    <svg
      className={clsx(svgSizeClassName, className)}
      {...otherProps}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.8335 13.8334L3.16683 13.8334C2.81321 13.8334 2.47407 13.6929 2.22402 13.4428C1.97397 13.1928 1.8335 12.8537 1.8335 12.5L1.8335 3.16671C1.8335 2.81309 1.97397 2.47395 2.22402 2.2239C2.47407 1.97385 2.81321 1.83337 3.16683 1.83337L5.8335 1.83337"
        {...otherProps}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M11.8332 10.5026L14.1665 7.83598M11.8332 5.16931L14.1665 7.83598M14.1665 7.83598L7.49984 7.83598"
        {...otherProps}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
