import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import clsx from 'clsx';
import RegistrationPage from 'pages/Registration/Registration';
import { useForm, FormProvider } from 'react-hook-form';
import { CustomerHomeContainer } from 'features/home/customer/container/Customer.home';

export default function HomePage() {
  const { isLoading, isAuthenticated, error, user } = useAuth0();

  const methods = useForm();

  if (isLoading) {
    const loadingImg = 'https://cdn.auth0.com/blog/hello-auth0/loader.svg';
    return (
      <div className={clsx('flex items-center justify-center', 'w-full h-[100vh]')}>
        <img className={clsx('w-[120px] h-[120px]')} src={loadingImg} alt="Loading..." />
      </div>
    );
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isAuthenticated) {
    localStorage.setItem('auth0_user', JSON.stringify(user));

    return <RegistrationPage />;
  } else {
    localStorage.clear();
    //console.log(process.env);

    return (
      <FormProvider {...methods}>
        <CustomerHomeContainer />
      </FormProvider>
    );
  }
}
