import { FormProvider, useForm } from 'react-hook-form';
import { NotFoundContainer } from 'core/modules/not_found/container';

export default function PageNotFound() {
  const methods = useForm();
  return (
    <FormProvider {...methods}>
      <NotFoundContainer />
    </FormProvider>
  );
}
