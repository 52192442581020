import { forms } from './data';

export const state = [forms.state.is_loading] as const;
export const alert = [forms.alert.is_open, forms.alert.message] as const;

export const personal_information = [
  forms.personal_information.contact_number,
  forms.personal_information.linkedin,
  forms.personal_information.interest
] as const;
export const user = [forms.user.is_email_verified] as const;

export type ReviewerRegistrationFormDataKeys =
  | (typeof state)[number]
  | (typeof alert)[number]
  | (typeof personal_information)[number]
  | (typeof user)[number];

export type ReviewerRegistrationForm = Record<
  ReviewerRegistrationFormDataKeys,
  string | boolean | null | { id: string; name: string } | { id: string; name: string }[]
>;
