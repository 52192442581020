import * as React from 'react';
import { Tab } from 'core/components/Tab';
import { Tabs } from 'core/components/Tabs';
import { forms } from 'features/billing/react_hook_form/constants/data';
import { BillingForm } from 'features/billing/react_hook_form/constants/type';
import { useFormContext } from 'react-hook-form';

export const TabsBilling = () => {
  const { watch, setValue } = useFormContext<BillingForm>();
  const tabs = watch(forms.tabs.data) as { id: string; name: string }[];
  const activeTab = watch(forms.tabs.active) as null | { id: string; name: string };
  return (
    <Tabs>
      {tabs.map((tab, index) => (
        <Tab
          active={activeTab?.id === tab.id}
          key={index}
          onClick={() => setValue(forms.tabs.active, tab)}>
          {tab.name}
        </Tab>
      ))}
    </Tabs>
  );
};
