import axios from 'axios';
import { GetUserRequestInterface } from 'core/models/api/registration';

export const fetchGetUser = async (payload?: GetUserRequestInterface) => {
  const url = process.env.REACT_APP_GRAPHQL_API_URL || '';

  return await axios
    .post(url, payload?.data, {
      headers: {
        Authorization: `Bearer ${payload?.headers?.token}`
      }
    })
    .then((res) => {
      return res.data;
    });
};
