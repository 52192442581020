import * as React from 'react';
import clsx from 'clsx';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import BillingData from 'features/billing/data/invoices.json';
import { Typography } from 'core/components/Typography';
import { Pagination } from 'core/components/Pagination';
import { useFormContext } from 'react-hook-form';
import { HeaderCellDataTableBilling } from 'features/billing/components/HeaderCellDataTable';
import {
  BillingForm,
  DataTableInvoicesForm
} from 'features/billing/react_hook_form/constants/type';
import { forms } from 'features/billing/react_hook_form/constants/data';
import { Select } from 'core/components/Select';

export const CurrentCycleDataTableBilling = () => {
  const { watch, setValue } = useFormContext<BillingForm>();

  const invoices = watch(forms.current_cycle.detail.data_table.data) as DataTableInvoicesForm[];

  const columns = React.useMemo<ColumnDef<DataTableInvoicesForm>[]>(() => {
    return BillingData.current_cycle.detail.data_table.columns.map((item, index) => {
      return {
        accessorKey: item.id,
        header: () => {
          return (
            <HeaderCellDataTableBilling
              label={item.label.toLowerCase() === 'pay' ? 'Payment' : item.label}
            />
          );
        },
        cell: (cellProps) => {
          if (item.id === 'id') {
            return <span />;
          }

          if (item.id === 'pages') {
            return (
              <div className={clsx('flex items-center justify-end', 'w-full', 'px-[1rem]')}>
                <Typography variant={'Regular'} size={'XS'} color={'text-grayscale-70'}>
                  {cellProps.row.getValue(item.id)}
                </Typography>
              </div>
            );
          }

          return (
            <div
              className={clsx(
                'grid grid-cols-1 place-content-start place-items-start',
                'w-full',
                'px-[1rem]'
              )}>
              <Typography variant={'Regular'} size={'XS'} color={'text-grayscale-70'}>
                {cellProps.row.getValue(item.id)}
              </Typography>
            </div>
          );
        }
      };
    });
  }, [BillingData.data_table.columns]);

  const table = useReactTable({
    data: invoices,
    columns: columns,
    state: {
      pagination: {
        pageIndex: 0,
        pageSize: 20
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    paginateExpandedRows: false,
    autoResetPageIndex: false,
    debugTable: true
  });

  const LIMIT = 10;

  const offset = watch(forms.current_cycle.detail.data_table.offset) as number;

  const paginationLabel = `${offset + 1}-${offset + 10} of ${LIMIT} Invoices`;

  const handlePreviousPage = () => {
    setValue(
      forms.current_cycle.detail.data_table.offset,
      (watch(forms.current_cycle.detail.data_table.offset) as number) - 10
    );
  };
  const handleNextPage = () => {
    setValue(
      forms.current_cycle.detail.data_table.offset,
      (watch(forms.current_cycle.detail.data_table.offset) as number) + 1 * 10
    );
  };

  const handleFilterByService = (data: { id: string; name: string }) => {
    setValue(forms.current_cycle.detail.data_table.services, data);
  };
  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-content-start place-items-start gap-[1.5rem]',
        'w-full'
      )}>
      <div className={clsx('flex items-center justify-between', 'w-full')}>
        <Typography
          className="text-[1.0625rem]"
          variant={'Medium'}
          size={'L'}
          color={'text-grayscale-90'}>
          {BillingData.current_cycle.detail.title}
        </Typography>
        <div
          className={clsx(
            'grid grid-flow-col items-center content-center justify-end justify-items-end gap-[0.625rem]'
          )}>
          <Typography variant={'Button'} size={'S'} color={'text-grayscale-60'}>
            {BillingData.current_cycle.detail.data_table.actions.services}
          </Typography>
          <Select
            options={BillingData.current_cycle.detail.data_table.services}
            onSelect={handleFilterByService}
          />
        </div>
      </div>

      <div
        className={clsx(
          'grid grid-cols-1 place-content-start place-items-start gap-[1rem]',
          'w-full'
        )}>
        <table className={clsx('grid grid-cols-1 place-content-start place-items-start', 'w-full')}>
          <thead
            className={clsx(
              'grid grid-cols-1 place-content-start place-items-start',
              'bg-grayscale-20',
              'w-full'
            )}>
            {table !== null &&
              table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className={clsx(
                    'grid place-content-start place-items-start',
                    'w-full',
                    'px-[1rem]'
                  )}
                  style={{
                    gridTemplateColumns: `1fr 1fr 1fr 1fr 60px 300px`
                  }}>
                  {headerGroup.headers
                    .filter((header) => {
                      return header.id !== 'id';
                    })
                    .map((header) => {
                      return (
                        <th
                          className={clsx(
                            'grid grid-cols-1 place-content-start place-items-start',
                            'w-full'
                          )}
                          key={header.id}
                          colSpan={header.colSpan}>
                          <div
                            className={clsx(
                              'w-full',
                              'cursor-default select-none flex justify-left items-center',
                              'px-[1rem]'
                            )}>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </div>
                        </th>
                      );
                    })}
                </tr>
              ))}
          </thead>
          <tbody
            className={clsx(
              'grid grid-cols-1 place-content-start place-items-start',
              'w-full',
              // 'h-[calc(100vh_-_600px)]',
              'h-[20vh]',
              'overflow-auto'
            )}>
            {table !== null && table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row, idx) => (
                <tr
                  key={row.id}
                  role={'table-row'}
                  className={clsx(
                    'grid grid-cols-1 place-content-start place-items-start',
                    'w-full',
                    'px-[1rem]',
                    String(row.getValue('id')).length > 0 &&
                      'border-b border-b-grayscale-30 hover:bg-grayscale-20'
                  )}
                  style={{
                    gridTemplateColumns: `1fr 1fr 1fr 1fr 60px 300px`
                  }}>
                  {row
                    .getVisibleCells()
                    .filter((cell) => {
                      return !cell.id.includes('_id');
                    })
                    .map((cell) => (
                      <td key={cell.id} className={clsx('py-[0.8rem]', 'text-left', 'w-full')}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                </tr>
              ))
            ) : (
              <tr
                className={clsx(
                  'grid grid-cols-1 place-content-start place-items-start',
                  'w-full'
                )}>
                <td
                  colSpan={table?.getHeaderGroups()[0]?.headers?.length}
                  className={clsx(
                    'text-center border-b border-b-grayscale-30 hover:bg-grayscale-20',
                    'w-full'
                  )}>
                  <p className="text-grayscale-70 text-center py-4">No data available</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div
          className={clsx(
            'grid grid-flow-col items-center content-center justify-items-center justify-center',
            'w-[calc(100vw-216px)]',
            'fixed bottom-[1rem] left-[216px] right-[0px]'
          )}>
          <Pagination
            is_first_page={offset === 0}
            label={paginationLabel}
            onPreviousPage={handlePreviousPage}
            onNextPage={handleNextPage}
          />
        </div>
      </div>
    </div>
  );
};
