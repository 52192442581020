import * as React from 'react';
import clsx from 'clsx';
import { UploadIcon } from 'core/icons/Upload';
import { Typography } from '../Typography';
import { Button } from '../Button';

export interface UploadProps extends React.HTMLAttributes<HTMLInputElement> {
  message?: string;
  action?: string;
  onDragAndDrop?: () => void;
}

export const Upload = ({
  message = 'Drag and Drop your File',
  action = 'Select',
  onDragAndDrop,
  ...otherProps
}: UploadProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleClickUpload = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    inputRef?.current?.click();
  };

  return (
    <div
      className={clsx(
        'grid grid-flow-col items-center content-center justify-between justify-items-start gap-[1.5rem]',
        'w-full',
        'p-[1.5rem]',
        'rounded-[0.375rem]',
        'border border-dashed border-[rgba(76, 53, 222, 0.40)]'
      )}
      onDrop={onDragAndDrop}>
      <div
        className={clsx(
          'grid grid-flow-col items-center content-center justify-start justify-items-start',
          'gap-[1rem]',
          'w-full',
          'whitespace-nowrap'
        )}>
        <UploadIcon iconSize={'M'} className={clsx('stroke-cta-purple-primary')} />
        <Typography variant={'Button'} size={'M'} color={'text-cta-purple-primary'}>
          {message}
        </Typography>
      </div>

      <Button variant={'primary'} onClick={handleClickUpload}>
        {action}
      </Button>

      <input
        {...otherProps}
        ref={inputRef}
        type="file"
        id={'inputFile'}
        multiple={true}
        data-testid={'upload-input'}
        className={clsx('sr-only')}
      />
    </div>
  );
};
