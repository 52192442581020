import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  GetBillableItemsRequestInterface,
  GetBillableItemsResponseInterface
} from 'core/models/api/invoices';
import { fetchGetBillableItems } from 'core/services/invoices';
import { BillingReactQueryKey } from '../keys';
import { gql } from 'graphql-request';
import { useFormContext } from 'react-hook-form';
import { useMemo, useEffect } from 'react';
import {
  CurrentCycleDataTableInvoicesForm,
  BillingForm,
  forms
} from 'features/billing/react_hook_form/constants';

export const useCurrentCycleBillingQueryGetBillableItems = () => {
  const token = localStorage.getItem('token') ?? '';
  const { watch, setValue } = useFormContext<BillingForm>();
  const LIMIT = 10;
  const services = watch(forms.current_cycle.detail.data_table.services) as {
    id: string;
    name: string;
  };

  const payload: GetBillableItemsRequestInterface = useMemo(() => {
    const OFFSET = watch(forms.current_cycle.detail.data_table.offset) as number;

    return {
      headers: {
        token: token
      },
      data: {
        query: gql`
          query {
            billableItems(offset: ${OFFSET}, limit: ${LIMIT}${
              services.id !== 'ALL' ? `, service: "${services.id}"` : ''
            }) {
              id
              createdAt
              service
              action
              name
              id
              quantity
            }
          }
        `
      }
    };
  }, [
    watch(forms.current_cycle.detail.data_table.offset) as number,
    token,
    LIMIT,
    watch(forms.current_cycle.detail.data_table.services) as { id: string; name: string }
  ]);

  const query = useQuery<GetBillableItemsResponseInterface | undefined, AxiosError>(
    BillingReactQueryKey.QueryCurrentCycleGetBillableItems(payload),
    () => {
      return fetchGetBillableItems(payload);
    },
    {
      retry: 0
    }
  );

  useEffect(() => {
    if (query.data?.data.billableItems) {
      const tableData: CurrentCycleDataTableInvoicesForm[] =
        query?.data?.data?.billableItems?.map((item) => {
          return {
            id: item.id,
            date: new Date(item.createdAt).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            }),
            used_services: item.service,
            job: item.action,
            job_name: item.name,
            job_number: item.id,
            pages: item.quantity.toLocaleString('en-US', {
              maximumFractionDigits: 0
            })
          };
        }) || [];
      setValue(forms.current_cycle.detail.data_table.data, tableData as any[]);
    }
  }, [query.data]);

  return query;
};
