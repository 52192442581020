export const forms = {
  general: {
    id: 'id-general-registration',
    organization_id: 'organization_id-general-registration'
  },
  state: {
    is_loading: 'is_loading-state-registration',
    step: 'step-state-registration',
    create_workspace_step: 'create_workspace_step-state-registration'
  },
  create_workspace: {
    company_name: 'company_name-create_workspace-registration',
    city: 'city-create_workspace-registration',
    state_region: 'state_region-create_workspace-registration',
    country: 'country-create_workspace-registration',
    zip_code: 'zip_code-create_workspace-registration',
    address_line_1: 'address_line_1-create_workspace-registration',
    address_line_2: 'address_line_2-create_workspace-registration',
    billing_email: 'billing_email-create_workspace-registration',
    contact_number: 'contact_number-create_workspace-registration'
  },
  join_workspace: {
    id: 'id-join_workspace-registration'
  },
  create_account: {
    first_name: 'first_name-create_account-registration',
    last_name: 'last_name-create_account-registration',
    email: 'email-create_account-registration'
  },
  add_team_members: {
    team_members: 'team_members-add_team_members-registration',
    alert: 'alert-add_team_members-registration',
    alert_message: 'alert_message-add_team_members-registration'
  },
  add_more_information: {
    industry: 'industry-add_more_information-registration',
    team_size: 'team_size-add_more_information-registration',
    about: 'about-add_more_information-registration'
  },
  personal_information_invited: {
    first_name: 'first_name-personal_information_invited-registration',
    last_name: 'last_name-personal_information_invited-registration',
    position_in_company: 'position_in_company-personal_information_invited-registration',
    contact_number: 'contact_number-personal_information_invited-registration',
    linkedin: 'linkedin-personal_information_invited-registration'
  },
  user: {
    is_email_verified: 'is_email_verified-user-registration'
  }
} as const;

export const formErrors = {
  // create_workspace
  [`${forms.create_workspace.company_name}`]: {
    type: 'custom',
    message: 'required'
  },
  [`${forms.create_workspace.city}`]: {
    type: 'custom',
    message: 'required'
  },
  [`${forms.create_workspace.state_region}`]: {
    type: 'custom',
    message: 'required'
  },
  [`${forms.create_workspace.zip_code}`]: {
    type: 'custom',
    message: 'required'
  },
  [`${forms.create_workspace.contact_number}`]: {
    type: 'custom',
    message: 'required'
  },
  // join workspace
  [`${forms.join_workspace.id}`]: {
    type: 'custom',
    message: 'required'
  },
  // create account
  [`${forms.create_account.first_name}`]: {
    type: 'custom',
    message: 'required'
  },
  [`${forms.create_account.last_name}`]: {
    type: 'custom',
    message: 'required'
  },
  [`${forms.create_account.email}`]: {
    type: 'custom',
    message: 'required'
  },
  // add team members
  [`${forms.add_team_members.team_members}`]: {
    type: 'custom',
    message: 'required'
  },
  // add more information
  [`${forms.add_more_information.industry}`]: {
    type: 'custom',
    message: 'required'
  },
  [`${forms.add_more_information.team_size}`]: {
    type: 'custom',
    message: 'required'
  },
  [`${forms.add_more_information.about}`]: {
    type: 'custom',
    message: 'required'
  }
};
