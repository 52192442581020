import clsx from 'clsx';
import { Typography } from '../../../../core/components/Typography';
import { Select } from '../../../../core/components/Select/Select';
import UsersData from 'features/users/data/users.json';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { useFormContext } from 'react-hook-form';
import { UsersForm } from 'features/users/react_hook_form/constants/type';
import { forms } from 'features/users/react_hook_form/constants/data';
import { Field } from 'core/components/Field';

export const DataTableActionsUsers = () => {
  const { watch, setValue } = useFormContext<UsersForm>();

  const handleSort = (data: { id: string; name: string }) => {
    setValue(forms.data_table.sort_by, data);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(forms.data_table.search, e.currentTarget.value);
  };

  return (
    <div className={clsx('flex items-center justify-between', 'w-full', 'mb-4')}>
      <div
        className={clsx(
          'grid grid-cols-1 items-center content-center justify-start justify-items-start',
          'w-[400px]'
        )}>
        <Field
          value={watch(forms.data_table.search) as string}
          placeholder={UsersData.data_table.search.placeholder}
          fieldSize={'S'}
          endIcon={
            <MagnifyingGlassIcon className={clsx('w-[1rem] h-[1rem]', 'text-grayscale-60')} />
          }
          onChange={handleSearch}
        />
      </div>

      <div
        className={clsx(
          'grid grid-flow-col items-center content-center justify-end justify-items-end gap-[0.625rem]'
        )}>
        <div
          className={clsx(
            'grid grid-flow-col items-center content-center justify-end justify-items-end gap-[0.625rem]'
          )}>
          <Typography variant={'Button'} size={'S'} color={'text-grayscale-60'}>
            {UsersData.data_table.actions.sort_by}
          </Typography>
          <Select options={UsersData.data_table.sort_by} onSelect={handleSort} />
        </div>
      </div>
    </div>
  );
};
