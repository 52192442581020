import clsx from 'clsx';
import { Checkbox } from '../../../../core/components/Checkbox/Checkbox';
import { Typography } from '../../../../core/components/Typography';
import { Select } from '../../../../core/components/Select/Select';
import InvoicesData from 'features/billing/data/invoices.json';
import { TextButton } from 'core/components/TextButton';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { useFormContext } from 'react-hook-form';
import { DataTableInvoicesForm } from 'features/billing/react_hook_form/constants/type';
import { BillingForm } from 'features/billing/react_hook_form/constants/type';
import { forms } from 'features/billing/react_hook_form/constants/data';

export const InvoicesDataTableActionsBilling = () => {
  const { watch, setValue } = useFormContext<BillingForm>();

  const invoices = watch(forms.invoices.data_table.data) as DataTableInvoicesForm[];
  const handleClickListDownloadPDF = () => {
    //
  };

  const handleSelectAll = () => {
    setValue(
      forms.invoices.data_table.all_selected,
      !watch(forms.invoices.data_table.all_selected)
    );

    const payload =
      (watch(forms.invoices.data_table.list_download) as string[]).length ===
      invoices.filter((item: DataTableInvoicesForm) => String(item.id).length > 0).length
        ? []
        : invoices
            ?.filter((item: DataTableInvoicesForm) => String(item.id).length > 0)
            .map((invoice: DataTableInvoicesForm) => invoice.id);
    setValue(forms.invoices.data_table.list_download, payload);
  };

  const isAllInvoiceSelected = watch(forms.invoices.data_table.all_selected) as boolean;

  const isInvoiceListDataSelected =
    (watch(forms.invoices.data_table.list_download) as DataTableInvoicesForm[]).length > 0;

  const handleFilterByService = (data: { id: string; name: string }) => {
    setValue(forms.invoices.data_table.services, data);
  };

  return (
    <div className={clsx('flex items-center justify-between', 'w-full', 'mb-4')}>
      <div
        className={clsx(
          'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[1.5rem]'
        )}>
        <div
          className={clsx(
            'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.75rem]',
            'px-[0.375rem]'
          )}>
          <Checkbox
            data-testid={'list-download-checkbox'}
            placeholder={'list-download-checkbox'}
            checked={isAllInvoiceSelected}
            defaultChecked={false}
            onChange={handleSelectAll}
          />
          <Typography variant={'Button'} size={'S'} color={'text-grayscale-80'}>
            {InvoicesData.data_table.actions.select_all}
          </Typography>
        </div>

        {isInvoiceListDataSelected && (
          <TextButton
            placeholder={InvoicesData.data_table.actions.list_download}
            rightIcon={
              <ArrowDownTrayIcon className={clsx('text-grayscale-80', 'w-[1rem] h-[1rem]')} />
            }
            onClick={handleClickListDownloadPDF}>
            <Typography variant={'Button'} size={'S'} color={'text-grayscale-80'}>
              {InvoicesData.data_table.actions.list_download}
            </Typography>
          </TextButton>
        )}
      </div>

      <div
        className={clsx(
          'grid grid-flow-col items-center content-center justify-end justify-items-end gap-[0.625rem]'
        )}>
        <div
          className={clsx(
            'grid grid-flow-col items-center content-center justify-end justify-items-end gap-[0.625rem]'
          )}>
          <Typography variant={'Button'} size={'S'} color={'text-grayscale-60'}>
            {InvoicesData.data_table.actions.services}
          </Typography>
          <Select options={InvoicesData.data_table.services} onSelect={handleFilterByService} />
        </div>
      </div>
    </div>
  );
};
