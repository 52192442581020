export const forms = {
  role: {
    name: 'name-role-invoices'
  },

  data_table: {
    data: 'data-data_table-users',
    search: 'search-data_table-users',
    offset: 'offset-data_table-users',
    total_data: 'total_data-data_table-users',
    sort_by: 'sort_by-data_table-users'
  },
  user_invitation: {
    is_open: 'is_open-user_invitation-users',
    email: 'email-user_invitation-users'
  }
} as const;
