export const RegistrationReactQueryKey = {
  GetAccessTokenSilently: () => ['RegistrationReactQueryKey.GetAccessTokenSilently'],
  GetIdTokenClaims: () => ['RegistrationReactQueryKey.GetIdTokenClaims'],
  QueryGetUser: () => ['RegistrationReactQueryKey.QueryGetUser'],
  QueryGetCurrentUser: () => ['RegistrationReactQueryKey.QueryGetCurrentUser'],
  MutationCreateUser: () => ['RegistrationReactQueryKey.MutationCreateUser'],
  MutationCreateOrganization: () => ['RegistrationReactQueryKey.MutationCreateOrganization'],
  MutationUpdateUser: () => ['RegistrationReactQueryKey.MutationUpdateUser'],
  QueryGetOrganizationExist: () => ['RegistrationReactQueryKey.QueryGetOrganizationExist'],
  MutationInviteUsers: () => ['RegistrationReactQueryKey.MutationInviteUsers']
};
