import * as React from 'react';
import clsx from 'clsx';
import ReviewerHomeData from 'features/home/reviewer/data/reviewer.home.json';
import { Typography } from 'core/components/Typography';

export const EnterpriseSecurityReviewerHome = () => {
  return (
    <div
      className={clsx(
        'grid grid-cols-1 items-start content-start justify-center justify-items-center',
        'w-full',
        'px-[4rem] py-[9rem]',
        'bg-cta-mint-primary',
        'rounded-[1rem]'
      )}>
      <div
        className={clsx(
          'grid grid-cols-1 lg:grid-cols-2 place-content-start place-items-start gap-[6rem]',
          'w-full max-w-[1200px]'
        )}>
        <img
          src={ReviewerHomeData.enterprise_security.image}
          className={clsx('w-[518px]')}
          alt={ReviewerHomeData.enterprise_security.title}
        />

        <div
          className={clsx(
            'grid grid-cols-1 items-center content-center justify-start justify-items-start gap-[1.5rem]',
            'w-full max-w-[348px] h-full'
          )}>
          <div
            className={clsx(
              'grid grid-cols-1 place-content-start place-items-start gap-[1rem]',
              'w-full'
            )}>
            <Typography variant={'Medium'} size={'XS'} color={'text-cta-purple-primary'}>
              {ReviewerHomeData.enterprise_security.label}
            </Typography>
            <h3
              className={clsx(
                'text-[3rem] text-grayscale-90 font-eliza font-medium leading-[112%] tracking-[-0.48px]'
              )}
              dangerouslySetInnerHTML={{ __html: ReviewerHomeData.enterprise_security.title }}
            />
          </div>
          <Typography variant={'Regular'} size={'S'} color={'text-grayscale-70'}>
            {ReviewerHomeData.enterprise_security.description}
          </Typography>
        </div>
      </div>
    </div>
  );
};
