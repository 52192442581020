import { UsersContainer } from 'features/users/container';
import { useForm, FormProvider } from 'react-hook-form';
import { defaultValues } from 'features/users/react_hook_form/constants/default';
import { withAuth } from 'core/utils/hooks/withAuth';

function UsersPage() {
  const methods = useForm({
    defaultValues: defaultValues
  });

  return (
    <FormProvider {...methods}>
      <UsersContainer />
    </FormProvider>
  );
}

export default withAuth(UsersPage);
