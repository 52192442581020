import * as React from 'react';
import clsx from 'clsx';
import { Checkbox } from 'core/components/Checkbox';
import { Typography } from 'core/components/Typography';

export interface CreatedDateCellDataTableBillingProps {
  id?: string;
  checked?: boolean;
  label?: string;
  onSelect?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CreatedDateCellDataTableBilling = ({
  id = '',
  checked = false,
  label = '',
  onSelect
}: CreatedDateCellDataTableBillingProps) => {
  return (
    <div
      className={clsx(
        'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.875rem]',
        'w-full'
      )}>
      <Checkbox id={id} name={id} checked={checked} onChange={onSelect} />
      <Typography variant={'Regular'} size={'XS'} color={'text-grayscale-70'}>
        {label}
      </Typography>
    </div>
  );
};
