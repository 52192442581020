import * as React from 'react';
import clsx from 'clsx';

export interface AlertProps {
  message?: string;
  isOpen?: boolean;
}

export const Alert = ({ message = '', isOpen = false }: AlertProps) => {
  return (
    <div
      id="error-message-container"
      className={clsx(
        'fixed top-[1rem] left-[50%] translate-x-[-50%] bg-[#EE0B0B]  grid-flow-col items-start content-start justify-start justify-items-start px-[1.5rem] py-[0.75rem] gap-[0.75rem] rounded-[0.25rem]',
        isOpen ? 'grid' : 'hidden'
      )}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_180_2496)">
          <path
            d="M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 5.33331V7.99998"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            d="M8 10.6667H8.00667"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_180_2496">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <p
        id="error-message"
        className="text-[white] text-[0.875rem] font-medium leading-[146%] tracking-[-0.14px]">
        {message}
      </p>
    </div>
  );
};
