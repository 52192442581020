import AccountPage from '../../../pages/Account/Account';
import InvoicesPage from '../../../pages/Billing/Billing';
import UsersPage from '../../../pages/Users/Users';
export const privateRoutes = [
  {
    path: () => `/account`,
    element: <AccountPage />
  },
  {
    path: () => `/invoices`,
    element: <InvoicesPage />
  },
  {
    path: () => `/users`,
    element: <UsersPage />
  }
];
