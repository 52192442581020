import * as React from 'react';
import clsx from 'clsx';

export interface RotateIconProps extends React.SVGAttributes<HTMLOrSVGElement> {
  iconSize?: 'XL' | 'L' | 'M' | 'S' | 'XS';
}

export const RotateIcon = ({ iconSize, className, ...otherProps }: RotateIconProps) => {
  const svgSizeClassName =
    iconSize === 'XL'
      ? 'w-[2.5rem] h-[2.5rem]'
      : iconSize === 'L'
      ? 'w-[2rem] h-[2rem]'
      : iconSize === 'M'
      ? 'w-[1.5rem] h-[1.5rem]'
      : iconSize === 'S'
      ? 'w-[1.25rem] h-[1.25rem]'
      : 'w-[1rem] h-[1rem]';
  return (
    <svg
      className={clsx(svgSizeClassName, className)}
      {...otherProps}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.00244 1.54358V5.54358M2.00244 5.54358H6.00244M2.00244 5.54358C3.89329 3.76298 4.66244 2.3664 6.81714 2.06077C8.10516 1.87808 9.41766 2.11895 10.5569 2.7471C11.6961 3.37525 12.6003 4.35664 13.1332 5.54339C13.6661 6.73014 13.7989 8.05794 13.5115 9.32671C13.2242 10.5955 12.5322 11.7365 11.5399 12.5778C10.5477 13.4191 9.30885 13.9151 8.01016 13.9911C6.71147 14.067 5.42327 13.7189 4.33967 12.999C3.25608 12.2792 2.4358 11.2266 2.00244 10"
        {...otherProps}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
