import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { UsersReactQueryKey } from '../keys';
import { gql } from 'graphql-request';
import { useFormContext } from 'react-hook-form';
import { DataTableForm } from 'features/users/react_hook_form/constants/type';
import { useEffect, useMemo } from 'react';
import { UsersForm, forms } from 'features/users/react_hook_form/constants';
import { fetchGetUsers } from 'core/services/registration';
import {
  GetUsersRequestInterface,
  GetUsersSuccessResponseInterface
} from 'core/models/api/registration';

export const useUsersQueryGetUsers = () => {
  const token = localStorage.getItem('token') ?? '';
  const { watch, setValue } = useFormContext<UsersForm>();
  const LIMIT = 10;

  const payload: GetUsersRequestInterface = useMemo(() => {
    const OFFSET = watch(forms.data_table.offset) as number;

    return {
      headers: {
        token: token
      },
      data: {
        query: gql`
          query {
            users(${
              !(watch(forms.data_table.search) as string).length
                ? 'search:null'
                : `search:"${watch(forms.data_table.search)}"`
            },
            limit:${LIMIT},
            offset:${OFFSET},
            sort:"${(watch(forms.data_table.sort_by) as { id: string; name: string }).id}"
          ) {
              limit
              offset
              total
              users{
                email
                firstName
                lastName
                roles
                status
                createdAt
              }
            }
          }
        `
      }
    };
  }, [
    watch(forms.data_table.sort_by) as { id: string; name: string },
    watch(forms.data_table.search) as string,
    watch(forms.data_table.offset) as number,
    token,
    LIMIT,
    watch(forms.data_table.sort_by) as { id: string; name: string }
  ]);

  const query = useQuery<GetUsersSuccessResponseInterface | undefined, AxiosError>(
    UsersReactQueryKey.QueryUsersGetUsers(payload),
    () => {
      return fetchGetUsers(payload);
    },
    {
      retry: 0
    }
  );

  useEffect(() => {
    if (query.data?.data.users) {
      const tableData: DataTableForm[] =
        query?.data?.data?.users.users?.map((user) => {
          return {
            id: user.id,
            name:
              user.status === 'invited'
                ? 'Waiting for acceptance'
                : `${user.firstName} ${user.lastName}`,
            email: user.email,
            date_of_sign_up:
              user.status !== 'active'
                ? user.status
                : `${new Date(user.createdAt).toLocaleDateString('en-US', {
                    month: 'short'
                  })},${new Date(user.createdAt).toLocaleDateString('en-US', {
                    day: 'numeric'
                  })} ${new Date(user.createdAt).toLocaleDateString('en-US', { year: 'numeric' })}`,
            role: user.roles.toString(),
            actions: ''
          };
        }) || [];

      setValue(forms.data_table.data, tableData as DataTableForm[]);
      setValue(forms.data_table.total_data, query.data.data.users.total ?? 0);
    }
  }, [query.data]);

  return query;
};
