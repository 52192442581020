import * as React from 'react';
import clsx from 'clsx';
import { Typography } from 'core/components/Typography';
import { TypographyColorProps } from 'core/components/Typography/Typography.types';

export interface IEmailCellDataTableUsersProps {
  value?: string;
  color?: TypographyColorProps;
}

export const EmailCellDataTableUsers = ({
  value = '',
  color = 'text-grayscale-80'
}: IEmailCellDataTableUsersProps) => {
  return (
    <div
      className={clsx(
        'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.75rem]',
        'w-full h-full'
      )}>
      <Typography
        variant={'Regular'}
        size={'XS'}
        color={color}
        className={clsx('text-left truncate')}>
        {value}
      </Typography>
    </div>
  );
};
