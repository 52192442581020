import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { RegistrationReactQueryKey } from '../keys';
import { IdToken } from '@auth0/auth0-react';
import {
  GetUserRequestInterface,
  GetUserSuccessResponseInterface
} from 'core/models/api/registration';
import { useFormContext } from 'react-hook-form';
import { ReviewerRegistrationForm } from 'features/registration/reviewer/react_hook_form/constants/type';
import { forms } from 'features/registration/reviewer/react_hook_form/constants/data';
import { fetchGetUser } from 'core/services/registration';

export const useReviewerRegistrationMutationGetUserCustomer = () => {
  const { setValue } = useFormContext<ReviewerRegistrationForm>();
  const token = localStorage.getItem('token') ?? '';

  const mutation = useMutation<GetUserSuccessResponseInterface, AxiosError>(
    RegistrationReactQueryKey.QueryGetUser(),
    () => {
      const payload: GetUserRequestInterface = {
        headers: {
          token: token
        },
        data: {
          query: gql`
            query {
              user {
                id
                email
                role
              }
            }
          `
        }
      };

      return fetchGetUser(payload);
    },
    {
      retry: 0,
      // enabled: !isLoadingAuth0 && !!auth0TokenData,
      onSuccess(data) {
        if (data.data.user !== null) {
          localStorage.setItem('userId', data.data.user.id);
          window.location.href = `${process.env.REACT_APP_AUTH0_REDIRECT_URI_CUSTOMER}`;
        } else {
          setValue(forms.state.is_loading, false);
        }
      },
      onError() {
        setValue(forms.state.is_loading, false);
      }
    }
  );

  return mutation;
};
