import axios from 'axios';

import { GetBillableItemsRequestInterface } from 'core/models/api/invoices';

export const fetchGetBillableItems = async (payload?: GetBillableItemsRequestInterface) => {
  const url = process.env.REACT_APP_GRAPHQL_API_URL || '';

  return await axios
    .post(url, payload?.data, {
      headers: {
        Authorization: `Bearer ${payload?.headers?.token}`
      }
    })
    .then((res) => {
      return res.data;
    });
};
