import * as React from 'react';
import clsx from 'clsx';
import { SidebarTypeProps } from './Sidebar.types';

export interface SidebarProps {
  type?: SidebarTypeProps;
  children?: React.ReactNode;
}

export const Sidebar = ({ type = 'Customer', children }: SidebarProps) => {
  return (
    <div
      className={clsx(
        'grid grid-cols-1 items-stretch content-between justify-start justify-items-start',
        'w-[216px] h-full h-[calc(100vh_-_70px)]',
        type === 'Customer' ? 'bg-grayscale-10' : 'bg-grayscale-30',
        type === 'Customer' ? 'border-r border-r-grayscale-30' : 'border-r border-r-grayscale-40'
      )}>
      {children}
    </div>
  );
};
