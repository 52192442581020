import * as React from 'react';
import clsx from 'clsx';
import { HeroCustomerHome } from '../fragments/hero';
import { NavbarCustomerHome } from '../fragments/navbar';
import { OutsourcePaperworkCustomerHome } from '../fragments/outsource_paperwork';
import { FooterCustomerHome } from '../fragments/footer';
import { TestimonialCustomerHome } from '../fragments/testimonial';

export const CustomerHomeContainer = () => {
  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-content-start place-items-start',
        'w-full',
        'px-[1rem] py-[1rem]'
      )}>
      <NavbarCustomerHome />
      <HeroCustomerHome />
      <TestimonialCustomerHome />
      <OutsourcePaperworkCustomerHome />
      <FooterCustomerHome />
    </div>
  );
};
