import { GetUserRequestInterface } from 'core/models/api/registration';

export const UsersReactQueryKey = {
  QueryGetUser: () => ['UsersReactQueryKey.QueryGetUser'],

  QueryUsersGetUsers: (payload?: GetUserRequestInterface) => [
    'UsersReactQueryKey.QueryUsersGetUsers',
    [payload] as const
  ],
  MutationInviteUsers: () => ['UsersReactQueryKey.MutationInviteUsers'],
  MutationUpdateUserStatus: () => ['UsersReactQueryKey.MutationUpdateUsersRoles'],
  MutationUpdateUserRoles: () => ['UsersReactQueryKey.MutationUpdateUserRoles']
};
