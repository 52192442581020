import * as React from 'react';
import clsx from 'clsx';
import { Avatar } from 'core/components/Avatar';
import { Typography } from 'core/components/Typography';
import { stringToHslColor } from 'core/utils/color';

export interface AccountDashboardProps {
  image?: string;
  name?: string;
  email?: string;
  initial?: string;
}

export const AccountDashboard = ({
  image = '',
  name = '',
  email = '',
  initial = ''
}: AccountDashboardProps) => {
  return (
    <div
      className={clsx(
        'grid grid-flow-col items-center content-center justify-end justify-items-end gap-[0.75rem]'
      )}>
      <Avatar
        variant={'user-placeholder'}
        size={'S'}
        backgroundColor={stringToHslColor(name, 30, 80)}>
        {initial}
      </Avatar>
      {/* <Avatar src={image} variant={'user-photo'} size={'S'} /> */}
      <div className={clsx('flex flex-col justify-between overflow-hidden w-28')}>
        <Typography
          variant={'Medium'}
          size={'S'}
          color={'text-grayscale-80'}
          className={clsx('text-left truncate')}>
          {name}
        </Typography>
        <Typography
          variant={'Regular'}
          size={'XXS'}
          color={'text-grayscale-70'}
          className={clsx('text-left truncate')}>
          {email}
        </Typography>
      </div>
    </div>
  );
};
