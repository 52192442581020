import { useState } from 'react';
import clsx from 'clsx';

export interface TabProps {
  active?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}

export const Tab = ({ children, active = false, onClick }: TabProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleMouseOver = () => {
    setIsHovered(() => true);
  };
  const handleMouseLeave = () => {
    setIsHovered(() => false);
  };
  return (
    <button
      className={clsx(
        'flex items-center justify-center',
        'p-[0.5rem]',
        'relative',
        'h-[2.375rem]',
        'box-border',
        'text-[0.875rem] font-medium leading-[146%] tracking-[-0.14px]',
        active ? 'text-cta-purple-primary' : isHovered ? 'text-grayscale-70' : 'text-grayscale-60'
      )}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}>
      {children}
      {(isHovered || active) && (
        <div
          className={clsx(
            'absolute',
            'bottom-0 left-0 right-0',
            'w-full',
            'h-[0.125rem]',
            active && 'bg-cta-purple-primary',
            !active && isHovered && 'bg-grayscale-70'
          )}
        />
      )}
    </button>
  );
};
