import * as React from 'react';
import clsx from 'clsx';
import { Backdrop } from '../Backdrop';

export interface PopUpProps {
  isOpen?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

export const PopUp = ({ isOpen, onClose, children }: PopUpProps) => {
  React.useEffect(() => {
    if (isOpen) {
      document.body.style.height = '100vh';
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.height = '100%';
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);
  return (
    <>
      {isOpen && (
        <>
          <Backdrop onClick={onClose}></Backdrop>
          <div
            className={clsx(
              'fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]',
              'z-50'
            )}>
            {children}
          </div>
        </>
      )}
    </>
  );
};
