import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { RegistrationReactQueryKey } from '../keys';
import { IdToken, useAuth0 } from '@auth0/auth0-react';
import { fetchGetUser } from 'core/services/registration';
import {
  GetUserRequestInterface,
  GetUserSuccessResponseInterface
} from 'core/models/api/registration';
import { useFormContext } from 'react-hook-form';
import RegistrationData from 'features/registration/customer/data/registration.json';
import { RegistrationForm } from 'features/registration/customer/react_hook_form/constants/type';
import { forms } from 'features/registration/customer/react_hook_form/constants/data';
import { useRegistrationMutationGetCurrentUser } from './useQueryGetCurrentUser.registration';

export const useRegistrationMutationGetUser = () => {
  const { user, logout } = useAuth0();
  const { setValue } = useFormContext<RegistrationForm>();
  const token = localStorage.getItem('token') ?? '';
  const { mutate: getCurrentUser } = useRegistrationMutationGetCurrentUser();

  const mutation = useMutation<GetUserSuccessResponseInterface, AxiosError, IdToken>(
    RegistrationReactQueryKey.QueryGetUser(),
    () => {
      const payload: GetUserRequestInterface = {
        headers: {
          token: token
        },
        data: {
          query: gql`
            query {
              user(email: "${user?.email || ''}") {
                id,
                email,
                status,
                organization{
                  id
                }
              }
            }
          `
        }
      };

      return fetchGetUser(payload);
    },
    {
      retry: 0,
      // enabled: !isLoadingAuth0 && !!auth0TokenData,
      onSuccess(data) {
        if (data.data.user !== null) {
          localStorage.setItem('userId', data.data.user.id);

          if (data.data.user.organization !== null) {
            localStorage.setItem('organizationId', data.data.user.organization?.id ?? '');
          }
          if (data.data.user.status === 'invited') {
            setValue(forms.state.is_loading, false);
            setValue(forms.state.step, 'invited');
          } else if (data.data.user.status === 'active') {
            if (user?.email_verified) {
              window.location.href = `${process.env.REACT_APP_REDIRECT_CUSTOMER_LOGIN_LINK}` || '';
            } else {
              setValue(forms.state.is_loading, false);
              setValue(forms.user.is_email_verified, false);
            }
          } else {
            alert("Sorry, you're not a reviewer. Please sign in as a customer.");
            logout({
              logoutParams: {
                returnTo: window.location.origin
              }
            });
            window.location.href = window.location.origin;
          }
        } else {
          getCurrentUser();
        }
      },
      onError() {
        setValue(forms.state.is_loading, false);
      }
    }
  );

  return mutation;
};
