export const forms = {
  role: {
    name: 'name-role-invoices'
  },
  tabs: {
    active: 'active-tab-invoices',
    data: 'data-tab-invoices'
  },

  general: {
    information: 'information-general-billing',
    invoices: {
      data_table: {
        data: 'data-data_table-invoices-general-billing',
        offset: 'offset-data_table-invoices-general-billing'
      }
    }
  },

  current_cycle: {
    information: 'estimated-usage',
    detail: {
      data_table: {
        data: 'data-data_table-detail-current_cycle-invoices',
        offset: 'offset-data_table-detail-current_cycle-billing',
        services: 'services-data_table-detail-current_cycle-billing'
      }
    }
  },

  invoices: {
    data_table: {
      data: 'data-data_table-invoices-billing',
      offset: 'offset-data_table-invoices-billing',
      all_selected: 'all_selected-data_table-invoices-billing',
      list_download: 'list_download-data_table-invoices-billing',
      services: 'services-data_table-invoices-billing'
    }
  }
} as const;
