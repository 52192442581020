import axios from 'axios';
import { PostUpdateUserRequestInterface } from 'core/models/api/registration';

export const fetchPostUpdateUser = async (payload: PostUpdateUserRequestInterface) => {
  const url = process.env.REACT_APP_GRAPHQL_API_URL || '';

  return await axios
    .post(url, payload.data, {
      headers: {
        Authorization: `Bearer ${payload.headers?.token || ''}`
      }
    })
    .then((res) => {
      return res.data;
    });
};
