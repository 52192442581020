import axios from 'axios';
import { GetCurrentUserRequestInterface } from 'core/models/api/registration';

export const fetchGetCurrentUser = async (payload?: GetCurrentUserRequestInterface) => {
  const url = process.env.REACT_APP_GRAPHENE_BACKEND_SERVICE_URL || '';

  return await axios
    .post(url, payload?.data, {
      headers: {
        Authorization: `Bearer ${payload?.headers?.token}`
      }
    })
    .then((res) => {
      return res.data;
    });
};
