import { useForm, FormProvider } from 'react-hook-form';
import { AccountForm } from 'features/account/react_hook_form/constants/type';
import { defaultValues } from 'features/account/react_hook_form/constants/default';
import { withAuth } from 'core/utils/hooks/withAuth';
import { lazy } from 'react';

const AccountContainer = lazy(async () => import('features/account/container'))


function AccountPage() {
  const methods = useForm<AccountForm>({
    defaultValues: {
      ...defaultValues
    }
  });

  return (
    <FormProvider {...methods}>
      <AccountContainer />
    </FormProvider>
  );
}

export default withAuth(AccountPage);