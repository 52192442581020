import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Typography } from 'core/components/Typography';
import ReviewerRegistrationData from 'features/registration/reviewer/data/reviewer.registration.json';
import { useAuth0 } from '@auth0/auth0-react';
import { TextButton } from 'core/components/TextButton';
import { LogOutIcon } from 'core/icons/LogOut';

export const ErrorReviewerRegistration = () => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const handleClickLogout = () => {
    logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_REDIRECT_LOGOUT_LINK
      }
    });
    localStorage.clear();
    navigate('/');
  };
  return (
    <div
      className={clsx(
        'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[2.5rem]',
        'w-full max-w-[465px]'
      )}>
      <div
        className={clsx(
          'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[0.5rem]',
          'w-full'
        )}>
        <Typography
          variant={'Heading'}
          size={'H1'}
          color={'text-grayscale-90'}
          className={'text-center'}>
          {ReviewerRegistrationData.error.message}
        </Typography>
        <Typography
          variant={'Regular'}
          size={'S'}
          color={'text-grayscale-70'}
          className={'text-center'}>
          {ReviewerRegistrationData.error.description}
        </Typography>
      </div>

      <div
        className={clsx(
          'grid grid-flow-col item-start content-start justify-center justify-items-center gap-[2.5rem]'
        )}>
        <TextButton
          leftIcon={<LogOutIcon iconSize={'XS'} className={clsx('stroke-grayscale-90')} />}
          onClick={handleClickLogout}>
          {ReviewerRegistrationData.error.actions.logout}
        </TextButton>
        <a
          href={'https://www.aaico.com/contact-us'}
          className={clsx(
            'py-[0.125rem]',
            'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.5rem]',
            'bg-transparent',
            'text-[0.875rem] font-medium leading-[146%] tracking-[-0.14px]',
            'text-grayscale-90 disabled:text-grayscale-50'
          )}
          target={'_blank'}
          rel="noreferrer">
          {ReviewerRegistrationData.error.actions.support}
        </a>
      </div>
    </div>
  );
};
