import clsx from "clsx";
import { useSystemAuth0 } from "features/registration/customer/react_query/hooks/useSystemAuth0";
import React from "react";
import { useNavigate } from "react-router-dom";

export const withAuth = (WrappedComponent: React.FC) => {
  const WithLoginWrapper = () => {
    const { isAuthenticated, isLoading } = useSystemAuth0();

    const navigate = useNavigate();
    // TODO: fix this to handle always async call
    const isLocalAuthenticated = localStorage.getItem('userId') !== null;

    // TODO: loading
    React.useEffect(() => {
      if (isLoading) return;
      if (!isAuthenticated && !isLocalAuthenticated) {
        navigate('/');
      }
    }, [isAuthenticated, isLocalAuthenticated, isLoading]);

    if (isLoading) {
      const loadingImg = 'https://cdn.auth0.com/blog/hello-auth0/loader.svg';
      return (
        <div className={clsx('flex items-center justify-center', 'w-full h-[100vh]')}>
          <img className={clsx('w-[120px] h-[120px]')} src={loadingImg} alt="Loading..." />
        </div>
      );
    }

    return <WrappedComponent />
  }

  return WithLoginWrapper;
}