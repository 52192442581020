export const forms = {
  state: {
    is_loading: 'is_loading-state-reviewer_registration'
  },
  alert: {
    is_open: 'is_open-alert-reviewer_registration',
    message: 'message-alert-reviewer_registration'
  },
  personal_information: {
    contact_number: 'contact_number-personal_information-reviewer_registration',
    linkedin: 'linkedin-personal_information-reviewer_registration',
    interest: 'interest-personal_information-reviewer_registration'
  },
  user: {
    is_email_verified: 'is_email_verified-user-reviewer_registration'
  }
} as const;

export const formErrors = {
  // personal_information
  [`${forms.personal_information.contact_number}`]: {
    type: 'custom',
    message: 'required'
  },
  [`${forms.personal_information.linkedin}`]: {
    type: 'custom',
    message: 'required'
  },
  [`${forms.personal_information.interest}`]: {
    type: 'custom',
    message: 'required'
  }
};
