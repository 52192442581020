import * as React from 'react';
import clsx from 'clsx';

export interface CompanyIconProps extends React.SVGAttributes<HTMLOrSVGElement> {
  iconSize?: 'XL' | 'L' | 'M' | 'S' | 'XS';
}

export const CompanyIcon = ({ iconSize, className, ...otherProps }: CompanyIconProps) => {
  const svgSizeClassName =
    iconSize === 'XL'
      ? 'w-[2.5rem] h-[2.5rem]'
      : iconSize === 'L'
      ? 'w-[2rem] h-[2rem]'
      : iconSize === 'M'
      ? 'w-[1.5rem] h-[1.5rem]'
      : iconSize === 'S'
      ? 'w-[1.25rem] h-[1.25rem]'
      : 'w-[1rem] h-[1rem]';
  return (
    <svg
      className={clsx(svgSizeClassName, className)}
      {...otherProps}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_179_2337)">
        <path
          d="M4.0002 14.8V2.19995C4.0002 1.64767 4.44791 1.19995 5.0002 1.19995H11.0002C11.5525 1.19995 12.0002 1.64767 12.0002 2.19995V14.8M4.0002 14.8H12.0002M4.0002 14.8H2.20019C1.64791 14.8 1.2002 14.3522 1.2002 13.8V10.1999C1.2002 9.64766 1.64791 9.19995 2.2002 9.19995H4.0002M12.0002 14.8H13.8002C14.3525 14.8 14.8002 14.3522 14.8002 13.8V6.19995C14.8002 5.64767 14.3525 5.19995 13.8002 5.19995H12.0002M8.80019 12.3999H10.0002M6.00019 12.3999H7.20019"
          strokeOpacity="0.1"
          strokeWidth="1.5"
          {...otherProps}
        />
      </g>
      <defs>
        <clipPath id="clip0_179_2337">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
