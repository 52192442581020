import { Table, flexRender } from '@tanstack/react-table';
import clsx from 'clsx';
import TableHeadTestData from './TableHead.test.data.json';

export interface TableHeadProps {
  table?: Table<any> | null;
}

export const TableHead = ({ table = null }: TableHeadProps) => {
  return (
    <thead data-testid={TableHeadTestData['data-testid']} className={clsx('bg-grayscale-20')}>
      {table !== null &&
        table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className={clsx('w-full')}>
            {headerGroup.headers.map((header) => {
              return (
                <th key={header.id} colSpan={header.colSpan}>
                  <div
                    className={clsx(
                      'w-full',
                      'cursor-default select-none flex justify-left items-center'
                    )}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
    </thead>
  );
};
