import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './assets/styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// Notes: Global configuration query client
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const domain = process.env.REACT_APP_BILLING_AUTH0_DOMAIN || '';
const clientID = process.env.REACT_APP_BILLING_AUTH0_CLIENT_ID || '';
const audience = process.env.REACT_APP_BILLING_AUTH0_AUDIENCE;

const redirectURI = !window.location.href.includes('/reviewer')
  ? process.env.REACT_APP_AUTH0_REDIRECT_URI_CUSTOMER
  : process.env.REACT_APP_AUTH0_REDIRECT_URI_REVIEWER;

root.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <Auth0Provider
        domain={domain}
        clientId={clientID}
        authorizationParams={{
          redirect_uri: redirectURI,
          ...(process.env.NODE_ENV === 'production' && {cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN}),
          ...(audience ? { audience: audience } : null)
        }}
        {...(process.env.NODE_ENV === 'production' && {
          cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN, 
          useCookiesForTransactions: true, 
          legacySameSiteCookie: true
        })}
        >
        <Router>
          <App />
        </Router>
      </Auth0Provider>
    </React.StrictMode>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
