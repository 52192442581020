import { PopUp, type PopUpProps } from 'core/components/PopUp/PopUp';
import * as React from 'react';
import clsx from 'clsx';
import { Typography } from 'core/components/Typography';
import { Button } from 'core/components/Button';
import { CancelIcon } from 'core/icons/Cancel';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Field } from 'core/components/Field';

export interface UserInvitationPopuUpUsersProps extends PopUpProps {
  message?: string;
  description?: string;
  input?: {
    error: {
      status: boolean;
      message: string;
    };
    placeholder?: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };
  cta?: {
    primary: {
      children: React.ReactNode;
      disabled: boolean;
      onClick: () => void;
    };
  };
}

export const UserInvitationPopUpUsers = ({
  message = '',
  description = '',

  input = {
    error: {
      status: false,
      message: ''
    },
    value: '',

    onChange: () => {}
  },

  cta = {
    primary: {
      children: '',
      disabled: true,
      onClick: () => {}
    }
  },

  ...otherProps
}: UserInvitationPopuUpUsersProps) => {
  return (
    <PopUp {...otherProps}>
      <div
        className={clsx(
          'grid grid-cols-1 place-content-start place-items-start gap-[1.5rem]',
          'rounded-[0.5rem]',
          'bg-grayscale-10',
          'px-[2rem] py-[1.5rem]',
          'w-[456px]'
        )}>
        <button
          className={clsx('absolute', 'right-[1.5rem] top-[1.5rem]')}
          onClick={otherProps.onClose}>
          <CancelIcon iconSize={'XS'} className={clsx('stroke-grayscale-60')} />
        </button>

        <div
          className={clsx(
            'grid grid-cols-1 items-start content-start justify-start justify-items-start gap-[0.5rem]',
            'w-full',
            'text-left'
          )}>
          <Typography variant={'Heading'} size={'H3'} color={'text-grayscale-90'}>
            {message}
          </Typography>
          <Typography variant={'Regular'} size={'S'} color={'text-grayscale-70'}>
            {description}
          </Typography>
        </div>

        <div
          className={clsx(
            'grid grid-cols-1 place-content-start place-items-start gap-[0.5rem]',
            'w-full'
          )}>
          {/* <div
            className={clsx(
              'flex content-start justify-start flex-wrap gap-[0.5rem]',
              'w-full h-[168px]',
              'overflow-auto',
              'border',
              input.error.status ? 'border-error-primary' : 'border-grayscale-30',
              'px-[0.5rem] py-[0.5rem]',
              'rounded-[0.25rem]'
            )}>
            {input.value.map((item, index) => (
              <div
                key={index}
                className={clsx(
                  'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.5rem]',
                  'bg-grayscale-20',
                  'px-[0.75rem] py-[0.375rem]',
                  'rounded-[0.25rem]'
                )}>
                <Typography variant={'Regular'} size={'S'} color={'text-grayscale-90'}>
                  {item}
                </Typography>
                <button
                  value={item}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    input.onDelete(e.currentTarget.value)
                  }>
                  <XMarkIcon className={clsx('w-[1rem] h-[1rem]', 'text-grayscale-60')} />
                </button>
              </div>
            ))}
            <input
              className={clsx(
                'outline-none',
                'w-fit h-[2rem]',
                'resize-none',
                'placeholder:text-[0.875rem] placeholder:text-grayscale-60 placeholder:leading-[146%] placeholder:tracking-[-0.14px] font-normal',
                'text-[0.875rem] text-grayscale-90 font-normal leading-[146%] tracking-[-0.14px]',
                'disabled:bg-grayscale-30 disabled:text-grayscale-60'
              )}
              autoFocus={true}
              value={input.email}
              onChange={input.onChange}
              onKeyDown={input.onKeyDown}
            />
           
          </div> */}
          <Field
            placeholder={input.placeholder}
            variant={input.error.status ? 'error' : 'default'}
            value={input.value}
            onChange={input.onChange}
            helperText={input.error.status ? input.error.message : ''}
          />
          {/* {input.error.status && (
            <Typography variant={'Regular'} size={'XS'} color={'text-error-primary'}>
              {input.error.message}
            </Typography>
          )} */}
        </div>

        <div
          className={clsx(
            'grid grid-flow-col items-center content-center justify-end justify-items-end gap-[0.5rem]',
            'w-full'
          )}>
          <Button
            data-testid={cta.primary}
            variant={'primary'}
            fullWidth={true}
            disabled={cta.primary.disabled}
            onClick={cta.primary.onClick}>
            {cta.primary.children}
          </Button>
        </div>
      </div>
    </PopUp>
  );
};
