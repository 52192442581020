import { BillingContainer } from 'features/billing/container';
import { useForm, FormProvider } from 'react-hook-form';
import { defaultValues } from 'features/billing/react_hook_form/constants/default';
import { withAuth } from 'core/utils/hooks/withAuth';


function BillingPage() {
  const methods = useForm({
    defaultValues: defaultValues
  });

  return (
    <FormProvider {...methods}>
      <BillingContainer />
    </FormProvider>
  );
}

export default withAuth(BillingPage)