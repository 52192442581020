import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { RegistrationReactQueryKey } from '../keys';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchPostAddUser } from 'core/services/registration';
import {
  PostAddUserRequestInterface,
  PostAddUserSuccessResponseInterface,
  PostAddUserErrorResponseInterface
} from 'core/models/api/registration';
import { useFormContext } from 'react-hook-form';
import { forms } from 'features/registration/reviewer/react_hook_form/constants/data';
import { ReviewerRegistrationForm } from '../../react_hook_form/constants';
import ReviewerRegistrationData from 'features/registration/reviewer/data/reviewer.registration.json';

export const useReviewerRegistrationMutationAddUser = () => {
  const { user } = useAuth0();
  const { setValue } = useFormContext<ReviewerRegistrationForm>();
  const token = localStorage.getItem('token') ?? '';

  const mutation = useMutation<
    PostAddUserSuccessResponseInterface,
    AxiosError<PostAddUserErrorResponseInterface>
  >(
    RegistrationReactQueryKey.MutationAddUser(),
    () => {
      const payload: PostAddUserRequestInterface = {
        headers: {
          token: token
        },
        data: {
          query: gql`
            mutation {
              addUser(
                email:"${user?.email || ''}",
                name:"${user?.name || ''}",
                role:REVIEWER,
              ){
                id,
              }
            }
          `
        }
      };

      return fetchPostAddUser(payload);
    },
    {
      retry: 0,
      onError(error, variables, context) {
        setTimeout(() => {
          setValue(forms.alert.is_open, true);
          setValue(
            forms.alert.message,
            ReviewerRegistrationData.alert.message.replace(
              '{{error}}',
              error.response?.data.error[0].message ?? ''
            )
          );
        }, 5000);
      },
      onSuccess(data) {
        if (data?.data?.addUser !== null) {
          if (user?.email_verified) {
            window.location.href = `${process.env.REACT_APP_REDIRECT_REVIEWER_REGISTRATION_LINK}`;
          } else {
            setValue(forms.user.is_email_verified, false);
          }

          // console.log("Link" , process.env.REACT_APP_REDIRECT_REVIEWER_REGISTRATION_LINK)
        }
      }
    }
  );

  return mutation;
};
