import RegistrationPage from 'pages/Registration/Registration';
import HomePage from 'pages/Home/Home';
import PageNotFound from 'pages/NotFound/NotFound';
import ReviewerRegistrationPage from 'pages/ReviewerRegistration/ReviewerRegistration';
export const publicRoutes = [
  {
    path: () => `/`,
    element: <HomePage />
  },
  {
    path: () => `/reviewer`,
    element: <ReviewerRegistrationPage />
  },
  {
    path: () => `/registration`,
    element: <RegistrationPage />
  },

  {
    path: () => `*`,
    element: <PageNotFound />
  }
];
