import { PopUp, type PopUpProps } from 'core/components/PopUp/PopUp';
import * as React from 'react';
import clsx from 'clsx';
import { Typography } from 'core/components/Typography';
import { Button } from 'core/components/Button';
import { FileList } from 'core/components/FileList';
import { Upload } from 'core/components/Upload';
import { Banner } from 'core/components/Banner';
import { CancelIcon } from 'core/icons/Cancel';

export interface BankPaymentPopUpBillingProps extends PopUpProps {
  message?: string;
  description?: string;
  bannerMessage?: string;
  informations?: { name: string; value: string }[];
  cta?: {
    primary: string;
  };
  onSubmit?: () => void;
}

export const BankPaymentPopUpBilling = ({
  message = '',
  description = '',
  informations = [],
  bannerMessage = '',
  cta = {
    primary: ''
  },
  onSubmit,
  ...otherProps
}: BankPaymentPopUpBillingProps) => {
  const [names, setNames] = React.useState<string[]>([]);
  const [files, setFiles] = React.useState<File[] | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newFiles: File[] = [];
    if (e.currentTarget.files !== null) {
      newFiles = Array.from(e.currentTarget.files);
    }
    setFiles(() => newFiles);
    let fileNames: string[] = [];
    if (e.currentTarget.files !== null && e.currentTarget.files.length > 0) {
      for (let i = 0; i < e.currentTarget.files.length; i++) {
        fileNames = [...fileNames, e.currentTarget.files[i].name];
      }
    }
    setNames(fileNames);
    e.stopPropagation();
    e.preventDefault();
  };

  const handleChangeOneFile = (data: { id: number; files: FileList | null }) => {
    const newNames = names.map((name, nameIndex) => {
      if (data.id === nameIndex && data.files !== null) {
        return data.files[0].name;
      } else {
        return name;
      }
    });

    // change files
    let newFiles = files;
    if (newFiles !== null) {
      for (let i = 0; i < newFiles.length; i++) {
        if (data.id === i && data.files !== null) {
          newFiles[i] = data.files[0];
        }
      }
    }

    setFiles((_) => newFiles);
    setNames((_) => newNames);
  };

  const handleDeleteOneFile = (data: { id: number }) => {
    const newNames = names.filter((name, nameIndex) => data.id !== nameIndex);
    let newFiles: File[] | null = [];

    if (files !== null) {
      newFiles = files.filter((_, fileIndex) => fileIndex !== data.id);
    } else {
      newFiles = files;
    }

    setFiles((_) => newFiles);
    setNames((_) => newNames);
  };
  return (
    <PopUp {...otherProps}>
      <div
        className={clsx(
          'grid grid-cols-1 place-content-start place-items-start gap-[1.5rem]',
          'rounded-[0.5rem]',
          'bg-grayscale-10',
          'px-[2rem] py-[1.5rem]'
        )}>
        <button
          className={clsx('absolute', 'right-[1.5rem] top-[1.5rem]')}
          onClick={otherProps.onClose}>
          <CancelIcon iconSize={'XS'} className={clsx('stroke-grayscale-60')} />
        </button>

        <div
          className={clsx(
            'grid grid-cols-1 items-start content-start justify-start justify-items-start gap-[0.5rem]',
            'w-full',
            'text-left',
            'pb-[1rem]',
            'border-b border-b-grayscale-30'
          )}>
          <Typography variant={'Heading'} size={'H3'} color={'text-grayscale-90'}>
            {message}
          </Typography>
          <Typography variant={'Regular'} size={'S'} color={'text-grayscale-70'}>
            {description}
          </Typography>
        </div>

        <div
          className={clsx(
            'grid grid-cols-1 gap-[1.5rem] place-content-start place-items-start',
            'w-full'
          )}>
          <Banner message={bannerMessage} />

          <div
            className={clsx(
              'grid grid-cols-1 gap-x-[1.5rem] gap-y-[0.5rem] place-content-start place-items-start',
              'w-full'
            )}>
            {informations.map((information, informationIndex) => (
              <div
                key={informationIndex}
                className={clsx(
                  'grid grid-cols-2 gap-x-[1.5rem] gap-y-[0.5rem] place-content-start place-items-start',
                  'w-full'
                )}>
                <div
                  className={clsx(
                    'grid grid-cols-1 gap-x-[1.5rem] gap-y-[0.5rem] place-content-start place-items-start',
                    'w-full'
                  )}>
                  <Typography variant={'Regular'} size={'S'} color={'text-grayscale-70'}>
                    {information.name}
                  </Typography>
                </div>
                <div
                  className={clsx(
                    'grid grid-cols-1 gap-x-[1.5rem] gap-y-[0.5rem] place-content-start place-items-start',
                    'w-full'
                  )}>
                  <Typography variant={'Medium'} size={'S'} color={'text-grayscale-90'}>
                    {information.value}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          className={clsx(
            'grid grid-cols-1 place-content-start place-items-start gap-y-[1.5rem]',
            'w-full'
          )}>
          {names.length > 0 && (
            <div
              className={clsx(
                'grid grid-cols-1 place-content-start place-items-start gap-[0.5rem]',
                'w-full'
              )}>
              {names.map((name, nameIndex) => (
                <FileList
                  key={nameIndex}
                  name={name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangeOneFile({ id: nameIndex, files: e.currentTarget.files });
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onDelete={() => handleDeleteOneFile({ id: nameIndex })}
                />
              ))}
            </div>
          )}
          <Upload onChange={handleChange} />
        </div>

        {names.length > 0 && (
          <div
            className={clsx(
              'grid grid-flow-col items-center content-center justify-end justify-items-end gap-[0.5rem]',
              'w-full'
            )}>
            <Button
              data-testid={cta.primary}
              variant={'primary'}
              fullWidth={true}
              onClick={onSubmit}>
              {cta.primary}
            </Button>
          </div>
        )}
      </div>
    </PopUp>
  );
};
