import { forms } from './data';
import { ReviewerRegistrationForm } from './type';
export const defaultValues: ReviewerRegistrationForm = {
  // state
  [forms.state.is_loading]: true,
  // alert
  [forms.alert.is_open]: false,
  [forms.alert.message]: '',
  // personal_information
  [forms.personal_information.contact_number]: '',
  [forms.personal_information.linkedin]: '',
  [forms.personal_information.interest]: [],
  // user
  [forms.user.is_email_verified]: null as null | boolean
};
