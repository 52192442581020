import {
  GetEstimatedUsageRequestInterface,
  GetInvoicesRequestInterface
} from 'core/models/api/invoices';

export const BillingReactQueryKey = {
  QueryGetUser: () => ['BillingReactQueryKey.QueryGetUser'],
  QueryGeneralBillingGetEstimatedUsage: (payload?: GetEstimatedUsageRequestInterface) => [
    'BillingReactQueryKey.QueryGeneralBillingGetEstimatedUsage',
    [payload] as const
  ],
  QueryCurrentCycleBillingGetEstimatedUsage: (payload?: GetEstimatedUsageRequestInterface) => [
    'BillingReactQueryKey.QueryCurrentCycleBillingGetEstimatedUsage',
    [payload] as const
  ],
  QueryGeneralGetInvoices: (payload?: GetInvoicesRequestInterface) => [
    'BillingReactQueryKey.QueryGeneralGetInvoices',
    [payload] as const
  ],
  QueryInvoicesGetInvoices: (payload?: GetInvoicesRequestInterface) => [
    'BillingReactQueryKey.QueryInvoicesGetInvoices',
    [payload] as const
  ],
  QueryCurrentCycleGetBillableItems: (payload?: GetInvoicesRequestInterface) => [
    'BillingReactQueryKey.QueryGetBillableItems',
    [payload] as const
  ]
};
