import * as React from 'react';
import clsx from 'clsx';
import CustomerHomeData from 'features/home/customer/data/customer.home.json';
import { Typography } from 'core/components/Typography';
import { Avatar } from 'core/components/Avatar';
import { StarIcon } from 'core/icons/Star';

export const TestimonialCustomerHome = () => {
  return (
    <div
      className={clsx(
        'grid grid-cols-1 items-start content-start justify-center justify-items-center',
        'w-full',
        'py-[9rem]',
        'bg-grayscale-10',
        'rounded-[1rem]'
      )}>
      <div
        className={clsx(
          'grid grid-cols-1 lg:grid-cols-[auto_repeat(4,1fr)] place-content-start place-items-start gap-[6rem]',
          'w-full max-w-[1200px]'
        )}>
        <div className={clsx('py-[1.5rem]', 'w-full max-w-[168px]')}>
          <h5
            className={clsx(
              'text-[1.5rem] text-[#B9B9B9] font-eliza font-medium leading-[123%] tracking-[-0.24px]'
            )}>
            {CustomerHomeData.testimonial.title}
          </h5>
        </div>

        {CustomerHomeData.testimonial.items.map((item) => (
          <div
            key={item.name}
            className={clsx(
              'grid grid-cols-1 items-stretch content-between justify-start justify-items-start gap-[1rem]',
              'w-full h-full'
              //   'px-[2rem] py-[1.5rem]'
            )}>
            <Typography variant={'Regular'} size={'L'} color={'text-grayscale-black'}>
              {item.name}
            </Typography>
            <div className={clsx('flex items-center justify-between', 'w-full')}>
              <div>
                <div
                  className={clsx(
                    'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.25rem]',
                    'w-full'
                  )}>
                  <StarIcon
                    iconSize={'XS'}
                    className={clsx('stroke-grayscale-black fill-grayscale-black')}
                  />
                  <Typography variant={'Regular'} size={'XS'} color={'text-grayscale-black'}>
                    {item.rating_text}
                  </Typography>
                </div>
                <Typography variant={'Regular'} size={'XS'} color={'text-grayscale-75'}>
                  {item.reviews}
                </Typography>
              </div>
              {/* avatar */}
              <Avatar variant={'user-photo'} size={'M'} src={item.image} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
