export const forms = {
  navbar: {
    image: 'image-navbar-dashboard',
    name: 'name-navbar-dashboard',
    email: 'email-navbar-dashboard',
    initial: 'initial-navbar-dashboard'
  },
  sidebar: {
    menu: 'sidebar-menu-dashboard'
  },
  menu_dropdown: {
    is_open: 'is_open-menu_dropdown-dashboard',
    items: 'items-menu_dropdown-dashboard'
  }
} as const;
