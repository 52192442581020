import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { BillingReactQueryKey } from '../keys';
import { gql } from 'graphql-request';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { BillingForm, forms } from 'features/billing/react_hook_form/constants';
import BillingData from 'features/billing/data/invoices.json';
import {
  GetEstimatedUsageRequestInterface,
  GetEstimatedUsageSuccessResponseInterface
} from 'core/models/api/invoices';
import { fetchGetEstimatedUsage } from 'core/services/invoices';

export const useGeneralBillingQueryGetEstimatedOrganizationUsage = () => {
  const token = localStorage.getItem('token') ?? '';

  const { watch, setValue } = useFormContext<BillingForm>();

  const payload: GetEstimatedUsageRequestInterface = {
    headers: {
      token: token
    },
    data: {
      query: gql`
          query {
            estimatedOrganizationUsage {
              currency
              pageCount
              totalAmount
              creditsUsed
              creditsTotal
            }
          }
        `
    }
  };

  const query = useQuery<GetEstimatedUsageSuccessResponseInterface | undefined, AxiosError>(
    BillingReactQueryKey.QueryGeneralBillingGetEstimatedUsage(payload),
    () => {
      return fetchGetEstimatedUsage(payload);
    },
    {
      retry: 0,
      enabled:
        (watch(forms.tabs.active) as null | { id: string; name: string })?.id ===
        BillingData.tabs[0].id
    }
  );

  useEffect(() => {
    if (query.data) {
      setValue(forms.general.information, query.data?.data.estimatedOrganizationUsage);
    }
  }, [query.data]);

  return query;
};

export const useCurrentCycleBillingQueryGetEstimatedOrganizationUsage = () => {
  const token = localStorage.getItem('token') ?? '';

  const { watch, setValue } = useFormContext<BillingForm>();

  const payload: GetEstimatedUsageRequestInterface = {
    headers: {
      token: token
    },
    data: {
      query: gql`
          query {
            estimatedOrganizationUsage {
              currency
              pageCount
              totalAmount
              creditsUsed
              creditsTotal
            }
          }
        `
    }
  };

  const query = useQuery<GetEstimatedUsageSuccessResponseInterface | undefined, AxiosError>(
    BillingReactQueryKey.QueryCurrentCycleBillingGetEstimatedUsage(payload),
    () => {
      return fetchGetEstimatedUsage(payload);
    },
    {
      retry: 0,
      enabled:
        (watch(forms.tabs.active) as null | { id: string; name: string })?.id ===
        BillingData.tabs[2].id
    }
  );

  useEffect(() => {
    if (query.data) {
      setValue(forms.current_cycle.information, query.data?.data.estimatedOrganizationUsage);
    }
  }, [query.data]);

  return query;
};
