import { request } from 'graphql-request';
import {
  PostInviteUsersRequestInterface,
  PostInviteUsersSuccessResponseInterface
} from 'core/models/api/registration';

export const fetchPostInviteUsers = async (payload: PostInviteUsersRequestInterface) => {
  return await request(payload.url, payload.query, payload.variables, payload.headers)
    .then((res) => res as PostInviteUsersSuccessResponseInterface)
    .catch((err) => {
      throw err;
    });
};
