import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { RegistrationReactQueryKey } from '../keys';
import { useFormContext } from 'react-hook-form';
import { fetchPostUpdateUser } from 'core/services/registration';
import {
  PostUpdateUserRequestInterface,
  PostUpdateUserSuccessResponseInterface
} from 'core/models/api/registration';
import { useEffect } from 'react';
import { forms } from 'features/registration/customer/react_hook_form/constants/data';
import { RegistrationForm } from '../../react_hook_form/constants/type';

export const useRegistrationMutationCompleteUserRegistration = () => {
  const { watch } = useFormContext<RegistrationForm>();
  const token = localStorage.getItem('token') ?? '';

  const mutation = useMutation<PostUpdateUserSuccessResponseInterface, AxiosError>(
    RegistrationReactQueryKey.MutationUpdateUser(),
    () => {
      const payload: PostUpdateUserRequestInterface = {
        headers: {
          token: token
        },

        data: {
          query: gql`
            mutation {
              completeUserInvitation(
                input:{
                  ${
                    !(watch(forms.personal_information_invited.first_name) as string).length
                      ? ``
                      : `firstName:"${watch(forms.personal_information_invited.first_name)}",`
                  }
                  ${
                    !(watch(forms.personal_information_invited.last_name) as string).length
                      ? ``
                      : `lastName:"${watch(forms.personal_information_invited.last_name)}",`
                  }
                  ${
                    !(watch(forms.personal_information_invited.contact_number) as string).length
                      ? ``
                      : `contactNumber:"${watch(
                          forms.personal_information_invited.contact_number
                        )}",`
                  }${
                    !(watch(forms.personal_information_invited.position_in_company) as string)
                      .length
                      ? ``
                      : `positionInCompany:"${watch(
                          forms.personal_information_invited.position_in_company
                        )}",`
                  }
                  ${
                    !(watch(forms.personal_information_invited.linkedin) as string).length
                      ? ``
                      : `linkedInUrl:"${watch(forms.personal_information_invited.linkedin)}",`
                  }
                profileImageUrl:null
              }){
                id,
                firstName,
                lastName,
                email,
                linkedInUrl,
                positionInCompany,
                contactNumber,
                profileImageUrl
              }
            }
          `
        }
      };

      return fetchPostUpdateUser(payload);
    },
    {
      retry: 0,
      onSuccess(data, variables, context) {
        if (data) {
          window.location.href = `${process.env.REACT_APP_REDIRECT_CUSTOMER_REGISTRATION_LINK}`;
        }
      }
    }
  );

  return mutation;
};
