import * as React from 'react';
import clsx from 'clsx';
import { Typography } from '../Typography';

export interface TextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  mainLabel?: string;
  secondaryLabel?: string;
  helperText?: string;
  variant?: 'default' | 'error';
  fieldSize?: 'S' | 'M';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

export const Textarea = ({
  mainLabel = '',
  secondaryLabel = '',
  helperText = '',
  variant = 'default',
  fieldSize = 'M',
  startIcon,
  endIcon,
  ...otherProps
}: TextareaProps) => {
  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-content-start place-items-start gap-[0.5rem]',
        'w-full'
      )}>
      {(!!mainLabel.length || !!secondaryLabel.length) && (
        <div className={clsx('flex items-center justify-between', 'w-full')}>
          {!!mainLabel.length && (
            <Typography variant={'Regular'} size={'S'} color={'text-grayscale-70'}>
              {mainLabel}
            </Typography>
          )}

          {!!secondaryLabel.length && (
            <Typography variant={'Regular'} size={'S'} color={'text-grayscale-60'}>
              {secondaryLabel}
            </Typography>
          )}
        </div>
      )}

      <div
        className={clsx(
          'grid items-start content-start justify-start justify-items-start',
          typeof startIcon !== 'undefined' && typeof endIcon !== undefined
          ? 'grid-cols-[auto_repeat(1, minmax(0, 1fr))_auto]'
          : typeof startIcon !== 'undefined'
          ? 'grid-cols[auto_repeat(1, minmax(0, 1fr))]'
          : typeof endIcon !== 'undefined'
          ? 'grid-cols[repeat(1, minmax(0, 1fr))_auto]'
          : 'grid-cols-1',
          'w-full h-[5.625rem]',
          'rounded-[0.375rem]',
          'border',
          'bg-grayscale-10',
          variant === 'error' ? 'border-error-primary' : 'border-grayscale-50',
          fieldSize === 'M' ? 'px-[1rem] py-[0.875rem]' : 'px-[0.75rem] py-[0.75rem]'
        )}>
        {startIcon && startIcon}
        <textarea
          className={clsx(
            'outline-none',
            'w-full h-full',
            'resize-none',
            'placeholder:text-[0.875rem] placeholder:text-grayscale-60 placeholder:leading-[146%] placeholder:tracking-[-0.14px] font-normal',
            'text-[0.875rem] text-grayscale-90 font-normal leading-[146%] tracking-[-0.14px]'
          )}
          {...otherProps}
        />
        {endIcon && endIcon}
      </div>

      {!!helperText.length && (
        <Typography
          variant={'Regular'}
          size={'XS'}
          color={variant === 'error' ? 'text-error-primary' : 'text-grayscale-60'}>
          {helperText}
        </Typography>
      )}
    </div>
  );
};
