import * as React from 'react';
import clsx from 'clsx';

export interface UploadIconProps extends React.SVGAttributes<HTMLOrSVGElement> {
  iconSize?: 'XL' | 'L' | 'M' | 'S' | 'XS';
}

export const UploadIcon = ({ iconSize, className, ...otherProps }: UploadIconProps) => {
  const svgSizeClassName =
    iconSize === 'XL'
      ? 'w-[2.5rem] h-[2.5rem]'
      : iconSize === 'L'
      ? 'w-[2rem] h-[2rem]'
      : iconSize === 'M'
      ? 'w-[1.5rem] h-[1.5rem]'
      : iconSize === 'S'
      ? 'w-[1.25rem] h-[1.25rem]'
      : 'w-[1rem] h-[1rem]';
  return (
    <svg
      className={clsx(svgSizeClassName, className)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M12 13.5V21.75" {...otherProps} strokeWidth="1.5" strokeLinejoin="round" />
      <path
        d="M15.375 16.875L11.9994 13.5L8.625 16.875"
        {...otherProps}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M5.875 17.25C3.45875 17.25 1.5 15.2563 1.5 12.7969C1.5 10.3375 3.45875 8.34375 5.875 8.34375C6.17491 8.34375 6.46777 8.37447 6.75071 8.43298C6.75024 8.40329 6.75 8.37355 6.75 8.34375C6.75 5.39248 9.10051 3 12 3C14.8995 3 17.25 5.39248 17.25 8.34375C17.25 8.37355 17.2498 8.40329 17.2493 8.43298C17.5322 8.37447 17.8251 8.34375 18.125 8.34375C20.5412 8.34375 22.5 10.3375 22.5 12.7969C22.5 15.2563 20.5412 17.25 18.125 17.25"
        {...otherProps}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};
