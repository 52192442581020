import clsx from 'clsx';

export interface HeaderProps {
  children?: React.ReactNode;
}

export const Header = ({ children }: HeaderProps) => {
  return (
    <header
      className={clsx(
        'w-full',
        'py-[18px] px-10',
        'bg-grayscale-20',
        'flex justify-between items-center',
        'h-[4.5rem]'
      )}>
      {children}
    </header>
  );
};
