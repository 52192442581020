import * as React from 'react';
import clsx from 'clsx';

export interface ArrowRightIconProps extends React.SVGAttributes<HTMLOrSVGElement> {
  iconSize?: 'XL' | 'L' | 'M' | 'S' | 'XS';
}

export const ArrowRightIcon = ({ iconSize, className, ...otherProps }: ArrowRightIconProps) => {
  const svgSizeClassName =
    iconSize === 'XL'
      ? 'w-[2.5rem] h-[2.5rem]'
      : iconSize === 'L'
      ? 'w-[2rem] h-[2rem]'
      : iconSize === 'M'
      ? 'w-[1.5rem] h-[1.5rem]'
      : iconSize === 'S'
      ? 'w-[1.25rem] h-[1.25rem]'
      : 'w-[1rem] h-[1rem]';
  return (
    <svg className={clsx(svgSizeClassName, className)}
    {...otherProps}
    width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.25 12L9.75 8L6.25 4" stroke="#7E8180" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" {...otherProps}/>
    </svg>
  );
};
