import * as React from 'react';
import clsx from 'clsx';

export interface StarIconProps extends React.SVGAttributes<HTMLOrSVGElement> {
  iconSize?: 'XL' | 'L' | 'M' | 'S' | 'XS';
}

export const StarIcon = ({ iconSize, className, ...otherProps }: StarIconProps) => {
  const svgSizeClassName =
    iconSize === 'XL'
      ? 'w-[2.5rem] h-[2.5rem]'
      : iconSize === 'L'
      ? 'w-[2rem] h-[2rem]'
      : iconSize === 'M'
      ? 'w-[1.5rem] h-[1.5rem]'
      : iconSize === 'S'
      ? 'w-[1.25rem] h-[1.25rem]'
      : 'w-[1rem] h-[1rem]';
  return (
    <svg
      className={clsx(svgSizeClassName, className)}
      {...otherProps}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00065 1.33325L10.0607 5.50659L14.6673 6.17992L11.334 9.42659L12.1207 14.0133L8.00065 11.8466L3.88065 14.0133L4.66732 9.42659L1.33398 6.17992L5.94065 5.50659L8.00065 1.33325Z"
        {...otherProps}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
