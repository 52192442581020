import * as React from 'react';
import { twMerge } from 'tailwind-merge';

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  buttonSize?: 'L' | 'M';
  variant?: 'primary' | 'secondary' | 'ghost' | 'error';
  disabled?: boolean;

  fullWidth?: boolean;
}

export const Button = ({
  children,
  buttonSize,
  variant,
  disabled,
  fullWidth,
  onClick,
  ...otherProps
}: ButtonProps) => {
  return (
    <button
      className={twMerge(
        'flex items-center justify-center',
        fullWidth && 'w-full',
        variant === 'primary'
          ? 'bg-cta-purple-primary hover:bg-cta-purple-dark active:bg-cta-purple-dark disabled:bg-grayscale-30'
          : variant === 'secondary'
          ? 'bg-grayscale-90 hover:bg-grayscale-80 active:bg-grayscale-80 disabled:bg-grayscale-30'
          : variant === 'error'
          ? 'bg-error-tint hover:bg-cta-error-tint active:bg-cta-error-tint disabled:bg-grayscale-30'
          : 'bg-cta-mint-primary hover:bg-cta-mint-dark active:bg-cta-mint-dark disabled:bg-grayscale-30',
        variant === 'primary'
          ? 'text-grayscale-10 hover:text-grayscale-10 active:text-cta-purple-tint disabled:text-grayscale-60'
          : variant === 'secondary'
          ? 'text-grayscale-10 hover:text-grayscale-10 active:text-grayscale-40 disabled:text-grayscale-60'
          : variant === 'error'
          ? 'text-error-primary hover:text-error-primary active:text-error-primary disabled:text-grayscale-60'
          : 'text-grayscale-90 hover:text-grayscale-10 active:text-grayscale-80 disabled:text-grayscale-60',
        buttonSize === 'L'
          ? 'text-[1rem] font-medium leading-[150%] tracking-[-0.16px]'
          : 'text-[15px] font-medium leading-[158%] tracking-[-0.15px]',
        buttonSize === 'L' ? 'px-[2rem] py-[0.75rem]' : 'px-[1.5rem] py-[0.5rem]',
        'rounded-[0.375rem]',
        otherProps.className
      )}
      disabled={disabled}
      onClick={onClick}
      {...otherProps}>
      {children}
    </button>
  );
};
