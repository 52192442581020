import * as React from 'react';
import clsx from 'clsx';
import { TableHead } from 'core/components/TableHead';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import BillingData from 'features/billing/data/invoices.json';
import { Typography } from 'core/components/Typography';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { Pagination } from 'core/components/Pagination';
import { PaymentDropdownBilling } from 'features/billing/components/PaymentDropdown';
import { CheckPaymentPopUpBilling } from 'features/billing/components/CheckPaymentPopUp';
import {
  getStatusLabelBackgroundColorInvoiceTable,
  getStatusLabelColorInvoiceTable
} from 'features/billing/utils';
import { useFormContext } from 'react-hook-form';
import { BankPaymentPopUpBilling } from 'features/billing/components/BankPaymentPopUp';
import { RetryPaymentPopUpBilling } from 'features/billing/components/RetryPaymentPopUp';
import { InfoTipIcon } from 'core/icons/InfoTip';
import { Tooltip } from 'core/components/Tooltip/Tooltip';
import { Link } from 'react-router-dom';
import { HeaderCellDataTableBilling } from 'features/billing/components/HeaderCellDataTable';
import { CreatedDateCellDataTableBilling } from 'features/billing/components/CreatedDateCellDataTable';
import { DueDateCellDataTableBilling } from 'features/billing/components/DueDateCellDataTable';
import {
  BillingForm,
  DataTableInvoicesForm
} from 'features/billing/react_hook_form/constants/type';
import { forms } from 'features/billing/react_hook_form/constants/data';

export const InvoicesDataTableBilling = () => {
  const { watch, setValue } = useFormContext<BillingForm>();

  const invoices = watch(forms.invoices.data_table.data) as DataTableInvoicesForm[];

  const columns = React.useMemo<ColumnDef<DataTableInvoicesForm>[]>(() => {
    return BillingData.data_table.columns.map((item, index) => {
      return {
        accessorKey: item.id,
        header: () => {
          return (
            <HeaderCellDataTableBilling
              label={item.label.toLowerCase() === 'pay' ? 'Payment' : item.label}
            />
          );
        },
        cell: (cellProps) => {
          if (item.id === 'id') {
            return <span />;
          }

          if (item.id === 'checked') {
            return <span />;
          }

          if (item.id === 'date' && cellProps.row.getValue('id')) {
            const handleSelectCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
              const list: string[] = watch(forms.invoices.data_table.list_download) as string[];
              const payload: string[] = list.includes(cellProps.row.getValue('id'))
                ? list.filter((item) => item !== cellProps.row.getValue('id'))
                : [...list, cellProps.row.getValue('id')];

              setValue(forms.invoices.data_table.list_download, payload);

              setValue(
                forms.invoices.data_table.all_selected,
                payload.length ===
                  (watch(forms.invoices.data_table.data) as DataTableInvoicesForm[]).length
              );
            };

            const isCheckboxChecked: boolean = (
              watch(forms.invoices.data_table.list_download) as string[]
            ).includes(cellProps.row.getValue('id'));

            const date = new Date(cellProps.row.getValue('date')).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            });
            return (
              <CreatedDateCellDataTableBilling
                id={item.id}
                checked={isCheckboxChecked}
                label={date}
                onSelect={handleSelectCheckbox}
              />
            );
          }

          if (item.id === 'due_date' && cellProps.row.getValue('id')) {
            const dueDate = new Date(cellProps.row.getValue('due_date')).toLocaleDateString(
              'en-US',
              {
                month: 'short',
                day: 'numeric',
                year: 'numeric'
              }
            );
            return <DueDateCellDataTableBilling label={dueDate} />;
          }

          if (
            item.id === 'pay' &&
            (cellProps.row.getValue('status') as string).toLowerCase() === 'open'
          ) {
            const [paymentMethod, setPaymentMethod] = React.useState<null | {
              id: string;
              name: string;
            }>(null);
            const handleSelectPaymentMethod = (data: { id: string; name: string }) => {
              setPaymentMethod(data);
              if (data.id === 'credit_card') {
                window.open(cellProps.row.getValue('pay') as string, '_blank');
              }
            };
            const handleClose = () => {
              setPaymentMethod(null);
            };
            const handleSubmit = () => {
              setPaymentMethod(null);
            };
            return (
              <div className={clsx('flex items-center justify-left', 'w-full')}>
                <PaymentDropdownBilling
                  placeholder={item.label}
                  options={BillingData.data_table.payment_method}
                  onSelect={handleSelectPaymentMethod}
                />

                <CheckPaymentPopUpBilling
                  isOpen={paymentMethod !== null && paymentMethod.id === 'submit_check'}
                  message={BillingData.data_table.check_payment.title}
                  description={BillingData.data_table.check_payment.message.default}
                  cta={BillingData.data_table.check_payment.cta}
                  onClose={handleClose}
                  onSubmit={handleSubmit}
                />
                <BankPaymentPopUpBilling
                  isOpen={paymentMethod !== null && paymentMethod.id === 'bank_transfer'}
                  message={BillingData.data_table.bank_transfer.title}
                  bannerMessage={BillingData.data_table.bank_transfer.banner.message}
                  description={BillingData.data_table.bank_transfer.message.default}
                  informations={BillingData.data_table.bank_transfer.informations.data}
                  cta={BillingData.data_table.bank_transfer.cta}
                  onClose={handleClose}
                  onSubmit={handleSubmit}
                />
              </div>
            );
          }

          if (
            item.id === 'pay' &&
            (cellProps.row.getValue('status') as string).toLowerCase() === 'failed'
          ) {
            const [paymentMethod, setPaymentMethod] = React.useState<null | {
              id: string;
              name: string;
            }>(null);
            const handleSelectPaymentMethod = (data: { id: string; name: string }) => {
              setPaymentMethod(data);
              if (data.id === 'credit_card') {
                window.open(cellProps.row.getValue('pay') as string, '_blank');
              }
            };
            const handleClose = () => {
              setPaymentMethod(null);
            };
            const handleSubmit = () => {
              setPaymentMethod(null);
            };
            return (
              <div className={clsx('flex items-center justify-left', 'w-full')}>
                <PaymentDropdownBilling
                  placeholder={item.label}
                  options={BillingData.data_table.payment_method}
                  onSelect={handleSelectPaymentMethod}
                />

                <CheckPaymentPopUpBilling
                  isOpen={paymentMethod !== null && paymentMethod.id === 'submit_check'}
                  message={BillingData.data_table.check_payment.title}
                  description={BillingData.data_table.check_payment.message.default}
                  cta={BillingData.data_table.check_payment.cta}
                  onClose={handleClose}
                  onSubmit={handleSubmit}
                />
                <BankPaymentPopUpBilling
                  isOpen={paymentMethod !== null && paymentMethod.id === 'bank_transfer'}
                  message={BillingData.data_table.bank_transfer.title}
                  bannerMessage={BillingData.data_table.bank_transfer.banner.message}
                  description={BillingData.data_table.bank_transfer.message.default}
                  informations={BillingData.data_table.bank_transfer.informations.data}
                  cta={BillingData.data_table.bank_transfer.cta}
                  onClose={handleClose}
                  onSubmit={handleSubmit}
                />
              </div>
            );
          }

          if (
            item.id === 'pay' &&
            (cellProps.row.getValue('status') as string).toLowerCase() === 'overdue'
          ) {
            const [paymentMethod, setPaymentMethod] = React.useState<null | {
              id: string;
              name: string;
            }>(null);
            const handleSelectPaymentMethod = (data: { id: string; name: string }) => {
              setPaymentMethod(data);
              if (data.id === 'credit_card') {
                window.open(cellProps.row.getValue('pay') as string, '_blank');
              }
            };
            const handleClose = () => {
              setPaymentMethod(null);
            };
            const handleSubmit = () => {
              setPaymentMethod(null);
            };

            return (
              <div className={clsx('flex items-center justify-left', 'w-full')}>
                <PaymentDropdownBilling
                  placeholder={item.label}
                  options={BillingData.data_table.payment_method}
                  onSelect={handleSelectPaymentMethod}
                />

                <CheckPaymentPopUpBilling
                  isOpen={paymentMethod !== null && paymentMethod.id === 'submit_check'}
                  message={BillingData.data_table.check_payment.title}
                  description={BillingData.data_table.check_payment.message.default}
                  cta={BillingData.data_table.check_payment.cta}
                  onClose={handleClose}
                  onSubmit={handleSubmit}
                />
                <BankPaymentPopUpBilling
                  isOpen={paymentMethod !== null && paymentMethod.id === 'bank_transfer'}
                  message={BillingData.data_table.bank_transfer.title}
                  bannerMessage={BillingData.data_table.bank_transfer.banner.message}
                  description={BillingData.data_table.bank_transfer.message.default}
                  informations={BillingData.data_table.bank_transfer.informations.data}
                  cta={BillingData.data_table.bank_transfer.cta}
                  onClose={handleClose}
                  onSubmit={handleSubmit}
                />
              </div>
            );
          }

          if (
            item.id === 'pay' &&
            (cellProps.row.getValue('status') as string).toLowerCase() === 'error'
          ) {
            const [isOpen, setIsOpen] = React.useState<boolean>(false);
            const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
              setIsOpen(() => true);
            };

            const handleClose = () => {
              setIsOpen(() => false);
            };

            const handleSubmit = () => {
              setIsOpen(() => false);
            };
            return (
              <div className={clsx('flex items-center justify-left', 'w-full')}>
                <button
                  className={clsx(
                    'bg-cta-mint-primary',
                    'rounded-[0.25rem]',
                    'text-[0.875rem] font-medium leading-[146%] tracking-[-0.14px]',
                    'w-[5rem] h-[2rem]'
                  )}
                  onClick={handleClick}>
                  {'Retry'}
                </button>
                <RetryPaymentPopUpBilling
                  isOpen={isOpen}
                  message={BillingData.data_table.retry_payment.title}
                  description={BillingData.data_table.retry_payment.message.default}
                  cta={{
                    ...BillingData.data_table.retry_payment.cta,
                    disabled: true
                  }}
                  onClose={handleClose}
                  onSubmit={handleSubmit}
                />
              </div>
            );
          }

          if (
            item.id === 'pay' &&
            ((cellProps.row.getValue('status') as string).toLowerCase() !== 'open' ||
              (cellProps.row.getValue('status') as string).toLowerCase() !== 'overdue' ||
              (cellProps.row.getValue('status') as string).toLowerCase() !== 'error')
          ) {
            return <span />;
          }

          if (
            item.id === 'status' &&
            (cellProps.row.getValue(item.id) as string).toLowerCase() !== 'error'
          ) {
            return (
              <div
                className={clsx(
                  'px-[0.5rem] py-[0.25rem]',
                  'rounded-[0.25rem]',
                  'w-fit',
                  'text-[0.75rem] font-semibold leading-[136%] tracking-[0.3px] uppercase',
                  getStatusLabelBackgroundColorInvoiceTable(cellProps.row.getValue(item.id)),
                  getStatusLabelColorInvoiceTable(cellProps.row.getValue(item.id))
                )}>
                {cellProps.row.getValue(item.id)}
              </div>
            );
          }

          if (
            item.id === 'status' &&
            (cellProps.row.getValue(item.id) as string).toLowerCase() === 'error'
          ) {
            return (
              <div
                className={clsx(
                  'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.5rem]',
                  'w-full'
                )}>
                <div
                  className={clsx(
                    'px-[0.5rem] py-[0.25rem]',
                    'rounded-[0.25rem]',
                    'w-fit',
                    'text-[0.75rem] font-semibold leading-[136%] tracking-[0.3px] uppercase',
                    getStatusLabelBackgroundColorInvoiceTable(cellProps.row.getValue(item.id)),
                    getStatusLabelColorInvoiceTable(cellProps.row.getValue(item.id))
                  )}>
                  {cellProps.row.getValue(item.id)}
                </div>

                <Tooltip
                  text={`Payment couldn't be processed due to a faulty check scan.`}
                  variant={'white'}
                  position={'bottom'}>
                  <InfoTipIcon iconSize={'XS'} className={'fill-grayscale-55'} />
                </Tooltip>
              </div>
            );
          }
          if (item.id === 'pdf' && (cellProps.row.getValue(item.id) as string).length) {
            return (
              <div className={clsx('w-full', 'flex items-center justify-center')}>
                <Link
                  aria-label={'credit-card-payment'}
                  to={cellProps.row.getValue(item.id) as string}
                  target={'_blank'}
                  className={clsx(
                    'py-[0.125rem]',
                    'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.5rem]',
                    'bg-transparent',
                    'text-[0.875rem] font-medium leading-[146%] tracking-[-0.14px]',
                    'text-grayscale-90 disabled:text-grayscale-50'
                  )}>
                  {item.label}
                  <ArrowDownTrayIcon className={clsx('text-grayscale-90', 'w-[1rem] h-[1rem]')} />
                </Link>
              </div>
            );
          }

          if (item.id === 'total_amount') {
            return (
              <div className={clsx('flex items-center justify-end', 'w-full', 'max-w-[80px]')}>
                <Typography variant={'Regular'} size={'XS'} color={'text-grayscale-70'}>
                  {cellProps.row.getValue(item.id)}
                </Typography>
              </div>
            );
          }

          return (
            <Typography variant={'Regular'} size={'XS'} color={'text-grayscale-70'}>
              {cellProps.row.getValue(item.id)}
            </Typography>
          );
        }
      };
    });
  }, [BillingData.data_table.columns]);

  const table = useReactTable({
    data: invoices,
    columns: columns,
    state: {
      pagination: {
        pageIndex: 0,
        pageSize: 20
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    paginateExpandedRows: false,
    autoResetPageIndex: false,
    debugTable: true
  });

  const LIMIT = 10;

  const offset = watch(forms.invoices.data_table.offset) as number;

  const paginationLabel = `${offset + 1}-${offset + 10} of ${LIMIT} Invoices`;

  const handlePreviousPage = () => {
    setValue(
      forms.invoices.data_table.offset,
      (watch(forms.invoices.data_table.offset) as number) - 10
    );
  };
  const handleNextPage = () => {
    setValue(
      forms.invoices.data_table.offset,
      (watch(forms.invoices.data_table.offset) as number) + 1 * 10
    );
  };
  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-content-start place-items-start gap-[1rem]',
        'w-full'
      )}>
      <table className={clsx('grid grid-cols-1 place-content-start place-items-start', 'w-full')}>
        <thead
          className={clsx(
            'grid grid-cols-1 place-content-start place-items-start',
            'bg-grayscale-20',
            'w-full'
          )}>
          {table !== null &&
            table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className={clsx(
                  'grid place-content-start place-items-start',
                  'w-full',
                  'px-[0.5rem]'
                )}
                style={{
                  gridTemplateColumns: `0px 120px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr`
                }}>
                {headerGroup.headers
                  .filter((header) => {
                    return header.id !== 'id';
                  })
                  .map((header) => {
                    return (
                      <th
                        className={clsx(
                          'grid grid-cols-1 place-content-start place-items-start',
                          'w-full'
                        )}
                        key={header.id}
                        colSpan={header.colSpan}>
                        <div
                          className={clsx(
                            'w-full',
                            'cursor-default select-none flex justify-left items-center'
                          )}>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </div>
                      </th>
                    );
                  })}
              </tr>
            ))}
        </thead>
        <tbody
          className={clsx(
            'grid grid-cols-1 place-content-start place-items-start',
            'w-full',
            'h-[calc(100vh_-_360px)]',
            'overflow-auto'
          )}>
          {table !== null && table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row, idx) => (
              <tr
                key={row.id}
                role={'table-row'}
                className={clsx(
                  'grid place-content-start place-items-start',
                  'w-full',
                  String(row.getValue('id')).length > 0 &&
                    'border-b border-b-grayscale-30 hover:bg-grayscale-20',
                  'px-[0.5rem]'
                )}
                style={{
                  gridTemplateColumns: `0px 0px 120px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr`
                }}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} className={clsx('py-[0.8rem]', 'text-left', 'w-full')}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <tr
              className={clsx('grid grid-cols-1 place-content-start place-items-start', 'w-full')}>
              <td
                colSpan={table?.getHeaderGroups()[0]?.headers?.length}
                className={clsx(
                  'text-center border-b border-b-grayscale-30 hover:bg-grayscale-20',
                  'w-full'
                )}>
                <p className="text-grayscale-70 text-center py-4">No data available</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div
        className={clsx(
          'grid grid-flow-col items-center content-center justify-items-center justify-center',
          'w-[calc(100vw-216px)]',
          'fixed bottom-[1rem] left-[216px] right-[0px]'
        )}>
        <Pagination
          is_first_page={offset === 0}
          label={paginationLabel}
          onPreviousPage={handlePreviousPage}
          onNextPage={handleNextPage}
        />
      </div>
    </div>
  );
};
