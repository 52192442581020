import { GetEstimatedUsageSuccessResponseInterface } from 'core/models/api/invoices';
import { forms } from './data';

export const role = [forms.role.name] as const;

export const data_table = [
  forms.data_table.search,
  forms.data_table.sort_by,
  forms.data_table.data,
  forms.data_table.offset,
  forms.data_table.total_data
] as const;
export const user_invitation = [
  forms.user_invitation.is_open,
  forms.user_invitation.email
] as const;

export type UsersFormDataKeys =
  | (typeof role)[number]
  | (typeof data_table)[number]
  | (typeof user_invitation)[number];

export type DataTableForm = {
  id: string;
  name: string;
  email: string;
  date_of_sign_up: string;
  role: string;
  actions: string;
};

export type UsersForm = Record<
  UsersFormDataKeys,
  | string
  | boolean
  | null
  | { id: string; name: string }
  | { id: string; name: string }[]
  | number
  | string[]
  | DataTableForm[]
>;
