import * as React from 'react';
import clsx from 'clsx';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import PaginationTestData from './Pagination.test.data.json';
import { IconButton } from '../IconButton';
import { Typography } from '../Typography';

export interface PaginationProps {
  label?: string;
  is_last_page?: boolean;
  is_first_page?: boolean;

  onPreviousPage?: () => void;
  onNextPage?: () => void;
}

export const Pagination = ({
  label = '',
  is_first_page = false,
  is_last_page = false,
  onPreviousPage,
  onNextPage
}: PaginationProps) => {
  return (
    <div
      data-testid={PaginationTestData['data-testid'].container}
      className={clsx(
        'grid grid-flow-col items-center content-center justify-items-start justify-between gap-[1rem]',
        'w-full'
      )}>
      <Typography variant={'Regular'} size={'S'} color={'text-grayscale-70'}>
        {label}
      </Typography>

      <div
        className={clsx(
          'grid grid-flow-col items-center content-center justify-items-end justify-end gap-[0.5rem]',
          'w-full'
        )}>
        <IconButton
          className={clsx(
            'rounded-[0.25rem]',
            'grid place-content-center place-items-center',
            'w-[1.5rem] h-[1.5rem]',
            'bg-grayscale-20'
          )}
          disabled={is_first_page}
          onClick={onPreviousPage}>
          <ChevronLeftIcon
            className={clsx(
              'w-[1rem] h-[1rem]',
              is_first_page ? 'text-grayscale-50' : 'text-grascale-90'
            )}
          />
        </IconButton>
        <IconButton
          className={clsx(
            'rounded-[0.25rem]',
            'grid place-content-center place-items-center',
            'w-[1.5rem] h-[1.5rem]',
            'bg-grayscale-20'
          )}
          disabled={is_last_page}
          onClick={onNextPage}>
          <ChevronRightIcon
            className={clsx(
              'w-[1rem] h-[1rem]',
              is_last_page ? 'text-grayscale-50' : 'text-grascale-90'
            )}
          />
        </IconButton>
      </div>
    </div>
  );
};
