import * as React from 'react';
import clsx from 'clsx';

export interface MenuItemsProps {
  children?: React.ReactNode;
}

export const MenuItems = ({ children }: MenuItemsProps) => {
  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-content-start place-items-start gap-[0.5rem]',
        'w-full',
        'p-[1rem]'
      )}>
      {children}
    </div>
  );
};
